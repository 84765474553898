<epay-page-content [pagetitle]="pagetitle">
  <!-- TODO: user flow for employer / ncp; existing / new user; ACH / credit -->
  <div class="page-content-main max-w max-gxs t-alignC" id="page-content-main">
    <div class="box-panel ie-note mb-5" *ngIf="isIE">
      <div class="small-note-block alert-note">
        <p class="c-red1">
          {{ieMessage}}
        </p>
      </div>
    </div>
  </div>
    <div class="page-content-main max-w max-gs t-alignC" id="page-content-main">
      <div class="box-panel translucent-panel access-panel mb-5">
        <h1>Welcome to Our New Website</h1>
        <p style="font-size: larger;">
          Our redesigned website has a fresh new look and is easy to navigate.<br>
          The same options are available as on our previous website.<br>
          A Helpful Links menu is available if you need assistance.
        </p>

      </div>
    </div>
    <div class="page-content-main max-w max-gxs t-alignC" id="page-content-main">
    <div class="box-panel translucent-panel access-panel mb-5">
      <p class="mb-2">This Oklahoma Child Support Internet Payment Website is dedicated to providing quality
        customer service to noncustodial parents and employers who need assistance with the child support payment
        process.</p>
      <!-- Start here -->
      <div class="access-links m--pa4 mb-5">
        <div class="mb-3">
          <a class="db btn-link mt-3" [routerLink]="['/login']">Login</a>
          <a class="db btn-link mt-3" [routerLink]="['/register']">Create Account</a>
          <a class="db btn-link mt-3" [routerLink]="['/ncp/creditcard']">NCP Credit Card Payment</a>
        </div>
      </div>

      <div class="small-note-block alert-note mb-2">
        <p class="c-red1">This Website Does Not Accept Payments Drawn on a Foreign Bank Account</p>
      </div>
      <div class="small-note-block xs-note">
        <p>Note: This website requires that cookies be enabled in your
          browser. By accessing this site, you agree to have cookies stored on your computer. You can read more about
          how this site uses cookies in our <a [routerLink]="['/privacypolicy']">Privacy Policy</a>
        </p>
      </div>
    </div>

  </div>
  <div class="page-content-main max-w max-gs t-alignC">
    <div class="message-panel" *ngIf="message1">
      <div class="box-panel">
        <p class="c-red1b">{{message1}}</p>
        <p class="c-red1b">{{message2}}</p>
        <p class="c-red1b">{{message3}}</p>
      </div>
    </div>
  </div>
</epay-page-content>
