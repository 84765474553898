import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-creditcard',
  templateUrl: './creditcard.component.html'
})
export class CreditCardComponent implements OnInit {
  pagetitle = "New Credit Card Payment";

  constructor() { }

  ngOnInit(): void {
  }

}
