<div class="container " id="page-content-main">
  <div class="mt-4">
    <div class="">
      <mat-card class="mb-3" style="border-radius: 10px">
        <mat-card-content>
          <a [routerLink]="['/home']" class="ts-l tw-b">
              <span class="fa fa-chevron-circle-left fa-sm-blue" matTooltip="GO BACK"
              matTooltipClass="tooltip-md">
            </span>
            Back
          </a>
          <br><br>
          <div class="container-fluid">
            <h2>Noncustodial Parent FAQ</h2>
            <p>
              Welcome to the OK Child Support Internet Payment Web Site. This site allows child support payors to initiate bank 
              draft authorizations to satisfy child support court orders via the web. Although a user may be registered to access 
              OK Child Support case information, a separate registration is required to submit child support payments through the 
              OK Child Support Internet Payment Web Site.
            </p>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                    style="font-weight: bold;">
                    Questions About Registering
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!---------------------------------------------------------------------------------------------->
                    <br>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c; padding-top: 20px">
                          Can I have child support payments automatically withdrawn from my bank account if I am under an Income Withholding Order?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Yes, but income withholding will remain in effect and not be terminated.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          If I elect to have child support payments automatically withdrawn from my bank account, can I pay what I want?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Yes, however, we encourage you to schedule your payments in an amount equal
                          to your current child support obligation(s) to avoid delinquency.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What do I need to do to have child support payments automatically withdrawn from my bank account?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Click Non-Custodial Parent at this web site, register an account, and schedule a payment. You must have a valid e-mail address.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What if I want to use the web site but I don't have an e-mail address?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          A valid e-mail address is required in order for us to communicate with you electronically.
                          If you do not have an e-mail address we cannot send you information such as a confirmation
                          of your transactions. Free e-mail accounts are available on the Internet. To locate one,
                          use any search engine and search for "free e-mail."
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What information is required to register an account on the web site?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          If you want to register an account for yourself, the following information is required:
                          <div class="row">
                            <div class="col">
                              <h5 class="mt-3"><u>Personal Information</u></h5>
                              <ul>
                                <li>Full Legal Name</li>
                                <li>Address</li>
                                <li>Social Security Number</li>
                                <li>Family group number (FGN)</li>
                                <li>Telephone Number</li>
                                <li>Valid E-mail Address</li>
                              </ul>
                            </div>
                            <div class="col">
                              <h5 class="mt-3"><u>Bank Information</u></h5>
                              <ul>
                                <li>Bank Name</li>
                                <li>Routing Number</li>
                                <li>Account Number</li>
                                <li>Type of Account: Checking or Savings</li>
                              </ul>
                            </div>
                          </div>
                          <br>

                          If you want to register an account and make a payment for someone else, you will need to provide the following information for yourself:
                          <div class="row">
                            <div class="col">
                              <h5 class="mt-3"><u>Your Contact Information</u></h5>
                              <ul>
                                <li>Full Legal Name</li>
                                <li>Address</li>
                                <li>Relation To Payor</li>
                                <li>Telephone Number</li>
                                <li>Valid E-mail Address</li>
                              </ul>
                            </div>
                            <div class="col">
                              <h5 class="mt-3"><u>Payor Information</u></h5>
                              <ul>
                                <li>Full Legal Name</li>
                                <li>Social Security Number</li>
                                <li>Address</li>
                                <li>Family group number (FGN) of the payor</li>
                                <li>Telephone Number</li>
                              </ul>
                            </div>
                            <div class="col">
                              <h5 class="mt-3"><u>Bank Information</u></h5>
                              <ul>
                                <li>Bank Name</li>
                                <li>Routing Number</li>
                                <li>Account Number</li>
                                <li>Type of Account: Checking or Savings</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Where can I find my family group number (FGN)? 
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Your FGN is located on your payor coupons, child support court order, and other documents related to your 
                          child support order. You may also contact Oklahoma SDU Customer Service at 1-855-363-1754 or contact CARE 
                          at 1-800-522-2922 for this information.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I have more than one bank account authorized for a payment?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          No, you can only authorize one bank account for a payment.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                    style="font-weight: bold;">
                    Questions About Setting Up a Payment
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What are my payment options?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            You may schedule a one-time only (non-recurring) payment or you may schedule recurring payments using one of the following options:
                            <br><br>
                            <ul>
                              <li>Weekly: Monday, Tuesday, Wednesday, Thursday, or Friday </li><br>
                              <li>Every two weeks: Monday, Tuesday, Wednesday, Thursday, or Friday </li><br>
                              <li>Semi-Monthly: two payments per month based on the following schedule:
                                <div class="row">
                                  <div class="col-6">
                                    <table class="table table-borderd">
                                      <tr>
                                        <td>1st & 16th</td>
                                        <td>6th & 21st</td>
                                        <td>11th & 26th</td>
                                      </tr>
                                      <tr>
                                        <td>2nd & 17th</td>
                                        <td>7th & 22nd</td>
                                        <td>12th & 27th</td>
                                      </tr>
                                      <tr>
                                        <td>3rd & 18th</td>
                                        <td>8th & 23rd</td>
                                        <td>13th & 28th</td>
                                      </tr>
                                      <tr>
                                        <td>4th & 19th</td>
                                        <td>9th & 24th</td>
                                        <td>14th & 29th</td>
                                      </tr>
                                      <tr>
                                        <td>5th & 20th</td>
                                        <td>10th & 25th</td>
                                        <td>15th & 30th</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </li>
                              <li>Monthly: 1st-31st</li><br>
                            </ul>
                            <span style="font-weight: 600;"> Note:</span> Semi-monthly and monthly payments will occur on the last day of the month in months with fewer days than the date scheduled. For example, if the 15th and 30th are selected, February's payments would occur on the 15th and 28th (the last day of the month).
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I schedule more than one payment, for example,
                          a payment on the 20th of the month, as well as a payment every two weeks on Friday?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Yes, however, you will need to schedule each payment separately.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What day will funds be withdrawn from my bank account?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Funds will be withdrawn on the scheduled payment date. If that date falls on
                        a Saturday, Sunday, or bank holiday the funds will be withdrawn the next business day.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How long will it take for Oklahoma Child Support Services to receive a payment once it is withdrawn from my bank account? 
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        You will receive credit for the payment in two to three business days.                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What happens if my payment is scheduled for a weekend or a holiday?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        If a withdrawal falls on a Saturday, Sunday, or a bank or state holiday, the funds will be
                        withdrawn the next business day. You will receive credit for the payment in two to three business days.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          When may I schedule a payment?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        A payment must be scheduled at least two business days prior to its due date,
                        assuming that your bank account information has been previously verified (prenote).
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the earliest date that I can select for my first payment date?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Your first payment date must be at least five business days from the date you register your account.
                        This will allow sufficient time for your bank account information to be verified (prenote).
                        If your account has been previously verified then the payment must be scheduled at least two
                        business days prior to its due date.
                        <br><br>
                        <span style="font-weight: 600;">Example:</span>  If you register your account and establish a payment schedule on Monday the 1st,
                        you must set your first payment date no earlier than Monday the 8th.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I entered a start date and the web site calculated a different date for my first payment. What am I doing wrong?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        If you entered a start date that is less than five business days from today's date,
                        your date will be automatically adjusted to include the five business day verification (prenote) process.
                        <br><br>
                        <span style="font-weight: 600;">Example:</span>  Today is January 26 (Monday) and a payment frequency of semi-monthly (15th and 30th) is selected.
                        Friday the 30th is entered as the first payment date. The first payment date is set to February 16. <br />
                        <br />
                        The 16th is determined by calculating five business days (January 27 - February 2), and then determining if
                        the 15th is a business day. Since the 15th is on a Sunday your first payment date is set to the 16th.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"
                    style="font-weight: bold;">
                    Questions About the Verification (Prenote) Period
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What happens after I register an account and establish a payment schedule?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            If your bank account information has not been previously verified, it will be
                            submitted to your bank for verification (prenote). If the prenote is successful,
                            your payments will be withdrawn as scheduled. If the prenote is unsuccessful,
                            you will be notified by e-mail and no payment will be processed.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Why would a prenote be returned as unsuccessful?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Most prenote's are returned as unsuccessful when the bank account information was entered incorrectly.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I received an e-mail that the prenote was unsuccessful. What should I do now?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Edit your bank account information through this web site and reschedule your payment.
                        The prenote will be resubmitted. You may want to contact your bank if you need assistance
                        identifying the routing and account numbers.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"
                    style="font-weight: bold;">
                    Questions About Editing or Changing an Account
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How do I make a change to my account?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Make your changes through this web site.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I changed my bank account, what should I do?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Edit your bank account information through this web site. A prenote will be sent to
                          verify the new bank account information. In addition, you will need to establish a
                          new payment schedule since any existing payment schedule(s) will be cancelled when
                          you edit your bank account information.
                          <br><br>
                          If a child support payment is due before the end of the five business day verification (prenote) period, you will
                          need to mail your payment in order to avoid delinquency.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How do I reactivate a bank draft that I previously cancelled?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Establish another payment schedule through this web site.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I authorized a bank draft and now I want to change it. What do I need to do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Change or cancel your payment through this web site. You must allow two business
                        days prior to the payment's scheduled date to make your changes.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"
                    style="font-weight: bold;">
                    Questions About Unavailable Funds
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What if my bank account does not have sufficient funds to cover a payment?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            You must cancel your payment at least two business days prior to its scheduled date. You can cancel your payment at this web site.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What if I fail to cancel the payment two business days prior to its scheduled date and there are insufficient funds in my account?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Your account will be placed on hold and your automatic withdrawal will be cancelled. You will then be required to 
                          remit all future payments by a guaranteed payment instrument such as a money order, cashier's check, certified 
                          check, or credit card. Call CARE at 1-800-522-2922 to notify OCSS in case we are able to stop the payment from 
                          issuing.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Why is my account on hold?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        We attempted to withdraw the funds from your bank account and they were not available.
                        You will be required to remit all future payments by a guaranteed instrument such as a
                        money order, cashier's check, or certified check, or credit card.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"
                    style="font-weight: bold;">
                    Credit Card FAQs
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What credit cards do you accept?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            You may use your MasterCard® or Visa® to make a child support payment.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                         What browsers do you support for e-wallet payments?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Apple Pay payments must be made with an Apple device. Google Pay and PayPal payments
                          may be made with any browser. You must use Safari (iOS) or Chrome (Android) when
                          making a payment through Venmo.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What information is required to make a credit card payment?
                        </mat-expansion-panel-header>
                       <div class="mx-3">
                        The following information is required to make a credit card payment: <br>

                        <br />
                        &nbsp; &bull; Non-custodial parent's first and last name.<br>

                        <br />
                        &nbsp; &bull; Non-custodial parent's social security number.<br>

                        <br />
                        &nbsp; &bull; Noncustodial parent's family group number(FGN).<br>

                        <br />
                        &nbsp; &bull; Credit card holder's name.<br>

                        <br />
                        &nbsp; &bull; Credit card holder's billing address.<br>

                        <br />
                        &nbsp; &bull; Valid e-mail address.<br>

                        <br />
                        &nbsp; &bull; MasterCard® or Visa® credit card number and expiration date and CVV2/ CVC2 3-digit security code.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What should I do if I don't have an e-mail address?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        A valid e-mail address is required in order for us to communicate with you electronically.
                        If you do not have an e-mail address we cannot send you information such as a confirmation
                        of your transactions. Free e-mail accounts are available on the Internet. To locate one,
                        use any search engine and search for "free e-mail".
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How can I obtain my Family Group Number (FGN)?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Your FGN is located on your payor coupons, child support court order, and other documents related to 
                        your child support order. You may also contact Oklahoma SDU Customer Service at 1-855-363-1754 or CARE 
                        at 1-800-522-2922 for this information.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I make a credit card payment if I am under an Order for Income Withholding?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, but income withholding will remain in effect and will not be terminated.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I make a credit card payment by phone?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, you can make a non-recurring payment by calling 1.844.324.3856.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Is there a minimum payment requirement?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, there is a minimum payment requirement of $1.00.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the maximum payment that I can make?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The maximum payment that you can make in one transaction is $9,999.99; assuming that your card has
                        that amount of credit available. Multiple transactions can be made in one day if each
                        transaction does not exceed $9,999.99, and the appropriate amount of credit is available.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the purpose of the service fee?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The credit card service fee is charged for the convenience of using this easy
                        payment method. Without the service fee, the cost of providing this service would be prohibitive.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How much is the service fee?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The service fee for using this service is 2.50% of the amount of the primary child support payment.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Will the service fee be applied to my child support obligation?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The service fee is separate from your child support obligation and is not applied to it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          When will my credit card be charged for my child support payment?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Your credit card is charged at the time you submit the transaction, and your credit card company authorizes it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          When will my payment be applied to my child support account?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        You will receive credit for the payment in two to three business days.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I make a payment for a non-custodial parent with my credit card?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, you can use your credit card to make a payment for a noncustodial parent; however, you will 
                        need their first and last name, social security number, and family group number (FGN).
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the CVV2/ CVC2 3 or 4-digit security code and where do I find it?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The CVV2/ CVC2 3-digit security code is an added security measure. It is used to establish that 
                        you are in possession of the credit card. The code is printed just above the signature panel in 
                        reverse italics on the back of your card. Usually your card number precedes it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          My credit card transaction was declined, what should I do?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          You will need to contact your credit card company to determine why the payment was not authorized.
                          It could have been declined for one of the following reasons: <br>

                          <ul class="list-bullet mx-3">
                            <li>
                              The credit card number and/ or CVV2/ CVC2 security code was incorrectly entered. <br />
                            </li>
                    
                            <li>
                              There is a discrepancy between the address you entered and the billing address that is on 
                              file with the card-issuing bank. You must enter the billing address that appears on your credit 
                              card statement. <br />
                            </li>
                    
                            <li>
                              A foreign address (a country other than the US) was entered. The web site is unable to process 
                              credit card payments with a foreign address. <br />
                            </li>
                    
                            <li>
                              There is insufficient credit available for the payment amount. <br />
                            </li>
                          </ul>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How do I obtain a refund on a credit card payment made through this website?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        You will need to contact Oklahoma Child Support Customer Service at 1-855-363-1754.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"
                    style="font-weight: bold;">
                    Questions About Security
                  </button>
                </h2>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I am having trouble creating a user ID or password.
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Your user ID and password must each consist of at least two numbers, six letters,
                            and not exceed 12 characters in length. In addition, they cannot contain any special
                            characters. For example, question marks, number signs, periods.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can my password be the same as my user ID?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          No, it must be different from your user ID.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Does my password ever expire?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        No, your password does not expire.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I forgot my password. What do I need to do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        On the login page, click "Forgot your password?", enter the required information, and create a new password.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          If I forget my password and change it, but remember it later and want to change it back to the original password can I?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, you can.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I tried to register an account on the web site and received the message "user not found." What should I do now?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Contact Oklahoma SDU Customer Service at 1-855-363-1754 and verify that your name, address, and social security 
                        number are correct in the child support system. 
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingNine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine"
                  style="font-weight: bold;">
                  Questions About Technical Issues
                </button>
                </h2>
                <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <!------------------------------------------------------------------------------------------------>
                  <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        It appears there is a problem with the web site. What do I need to do?
                      </mat-expansion-panel-header>
                        <div class="mx-2">
                          Contact Oklahoma SDU Customer Service at 1-855-363-1754 to report the problem.
                        </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <br>
                </div>
              </div>

              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight"
                    style="font-weight: bold;">
                    Unauthorized Access of an Account
                  </button>
                </h2>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I believe someone has access to my web site user ID and password. What do I need to do?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Change your user ID and password through this web site.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Money has been withdrawn from my account without my permission. What should I do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Contact Oklahoma SDU Customer Service at 1-855-363-1754 immediately. You will be asked to submit your claim in writing. 
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>


              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen"
                    style="font-weight: bold;">
                    Contact Information
                  </button>
                </h2>
                <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body mx-3">
                    <h3>Address</h3>
                    Oklahoma Centralized Support Registry <br>
                    P.O. Box 268809 <br>
                    Oklahoma City, OK 73126-8809 <br>
                    <br>
                    <h3>Telephone</h3>
                    <div style="color:#174a7c;">Oklahoma SDU Customer Service:</div>
                    1-855-363-1754
                    <br>
                    <br>
                    <h3>E-mail</h3>
                    <address>
                      <a href="mailto:okecsupport@smimail.net">
                        <u>okecsupport@smimail.net</u>
                      </a>
                    </address>
                    <div><STRONG>Note: All questions related to a child support case should be 
                      directed to CARE at 1-800-522-2922.</STRONG>
                    </div>
                    <br>
                    <!-- <h3>Live Chat</h3>
                    <div>
                      <span>
                        <img (click)="openChat()" src="../../../../../assets/images/chaticonmid.png">
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
