import { Component, OnInit } from '@angular/core';
import { AuthService, CommonSettings, PhoneHelperService, ViewportService } from 'epay-library';
import { RegisterLibraryService } from 'epay-library';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'epay-library';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { PaymentService } from 'epay-library';
import { DatePipe } from '@angular/common';

import { EMPLOYER_PROFILE_SETTINGS_URL } from 'epay-library';

@Component({
  selector: 'epay-achd-landing',
  templateUrl: './achd.landing.component.html',
})
export class EMPACHDLandingComponent implements OnInit {
  pagetitle = 'ACH Debit Home';
  secondaryIdAlias = 'Family Group #';

  paymentaccounts: any[] = [];
  username = '';
  usertype = '';
  isManager = '';
  numOfUsers = 0;
  accountKeys: string[];
  isLoggedIn = false;
  isLoading = false;
  accountAddress = '';
  accountCSZ = '';
  accountEmail = '';
  accountPhone = '';
  employerName = '';
  baseurl = '';
  numOfDaysForPaymentHistory: number;
  setupresult = '';
  isPaymentAccountSetup = false;
  hasSecondaryAccount = false;
  isMobile = false;
  showParticipantCase = false;
  phoneMask = '';
  countryId = '';

  confirmationSender = '';
  confirmationTitle = '';
  confirmationMessage = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';
  openConfirmation = false;

  profileSettingsUrl = EMPLOYER_PROFILE_SETTINGS_URL;

  constructor(
    private authservice: AuthService,
    private paymentservice: PaymentService,
    private viewpointService: ViewportService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private router: Router,
    private phoneHelperService: PhoneHelperService
  ) {
    this.baseurl = CommonSettings.BaseApi;
    this.numOfDaysForPaymentHistory =
      +CommonSettings.NumOfDaysForPaymentHistory;
    this.accountKeys = [];
    this.showParticipantCase = true;
  }

  ngOnInit() {
    this.authservice.currentUserName.subscribe(
      (name: string) => (this.username = name.toUpperCase())
    );
    this.authservice.currentUserType.subscribe(
      (type: string) => (this.usertype = type)
    );
    this.authservice.currentAccountAddress.subscribe((address: string) => {
      this.accountAddress = address;
      this.countryId = address.slice(-2);
      this.setPhoneMask(this.countryId)!;
      console.log(this.countryId + ' ' + this.phoneMask);
    });
    this.authservice.currentAccountPhone.subscribe(
      (phone: string) => (this.accountPhone = phone)
    );
    this.authservice.currentAccountEmail.subscribe(
      (email: string) => (this.accountEmail = email)
    );
    this.authservice.currentEmployerName.subscribe(
      (empName: string) => (this.employerName = empName)
    );
    this.authservice.currentUserAccountManager.subscribe(
      (manager: string) => (this.isManager = manager)
    );
    this.authservice.currentNumberofAccountUsers.subscribe(
      (acctCount: string) => (this.numOfUsers = +acctCount)
    );

    this.viewpointService.IsMobile.subscribe(
      (mobile: any) => (this.isMobile = mobile)
    );

    this.paymentservice
      .getPaymentAccounts(this.baseurl)
      .subscribe((accounts: any) => {
        this.paymentaccounts = this.filterOldAccount(accounts);
        if (this.paymentaccounts.length === 0) {
          this.isPaymentAccountSetup = false;
          setTimeout(() => {
            this.setupPaymentAccount();
          }, 3000);
        } else {
          this.isPaymentAccountSetup = true;
        }
      });
  }

  setPhoneMask(regionCode: string) {
    this.phoneMask = this.phoneHelperService.getMask(regionCode);
  }

  setupPaymentAccount() {
    if (sessionStorage.getItem('askForBankAccount')?.toString() !== 'false') {
      const message = `You do not have a Bank Account setup.  Would you like to add one now?`;
      const acctdetail = '';
      const dialogData = new ConfirmDialogModel(
        'Setup Bank Account',
        message,
        acctdetail,
        '',
        '',
        '',
        ''
      );

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe({
        next: (dialogResult: any) => (this.setupresult = dialogResult),
        error: (error: any) => console.log(error),
        complete: () => {
          if (this.setupresult) {
            this.router.navigate(['employer/achd/paymentaccounts']);
          } else {
            //sessionStorage.setItem('askForBankAccount', 'false');
            this.router.navigate(['employer/home']);
          }
        },
      });

      sessionStorage.setItem('askForBankAccount', 'false');
    }
  }

  filterOldAccount(accounts: any[]) {
    return accounts.filter(
      (account) =>
        this.datepipe.transform(account.inactiveDate, 'yyyy-MM-dd') ===
        '9999-12-31'
    );
  }

  displayConfirmationPage(result: any) {
    if (result.success) {
      this.confirmationSender = result.sender;
      this.confirmationTitle = 'Success';
      this.confirmationMessage = result.message;
      this.confirmationNumber = result.confirmationNumber;
      this.goToPrimaryUrl = 'employer/home';
      this.goToPrimary = 'Home';
      this.openConfirmation = true;
    }
  }

  hideConfirmationPage(result: any) {
    this.openConfirmation = !result.hideConfirmation;
  }

  // goToProfile() {
  //   this.router.navigate(['/employer/profile']);
  // }

  goToPaymentAccounts() {
    this.router.navigate(['employer/paymentaccounts']);
  }
}
