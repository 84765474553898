import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IChallengeQuestion, ErrorHandlerService, AuthService, RegisterLibraryService, ConfirmDialogModel, ConfirmDialogComponent } from 'epay-library';
import { debounceTime } from 'rxjs';

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password1Ctrl = c.get('newPassword1');
  const password2Ctrl = c.get('newPassword2');
  if (password1Ctrl === null || password2Ctrl === null) {
    return null;
  }
  if (password1Ctrl.pristine || password2Ctrl.pristine) {
    return null;
  }
  if (password1Ctrl.value === password2Ctrl.value) {
    return null;
  }
  return { doNotMatch: true };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (control) {
      return (control.dirty || control.touched) && control.parent!.invalid;
    }
    return false;
  }
}

@Component({
  selector: 'employer-profile-security',
  templateUrl: './empprofile-security.component.html'
})
export class EMPProfileSecurityComponent implements OnInit {
  @Input() baseurl = '';
  @Input() useEmailForLogin: any;
  @Output() profileConfirmationEmitter = new EventEmitter<{
    success: boolean;
    restart: boolean;
    message: string;
    confirmationNumber: string;
  }>();

  @Input() newEmailFromLogin: any;

  errorMatcher = new CrossFieldErrorMatcher();
  loginFrmGp: FormGroup = new FormGroup({});
  passwordFrmGp: FormGroup = new FormGroup({});
  username = '';
  email = '';
  userNumber = 0;
  questions: IChallengeQuestion[] = [];
  selectedChallengeQuestion = '';
  errorMessage = '';
  currentLoginErrorMessage = '';
  currentLoginPasswordErrorMessage = '';
  currentChangePasswordErrorMessage = '';
  newChangePasswordErrorMessage = '';
  newChangeConfirmPasswordErrorMessage = '';
  newLoginErrorMessage = '';
  newLoginSuccessMessage = '';
  updateLoginDialogresult = '';
  isCheckingLogin = false;
  isValidLogin = false;
  isValidPassword = false;
  currentLoginPasswordHide = true;
  currentChangePasswordHide = true;
  newChangePasswordHide = true;
  newChangeConfirmPasswordHide = true;

  loginHintMessage = 'Must contain at least 8 characters. Alphanumerical values and email format are accepted.';

  passwordHintMessage =
    'Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.';
  hintMessageUsingEmail = ' Please enter a valid email.';

  // loginPattern =
  //   /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;

  loginPattern = /^[a-zA-Z0-9.@]*$/;

  passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  emailPattern = /\S+@\S+\.\S+/;

  validationLoginIdMessages: { [key: string]: string } = {
    required: 'Required.',
    pattern: '',
    isCurrent: '',
    notCurrentLogin: '',
    isDuplicate: '',
    isNotValid: '',
  };

  validationPasswordMessages: { [key: string]: string } = {
    required: 'Required.',
    pattern:
      'Invalid Password.  Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.',
    isSameAsLogin: 'Invalid format.  Password cannot be same as username.',
    isNotValid: 'Username/password combination is invalid',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService,
    private registerService: RegisterLibraryService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog //private accountInfo: EMPLibraryProfileAccountComponent
  ) {}

  ngOnInit() {
    this.loadFormGroups();
    this.getChallengeQuestions();
    this.authService.currentLoginId.subscribe(
      (username: any) => (this.username = username)
    );
    this.authService.currentAccountEmail.subscribe(
      (email: any) => (this.email = email)
    );
    this.authService.currentUserNumber.subscribe(
      (num) => (this.userNumber = +num)
    );
    const currentLoginCtrl = this.loginFrmGp.get('currentLoginId');
    const newloginCtrl = this.loginFrmGp.get('newLoginId');
    const currentLoginPasswordCtrl = this.loginFrmGp.get('currentPassword');

    // Error messages
    if (this.useEmailForLogin) {
      //this.validationLoginIdMessages["required"] = 'Required.';
      this.validationLoginIdMessages['pattern'] =
        'Invalid format. Please enter a valid Email.';
      this.validationLoginIdMessages['isCurrent'] =
        'Email is your current username.';
      this.validationLoginIdMessages['notCurrentLogin'] =
        'This is not your current Email.';
      this.validationLoginIdMessages['isDuplicate'] =
        'Email is NOT available. ';
      this.validationLoginIdMessages['isNotValid'] =
        'Email & Password combination is invalid';
    } else {
      //this.validationLoginIdMessages["required"] = 'Required.';
      this.validationLoginIdMessages['pattern'] =
        'Invalid Username. Must contain only alphanumerical values with the exception of email format.';
      this.validationLoginIdMessages['isCurrent'] =
        'Username is your current username.';
      this.validationLoginIdMessages['notCurrentLogin'] =
        'This is not your current Username.';
      this.validationLoginIdMessages['isDuplicate'] =
        'Username is NOT available. ';
      this.validationLoginIdMessages['isNotValid'] =
        'Username & Password combination is invalid';
    }

    // Switch validators based on user-type
    if (this.useEmailForLogin) {
      this.loginFrmGp.clearValidators();
      this.loginFrmGp
        .get('currentLoginId')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]);
      this.loginFrmGp
        .get('newLoginId')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]);
    } else {
      this.loginFrmGp.clearValidators();
      this.loginFrmGp
        .get('currentLoginId')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.loginPattern),
        ]);
      this.loginFrmGp
        .get('newLoginId')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.loginPattern),
        ]);
    }

    this.loginFrmGp.updateValueAndValidity();

    currentLoginCtrl!.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((currentLogin) =>
        this.setCurrentLoginMessage(currentLoginCtrl!, currentLogin)
      );

    currentLoginPasswordCtrl!.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((passwrd) =>
        this.setCurrentLoginPasswordMessage(currentLoginPasswordCtrl!)
      );

    newloginCtrl!.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((newlogin) =>
        this.setNewLoginMessage(newloginCtrl!, newlogin)
      );
  }

  updateLogin() {
    if (this.loginFrmGp.valid) {
      const message = `This action will force a Logout.  Are you sure you want to change your username?`;
      const acctdetail =
        'New Username: ' + this.loginFrmGp.get('newLoginId')!.value;
      const dialogData = new ConfirmDialogModel(
        'Confirm Changing Username',
        message,
        acctdetail,
        '',
        '',
        '',
        ''
      );
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '700px',
        data: dialogData,
      });
      dialogRef.afterClosed().subscribe({
        next: (dialogResult: any) => {
          this.updateLoginDialogresult = dialogResult;
        },
        error: (error: any) => {
          console.log(error);
        },
        complete: () => {
          if (this.updateLoginDialogresult) {
            if (this.loginFrmGp.valid) {
              if (this.loginFrmGp.dirty) {
                const loginData = {
                  loginId: this.loginFrmGp.get('newLoginId')!.value,
                  password: this.loginFrmGp.get('currentPassword')!.value,
                };
                this.registerService
                  .changeLoginId(this.baseurl, loginData)
                  .subscribe({
                    next: (result: any) => {
                      if (result.errorMessage) {
                        const currentLoginPasswordCtrl =
                          this.loginFrmGp.get('currentPassword');
                        currentLoginPasswordCtrl!.setErrors({
                          isNotValid: true,
                        });
                        this.setCurrentLoginPasswordMessage(
                          currentLoginPasswordCtrl!
                        );
                        // this.openSnackBar('Incorrect username/password.', 5000, 'snackbar-error');
                      }

                      if (this.loginFrmGp.get('updateEmail')!.value === true) {
                        // else-if checkbox --> RegisterSerivce.updateUser
                        const updateAccount = {
                          firstName: '',
                          middleName: '',
                          lastName: '',
                          address1: '',
                          address2: '',
                          city: '',
                          state: '',
                          zip5: '',
                          zip4: '',
                          countryCode: '',
                          phone: '',
                          phoneExt: '',
                          email: this.loginFrmGp.get('newLoginId')!.value,
                        };
                        this.registerService
                          .updateUserAccount(
                            this.baseurl,
                            updateAccount,
                            this.userNumber
                          )
                          .subscribe({
                            next: (result: any) => {
                              this.profileConfirmationEmitter.emit({
                                success: true,
                                restart: true,
                                message:
                                  'Username & Email changed to ' +
                                  loginData.loginId +
                                  '.',
                                confirmationNumber: result.confirmationNumber,
                              });
                            },
                            error: (error: any) => {
                              this.logError(error);
                              this.handleError(error.status);
                            },
                          });
                      } else {
                        this.profileConfirmationEmitter.emit({
                          success: true,
                          restart: true,
                          message:
                            'Username changed to ' + loginData.loginId + '.',
                          confirmationNumber: result.confirmationNumber,
                        });
                      }
                    },
                    error: (error: any) => {
                      this.logError(error);
                      this.handleError(error.status);
                    },
                  });
              }
            }
          }
        },
      });
    }
  }

  updatePassword() {
    if (this.passwordFrmGp.valid) {
      if (this.passwordFrmGp.dirty) {
        const passwrdData = {
          oldPassword: this.passwordFrmGp.get('currentPassword')!.value,
          newPassword: this.passwordFrmGp.get('newPassword1')!.value,
          confirmNewPassword: this.passwordFrmGp.get('newPassword2')!.value,
        };
        this.registerService
          .resetPassword(this.baseurl, passwrdData)
          .subscribe({
            next: (result: any) => {
              if (result.errorMessage) {
                this.openSnackBar(
                  'Incorrect current password.',
                  5000,
                  'snackbar-error'
                );
              } else {
                this.profileConfirmationEmitter.emit({
                  success: true,
                  restart: false,
                  message: 'Password has been updated.',
                  confirmationNumber: result.confirmationNumber,
                });
                this.onSaveCompletePasswordChange();
              }
            },
            error: (error: any) => {
              this.logError(error);
              this.handleError(error.status);
            },
          });
      }
    }
  }

  getPasswordError(ctrl: any) {
    const control = this.passwordFrmGp.get(ctrl);
    const passwrd = control!.value;
    if ((control!.touched || control!.dirty) && !control!.errors) {
      if (passwrd === this.username) {
        control!.setErrors({ isSameAsLogin: true });
        return Object.keys(control!.errors!)
          .map((key) => this.validationPasswordMessages[key])
          .join(' ');
      }
    } else if (control!.errors) {
      return Object.keys(control!.errors)
        .map((key) => this.validationPasswordMessages[key])
        .join(' ');
    }
    return '';
  }

  setCurrentLoginMessage(currentLoginControl: AbstractControl, loginId: any) {
    this.loginFrmGp.patchValue({
      currentPassword: '',
    });
    this.currentLoginErrorMessage = '';
    if (currentLoginControl.touched || currentLoginControl.dirty) {
      if (currentLoginControl.errors) {
        this.currentLoginErrorMessage = Object.keys(currentLoginControl.errors)
          .map((key) => this.validationLoginIdMessages[key])
          .join(' ');
      } else {
        if (loginId.toString() !== this.username) {
          currentLoginControl.setErrors({ notCurrentLogin: true });
          this.currentLoginErrorMessage = Object.keys(
            currentLoginControl.errors!
          )
            .map((key) => this.validationLoginIdMessages[key])
            .join(' ');
        }
      }
    }
  }

  setCurrentLoginPasswordMessage(currentLoginPasswordControl: AbstractControl) {
    this.currentLoginPasswordErrorMessage = '';
    if (
      (currentLoginPasswordControl.touched ||
        currentLoginPasswordControl.dirty) &&
      currentLoginPasswordControl.errors
    ) {
      this.currentLoginPasswordErrorMessage = Object.keys(
        currentLoginPasswordControl.errors
      )
        .map((key) => this.validationLoginIdMessages[key])
        .join(' ');
    }
  }

  setNewLoginMessage(newLoginControl: AbstractControl, newlogin: any) {
    this.newLoginErrorMessage = '';
    this.newLoginSuccessMessage = '';
    this.isValidLogin = false;

    if (newLoginControl.touched || newLoginControl.dirty) {
      if (newLoginControl.errors) {
        this.newLoginErrorMessage = Object.keys(newLoginControl.errors)
          .map((key) => this.validationLoginIdMessages[key])
          .join(' ');
      } else if (newlogin.toString() === this.username) {
        newLoginControl.setErrors({ isCurrent: true });
        this.newLoginErrorMessage = Object.keys(newLoginControl.errors!)
          .map((key) => this.validationLoginIdMessages[key])
          .join(' ');
      } else {
        this.isCheckingLogin = true;
        this.registerService
          .searchDuplicateLogin(this.baseurl, newlogin)
          .subscribe({
            next: (result: any) => {
              this.isValidLogin = !result.isDuplicate;
              this.isCheckingLogin = false;
              if (this.isValidLogin) {
                this.newLoginSuccessMessage = 'Username Available!';
              } else {
                newLoginControl.setErrors({ isDuplicate: true });
                this.newLoginErrorMessage = Object.keys(newLoginControl.errors!)
                  .map((key) => this.validationLoginIdMessages[key])
                  .join(' ');
              }
            },
            error: (error: any) => {
              this.logError(error);
              this.handleError(error.status);
            },
          });
      }
    }
  }

  toggleLoginPasswordHide() {
    this.currentLoginPasswordHide = !this.currentLoginPasswordHide;
  }

  toggleChangeCurrentPasswordHide() {
    this.currentChangePasswordHide = !this.currentChangePasswordHide;
  }

  toggleChangePasswordHide() {
    this.newChangePasswordHide = !this.newChangePasswordHide;
  }

  toggleChangeConfirmPasswordHide() {
    this.newChangeConfirmPasswordHide = !this.newChangeConfirmPasswordHide;
  }

  onSaveCompleteLoginChagne() {
    this.loginFrmGp.reset();
  }

  onSaveCompletePasswordChange() {
    this.passwordFrmGp.reset();
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  loadFormGroups() {
    this.loginFrmGp = this.formbuilder.group({
      //currentLoginId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      currentLoginId: ['', [Validators.required]],
      //newLoginId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      newLoginId: ['', [Validators.required, Validators.pattern(this.loginPattern)]],
      currentPassword: [
        '',
        [Validators.required],
      ],
      updateEmail: [''],
    });

    this.passwordFrmGp = this.formbuilder.group(
      {
        currentPassword: [
          '',
          [Validators.required],
        ],
        newPassword1: [
          '',
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        newPassword2: ['', Validators.required],
      },
      { validator: passwordMatcher }
    );

    if (this.newEmailFromLogin !== '') {
      this.loginFrmGp.patchValue({
        newLoginId: this.newEmailFromLogin,
      });
    }
  }

  getChallengeQuestions() {
    this.registerService.getChallengeQuestions(this.baseurl).subscribe({
      next: (questions: any) => {
        this.questions = questions;
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}
