<div *ngIf="!openConfirmation" class="stepper-panel">
    <div class="form-panel">
      <mat-stepper [orientation]="stepperOrientation" #stepper linear="true">
        <!-- Current Username & Email -->
        <mat-step class="stepper-icon" [stepControl]="usernameFrmGp">
          <form [formGroup]="usernameFrmGp">
            <ng-template matStepLabel>Enter Your Current Username</ng-template>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput placeholder="Username" #loginId formControlName="loginId" autocomplete="off" required>
                <!-- <mat-error>{{loginErrorMessage}}</mat-error> -->
                <mat-error *ngIf="usernameFrmGp.get('loginId')?.hasError('pattern')">Invalid Username.</mat-error>
                <mat-error *ngIf="usernameFrmGp.get('loginId')?.hasError('required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput placeholder="Current Email" formControlName="email" autocomplete="off" required>
                <mat-hint *ngIf="isValidAccount && !isCheckingLogin" class="mat-hint-success">{{accountSuccessMessage}}
                </mat-hint>
                <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                <span *ngIf="isValidAccount" matSuffix><i class="fa fa-check ts-l c-green0"></i></span>
                <mat-error *ngIf="!accountErrorMessage?.length">{{emailErrorMessage}}</mat-error>
                <mat-error *ngIf="accountErrorMessage?.length">{{accountErrorMessage}}</mat-error>

                <mat-error *ngIf="usernameFrmGp.get('email')?.hasError('required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-btn-row pt-2 pb-2">
              <button class="btn btn-secondary" mat-button (click)="goToLogin()">Back</button>
              <button class="btn btn-primary-short" mat-button matStepperNext [disabled]="!isValidAccount">Next</button>
            </div>
          </form>
        </mat-step>
        <!-- 2. Security Question -->
        <mat-step class="stepper-icon" [stepControl]="securityFrmGp">
          <form [formGroup]="securityFrmGp">
            <ng-template matStepLabel>Answer Your Security Question</ng-template>
            <div class="flex-input-col">
              <mat-form-field>
                <input matInput formControlName="challengeQuestion" readonly>
              </mat-form-field>
            </div>
            <div class="flex-input-col">
              <mat-form-field>
                <input matInput placeholder="Challenge Answer" formControlName="challengeAnswer" autocomplete="off">
                <mat-hint *ngIf="isValidAnswer && !isCheckingAnswer" class="c-green0">{{challengeSuccessMessage}}
                </mat-hint>
                <span *ngIf="isCheckingAnswer" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                <span *ngIf="isValidAnswer" matSuffix><i class="fa fa-check ts-l c-green0"></i></span>
                <mat-error *ngIf="securityFrmGp.get('challengeAnswer')?.hasError('notverified')">{{challengeErrorMessage}}
                </mat-error>
                <mat-error *ngIf="securityFrmGp.get('challengeAnswer')?.hasError('required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-btn-row pt-2 pb-2">
              <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
              <button class="btn btn-primary-short" mat-button matStepperNext [disabled]="!isValidAnswer">Next</button>
            </div>
          </form>
        </mat-step>
        <!-- 3. Reset -->
        <mat-step class="stepper-icon" [stepControl]="passwordFrmGp">
          <form [formGroup]="passwordFrmGp">
            <ng-template matStepLabel>Complete Information Below</ng-template>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput placeholder="New Password" [type]="passwordHide ? 'password' : 'text'"
                  formControlName="newpassword1" autocomplete="off">
                <mat-hint>{{passwordHintMessage}}</mat-hint>
                <mat-icon matSuffix (click)="togglePasswordHide()">{{passwordHide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-error>{{passwordErrorMessage}}</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput [errorStateMatcher]="errorMatcher" placeholder="Confirm Password"
                  [type]="passwordConfirmHide ? 'password' : 'text'" formControlName="newpassword2" autocomplete="off">
                <mat-icon matSuffix (click)="toggleConfirmPasswordHide()">{{passwordConfirmHide ? 'visibility_off' :
                  'visibility'}}</mat-icon>
                <mat-error *ngIf="passwordFrmGp.hasError('doNotMatch')">
                  Passwords do not match.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-btn-row pt-2 pb-2">
              <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
              <button [disabled]="!passwordFrmGp.valid" class="btn btn-primary-short" mat-button
                (click)="saveNewPassword()">Change Password</button>
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
  
  <epay-lib-common-confirmation *ngIf="openConfirmation" [confirmationTitle]="confirmationTitle"
    [confirmationMessage]="confirmationMessage" [confirmationEmailAddress]="confirmationEmailAddress"
    [confirmationNumber]="confirmationNumber" [restart]="true" [goToPrimary]="goToPrimary"
    [goToPrimaryUrl]="goToPrimaryUrl">
  </epay-lib-common-confirmation>
  