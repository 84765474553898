import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'epay-library';
import { EMPACHDInformationComponent } from './components/achd/information/achd-information.component';
import { EMPACHDLandingComponent } from './components/achd/landing/achd.landing.component';
import { EMPPaymentComponent } from './components/achd/payment/payment.component';
import { EMPPaymentAccountListComponent } from './components/achd/paymentaccount/paymentaccountlist.component';
import { EMPCreditCardLandingComponent } from './components/creditcard/landing/creditcard.landing.component';
import { EMPCreditCardPaymentComponent } from './components/creditcard/payment/creditcard.payment.component';
import { EMPEFTCreateFileComponent } from './components/eft/createfile/createfile.component';
import { EMPEFTInformationComponent } from './components/eft/information/eft-information.component';
import { EMPEFTLandingComponent } from './components/eft/landing/eft.landing.component';
import { EMPHomeComponent } from './components/home/home.component';
import { EMPProfileComponent } from './components/profile/profile.component';
import { EMPRegisterComponent } from './components/register/employer.register.component';
import { EMPSecondaryRegistrationComponent } from './components/register/secondary-registration/secondary-registration.component';
import { EmployerModuleComponent } from './employer.module.component';

const routes: Routes = [
  { path: '', component: EmployerModuleComponent },
  { path: 'register', component: EMPRegisterComponent },
  {
    path: 'completeregistration',
    component: EMPSecondaryRegistrationComponent,
  },

  {
    path: 'home',
    canActivate: [AuthGuardService],
    component: EMPHomeComponent,
  },

  {
    path: 'creditcard/payment',
    canActivate: [AuthGuardService],
    component: EMPCreditCardPaymentComponent,
  },
  {
    path: 'creditcard/landing',
    canActivate: [AuthGuardService],
    component: EMPCreditCardLandingComponent,
  },

  {
    path: 'profile',
    canActivate: [AuthGuardService],
    component: EMPProfileComponent,
  },
  {
    path: 'profile/settings',
    canActivate: [AuthGuardService],
    component: EMPProfileComponent,
  },

  {
    path: 'achd/landing',
    canActivate: [AuthGuardService],
    component: EMPACHDLandingComponent,
  },
  { path: 'achd/information', component: EMPACHDInformationComponent },
  {
    path: 'achd/payment',
    canActivate: [AuthGuardService],
    component: EMPPaymentComponent,
  },
  {
    path: 'achd/paymentaccounts',
    canActivate: [AuthGuardService],
    component: EMPPaymentAccountListComponent,
  },

  {
    path: 'eft/landing',
    canActivate: [AuthGuardService],
    component: EMPEFTLandingComponent,
  },
  { path: 'eft/information', component: EMPEFTInformationComponent },
  {
    path: 'eft/createfile',
    canActivate: [AuthGuardService],
    component: EMPEFTCreateFileComponent,
  },

  { path: '**', component: EMPHomeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployerRoutingModule {}
