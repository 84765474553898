import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalNavComponent } from './global-nav/global-nav.component';
import { PageContentComponent } from './page-content/page-content.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [GlobalNavComponent, PageContentComponent],
  imports: [CommonModule, RouterModule, NgbModule],
  exports: [GlobalNavComponent, PageContentComponent],
})
export class BaseLayoutModule {}
