import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BREAKPOINTS, BusyService } from 'epay-library';
import { AppLoadService } from '../app/appload/service/appload.service';

import { AuthService } from 'epay-library';
import { ErrorHandlerService } from 'epay-library';
import { ViewportService, CommonSettings } from 'epay-library';
import { AutoLogoutService } from './helpers/services/autologout.service';
import { observeOn } from 'rxjs/operators';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy{
  title = CommonSettings.Title;
  baseurl = CommonSettings.BaseApi;

  websiteMessage: any;
  isLoggedIn = false;
  isMobile = false;
  username = '';
  usertype = '';
  busy = false;
  message = '';
  errorMessage = '';

  constructor(
    private authservice: AuthService,
    private router: Router,
    private appLoadService: AppLoadService,
    private busyService: BusyService,
    private errorHandlerService: ErrorHandlerService,
    public viewpointService: ViewportService,
    public autologoutService: AutoLogoutService) {
      busyService.busyState$
      // asapScheduler ensures this is async; remove this and look in console to see nasty error without this
      .pipe(observeOn(asapScheduler))
      .subscribe((bs) => {
        this.busy = bs.isBusy;
        this.message = bs.message ? bs.message : '';
      });
    }

  ngOnInit() {
    this.viewpointService.IsMobile.subscribe(
      (ismobile: boolean) => this.isMobile = ismobile
    );
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnDestroy() {
    this.authservice.logout(this.baseurl);
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}
