
<mat-dialog-content>
  <div class="position-relative" style="width: 35vw; height: 35vh">
    <div class="row mx-1">
      <div class="col-8">
        <h3>CVV2 / CVC2 3-Digit Security Code</h3>
        <span>In order to process payments with Visa&#174; and Mastercard&#174; credit cards, we require the 3-digit CVV2 or CVC2 security code. This code is found on the back of your credit card.</span>
        <br><br>
      </div>
  
      <div class="col-4">
        <img src="../../../../../../assets/images/cvv2.gif" alt="CVV Help" style="width: 100%;">
      </div>
    </div>

    <div class="row mx-1">
      <h3>What is CVV2 or CVC2 and why do you need them?</h3>
      <span>The CVV2/CVC2 is a three-digit security code that is printed on the back of credit cards. 
      This code is used to establish that you are in possession of the card used for the payment. 
      </span>
      <br><br>
    </div>
    <br>
    
    <div class="row mx-1">
      <h3>Where is CVV2 located on my card?</h3>
      <span>The CVV2 three-digit value is printed just above the signature panel in reverse italics on the back of your card. Usually it is preceded by your card number.
      </span>
      <br><br>
    </div>
  </div>
  <br>

</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-primary-short" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>

