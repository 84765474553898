import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonSettings, ErrorHandlerService, IChallengeQuestion, RegisterLibraryService } from 'epay-library';
import { IEmpLogin } from 'epay-library/lib/features/employer/models/user/employerLogin';
import { IEmpProfileAccountLogin } from 'epay-library/lib/features/employer/models/user/profile/employerProfileAccountLogin';
import { IEmpProfileAccountResults } from 'epay-library/lib/features/employer/models/user/profile/employerProfileAccountResults';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'epay-secondary-registration',
  templateUrl: './secondary-registration.component.html'
})
export class EMPSecondaryRegistrationComponent implements OnInit {
  pagetitle = 'Employer: Complete Secondary Account Registration';
  baseurl: string;
  userEmailForLogin: boolean;

  constructor() {
    this.baseurl = CommonSettings.BaseApi;
    this.userEmailForLogin = CommonSettings.UseEmailForLogin;
   }

  ngOnInit() {
  }
}