import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-privacypolicy',
  templateUrl: './privacypolicy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {
  pagetitle = 'Privacy Policy';

  constructor() { }

  ngOnInit(): void {
  }

}
