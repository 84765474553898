import { Component, Input, OnInit } from '@angular/core';
import { CreditCardService, ErrorHandlerService } from 'epay-library';
import { Router } from '@angular/router';

@Component({
  selector: 'credit-card-success-common',
  templateUrl: './credit-card-success-common.component.html'
})
export class CreditCardSuccessCommonComponent implements OnInit {
  @Input() baseurl = '';
  @Input() encryptedTransactionId = '';
  @Input() clientId = '';

  paymentDate = '';
  empName = '';
  confirmationId = '';
  amount = 0;
  processFee = 0;
  totalAmount = 0;
  transactionId = '';
  payorType = '';

  errorMessage = '';

  constructor(
    private router: Router,
    private creditcardservice: CreditCardService,
    private errorhandleservice: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.creditcardservice
      .getCreditCardAttemptLogDetails(
        this.baseurl,
        this.encryptedTransactionId,
        this.clientId
      )
      .subscribe({
        next: (response: any) => {
          this.paymentDate = response[0].logEntryTime;
          this.empName = response[0].employerName;
          this.confirmationId = response[0].confirmationNumber;
          this.amount = +response[0].paymentAmount;
          this.processFee = +response[0].convenienceAmount;
          this.totalAmount = +response[0].totalPaymentAmount;
          this.transactionId = response[0].transactionNumber;
          this.payorType = response[0].payorType;
        },
        error: (error: any) => {
          console.log(error);
          this.logError(error);
          this.handleError(error.staus);
        },
      });
  }

  Print() {
    window.print();
  }

  GoToHomePage() {
    this.router.navigateByUrl('home');
  }

  logError(error: Error) {
    this.errorhandleservice.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    this.errorhandleservice.handleErrorMessage(error, this.baseurl);
  }
}
