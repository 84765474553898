import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService, RegisterLibraryService, AuthService } from 'epay-library';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { error } from '@angular/compiler/src/util';
import { debounceTime } from 'rxjs';

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password1Ctrl = c.get('newpassword1');
  const password2Ctrl = c.get('newpassword2');
  if (password1Ctrl === null || password2Ctrl === null) {
    return null;
  }
  if (password1Ctrl.pristine || password2Ctrl.pristine) {
    return null;
  }
  if (password1Ctrl.value === password2Ctrl.value) {
    return null;
  }
  return { doNotMatch: true };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (control) {
      return (control.dirty || control.touched) && control.parent!.invalid;
    }
    return false;
  }
}

@Component({
  selector: 'password-change',
  templateUrl: './password-change.component.html'
})
export class PasswordChangeComponent implements OnInit {
  @Input() baseurl = '';

  errorMatcher = new CrossFieldErrorMatcher();
  passwordFrmGp: any;
  currentPasswordHide = true;
  passwordHide = true;
  passwordConfirmHide = true;
  errorMessage = '';
  loginPattern =
    /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;

  passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  oldPasswordError = false;

  openConfirmation = false;
  confirmationTitle = '';
  confirmationMessage = '';
  confirmationNumber = '';
  goToPrimaryUrl = '';
  goToPrimary = '';
  restart = false;
  passwordHint =
    'Must contain at least 6 letters, 2 numbers, and at least 1 special character with a maximum of 12 characters in length.';

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private registerService: RegisterLibraryService,
    private authservice: AuthService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadFormGroups();
  }

  toggleCurrentPasswordHide() {
    this.currentPasswordHide = !this.currentPasswordHide;
  }

  togglePasswordHide() {
    this.passwordHide = !this.passwordHide;
  }

  toggleConfirmPasswordHide() {
    this.passwordConfirmHide = !this.passwordConfirmHide;
  }

  changePassword() {
    const updatepassword = {
      oldPassword: this.passwordFrmGp.get('currentpassword').value,
      newPassword: this.passwordFrmGp.get('newpassword1').value,
      confirmNewPassword: this.passwordFrmGp.get('newpassword2').value,
    };
    this.registerService.resetPassword(this.baseurl, updatepassword).subscribe({
      next: (result: any) => {
        this.onSaveComplete(result.confirmationNumber);
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  onSaveComplete(confirmationNum: string) {
    this.passwordFrmGp.reset();
    this.confirmationTitle = 'Success';
    this.confirmationMessage = `Your password has been changed. Please click "Log Out" below to login with new password.`;
    this.confirmationNumber = confirmationNum;
    this.goToPrimaryUrl = '/login';
    this.goToPrimary = 'Log Out';
    this.restart = true;
    this.openConfirmation = true;
  }

  loadFormGroups() {
    this.passwordFrmGp = this.formbuilder.group(
      {
        currentpassword: ['', Validators.required],
        newpassword1: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(12),
            Validators.pattern(this.passwordPattern),
          ],
        ],
        newpassword2: ['', Validators.required],
      },
      { validator: passwordMatcher }
    );
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}

