<div class="container m--full-bleed">
    <div class="form-display-card">
      <mat-card class='mat-card-display mb-5'>
        <mat-card-header>
          <mat-card-title class="justify-content-right">
            <h2 class="mat-card-title align-items-start">
              <span class="fa fa-credit-card fa-md-black mr-2"></span> Credit Card Payment Successful
            </h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="section-to-print">
            <p class="mb-5">Your payment has been successfully processed and a receipt has been sent to the email
              address you provided.</p>
            <div class="row m--full-bleed">
              <!-- Employer, if applicable -->
              <div *ngIf="payorType === 'EMP'" class="col-12">
                <dl class="account-details-sum">
                  <div class="account-sum-row">
                    <dt class="account-dt">Employer Name</dt>
                    <dd class="account-dt-info">{{empName}}</dd>
                  </div>
                </dl>
              </div>
              <div class="col-12 col-md-6">
                <!-- overview-->
                <dl class="account-details-sum">
                  <div class="account-sum-row">
                    <dt class="account-dt">Date</dt>
                    <dd class="account-dt-info">{{paymentDate | validDate}}</dd>
                  </div>
                  <div class="account-sum-row">
                    <dt class="account-dt">Confirmation Number</dt>
                    <dd class="account-dt-info">{{confirmationId}}</dd>
                  </div>
                </dl>
              </div>
              <div class="col-12 col-md-6">
                <!-- payment details -->
                <dl class="account-details-sum">
                  <div class="account-sum-row">
                    <dt class="account-dt">Amount</dt>
                    <dd class="account-dt-info">{{amount | currency}}</dd>
                  </div>
                  <!-- <div class="account-sum-row">
                    <dt class="account-dt">Service Fee</dt>
                    <dd class="account-dt-info">{{processFee | currency}}</dd>
                  </div> -->
                  <div class="account-sum-row">
                    <dt class="account-dt">Total Amount</dt>
                    <dd class="account-dt-info">{{totalAmount | currency}}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="t-alignC pt-2 pb-2">
        <button class="btn" (click)="GoToHomePage()">Home</button>
        <button class="btn" (click)=Print()>Print</button>
      </div>
    </div>
  </div>