<epay-page-content [pagetitle]="pagetitle">
  <div *ngIf="!openConfirmation">
    <div class="container m--full-bleed mt-2">
      <div class="row m--full-bleed justify-content-center">
        <div class="col-12 col-lg-8">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                <h2 class="mat-card-title mb-0">File History <a target="_blank"
                    href="/assets/help/How To Reconcile Employees Create File.pdf" class="dib ml-1">
                    <span class="fa fa-question-circle fa-sm-blue"
                      matTooltip="HOW TO RECONCILE EMPLOYEES AND/OR CREATE FILE" matTooltipClass="tooltip-md">
                    </span>
                  </a></h2>
                <div>
                  <a [routerLink]="['/employer/eft/createfile']" class="ts-s">
                    Add
                    <span class="fa fa-plus-circle fa-sm-blue" matTooltip="ADD NEW EFT/EDI FILE"
                      matTooltipClass="tooltip-md">
                    </span>
                  </a>
                </div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content style="max-height: 50vh; overflow: auto;">
              <epay-library-employer-eft-filehistory [baseurl]="baseurl"
                [daysToShowEFTFileHistory]="daysToShowEFTFileHistory">
              </epay-library-employer-eft-filehistory>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="!isMobile" class="col-12 col-lg-4 form-display-card">
          <div class="row m--full-bleed">
            <mat-card class="info-help-panel">
              <mat-card-header>
                <mat-card-title>My Account</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="flex-ico-row mb-3">
                  <div class="flex-ico">
                    <span class="fa fa-address-card-o fa-lg-gray"></span>
                  </div>
                  <div class="flex-text">
                    <h3 class="mb-1">{{username}} {{isManager && '(Manager)'}}
                    </h3>
                    <address class="ts-s">
                      {{accountAddress}} {{accountCSZ}}
                    </address>
                  </div>
                </div>
                <dl class="account-details-sum">
                  <div class="account-sum-row">
                    <dt class="account-dt">Employer</dt>
                    <dd class="account-dt-info">{{employerName}} </dd>
                  </div>
                  <div class="account-sum-row">
                    <dt class="account-dt">Phone</dt>
                    <dd class="account-dt-info">{{accountPhone | mask: phoneMask}} </dd>
                  </div>
                  <div class="account-sum-row">
                    <dt class="account-dt">Email</dt>
                    <dd class="account-dt-info">{{accountEmail}} </dd>
                  </div>
                </dl>
              </mat-card-content>
              <hr>
              <div *ngIf="numOfUsers === 1 && isManager" class="text-center">
                <mat-card-content>
                  <div class="ts-base">
                    <strong>No additional user found.</strong><br />
                    Click <a [routerLink]="profileSettingsUrl" [state]="{display: 'account'}"><i
                        class="fa fa-user-plus"></i> HERE</a> to add an additional user.
                  </div>
                </mat-card-content>
                <hr>
              </div>
              <mat-card-content>
                <div class="t-alignC">
                  <a class="btn-link mx-3 mb-3" [routerLink]="profileSettingsUrl">Profile and Settings</a>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <epay-lib-common-confirmation *ngIf="openConfirmation" [confirmationTitle]="confirmationTitle"
    [confirmationMessage]="confirmationMessage" [confirmationNumber]="confirmationNumber" [goToPrimary]="goToPrimary"
    [goToPrimaryUrl]="goToPrimaryUrl">
  </epay-lib-common-confirmation>

</epay-page-content>
