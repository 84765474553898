<footer class="page-footer">
  <div class="footer-nav ">
    <ul class="footer-nav-menu">
      <li>
        <div *ngIf="!(isLoggedIn$ | async)">
          <a [routerLink]="['/home']">Home</a>
        </div>
        <div *ngIf="(isLoggedIn$ | async) && (usertype$ | async) === 'employer'">
          <a [routerLink]="['/employer/home']">Home</a>
        </div>
        <div *ngIf="(isLoggedIn$ | async) && (usertype$ | async) === 'NCP'">
          <a [routerLink]="['/ncp/achd/landing']">Home</a>
        </div>
      </li>
      <li><a [routerLink]="['/demo']">Demo</a></li>
      <li><a [routerLink]="['/contact']">Contact Us</a></li>
      <li><a [routerLink]="['/privacypolicy']">Privacy Policy</a></li>
      <li><a [routerLink]="['/faq']"> FAQs</a></li>
      <li><a [routerLink]="['/securitypolicy']">Security</a></li>
    </ul>
  </div>
  <div class="footer-small-text">
    <p>Please read our <a [routerLink]="['/termsandconditions']">Terms and Conditions</a> and
      <a [routerLink]="['/webaccessibility']">Web Accessibility Policy</a>
    </p>
  </div>
</footer>