import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import PhoneNumber from 'awesome-phonenumber';
import { ICountry, IState, ErrorHandlerService, CommonService, RegisterLibraryService, AuthService, PhoneHelperService, ConfirmDialogModel, ConfirmDialogComponent } from 'epay-library';
import { IEmpProfileAccountLogin } from 'epay-library/lib/features/employer/models/user/profile/employerProfileAccountLogin';
import { IEmpProfileAccountResults } from 'epay-library/lib/features/employer/models/user/profile/employerProfileAccountResults';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'employer-profile-account',
  templateUrl: './employer-profile-account.component.html'
})
export class EmployerProfileAccountComponent implements OnInit {
  @Input() baseurl = '';
  @Input() useEmailForLogin = false;
  @Output() profileConfirmationEmitter = new EventEmitter<{
    success: boolean;
    message: string;
    confirmationNumber: string;
    showConfirmationEmail: boolean;
  }>();

  loginHintMessage =
  'Must contain at least 8 characters. Alphanumerical values and email format are accepted.';

  isAccountManager = false;
  numOfAccounts = 0;
  countries: ICountry[] = [];
  selectedCountry = '';
  states: IState[] = [];
  selectedState = '';
  errorMessage = '';
  emailMessage = '';
  loginErrorMessage = '';
  loginSuccessMessage = '';
  isCheckingLogin = false;
  isValidLogin = false;
  isValidEmail = false;
  isCheckingEmail = false;
  emailErrorMessage = '';
  emailSuccessMessage = '';
  profileAccountResults: IEmpProfileAccountResults =
    {} as IEmpProfileAccountResults;
  profileAccounts: IEmpProfileAccountLogin[];
  secondaryAccount: IEmpProfileAccountLogin = {} as IEmpProfileAccountLogin;
  secondaryUserNumber = '';
  showAccountForm = false;
  deleteresult = '';
  isEdit = false;
  userNumber = '';

  profileAccountHeader = [
    'Username',
    'Name',
    'Account',
    'Creation Date',
    'Action',
  ];

  secondaryAccountFrmGrp: FormGroup = new FormGroup({});
  phonePattern = /^((\\+91-?)|0)?[0-9]{10}$/;
  //loginPattern = /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;
  loginPattern = /^[a-zA-Z0-9.@]*$/;
  zipPattern = /^[0-9]{5}$/;
  emailPattern = /\S+@\S+\.\S+/;
  phoneExample = '';
  phoneMask = '';

  validationLoginIdMessages: { [key: string]: string } = {
    required: 'Username is required.',
    pattern:
      'Invalid Username. Must contain only alphanumerical values with the exception of email format.',
    minlength: 'Invalid Username.  Must contain at least 8 characters.',
    isDuplicate: 'Username is not available for use.',
  };

  validationEmailMessages: { [key: string]: string } = {
    required: 'Required.',
    pattern: 'Please enter a valid email address.',
    isDuplicate: 'Registration already exist for this email.',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private commonService: CommonService,
    private registerService: RegisterLibraryService,
    private authService: AuthService,
    private phoneHelperService: PhoneHelperService
  ) {
    this.profileAccounts = [];
    this.showAccountForm = false;
    this.isEdit = false;
    this.selectedCountry = 'US';
  }

  ngOnInit() {
    this.loadFormGroups();
    this.getCountries();
    this.getStates();
    this.loadEmployerAccounts();
    this.authService.currentUserAccountManager.subscribe(
      (manager) =>
        (this.isAccountManager =
          manager.toLowerCase() === 'true' ? true : false)
    );

    this.authService.currentUserNumber.subscribe(
      (num) => (this.userNumber = num)
    );

    if (!this.isAccountManager) {
      this.profileAccountHeader = this.profileAccountHeader.filter(
        (x) => x !== 'Action'
      );
    }

    const phoneCtrl = this.secondaryAccountFrmGrp.get('phoneGp.phoneNumber');
    const countryCtrl = this.secondaryAccountFrmGrp.get('country');
    const loginCtrl = this.secondaryAccountFrmGrp.get('loginId');
    const emailCtrl = this.secondaryAccountFrmGrp.get('email');

    loginCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if ((loginCtrl?.touched || loginCtrl?.dirty) && loginCtrl?.errors) {
        this.loginErrorMessage = Object.keys(loginCtrl!.errors)!
          .map((key: any) => this.validationLoginIdMessages[key])
          .join(' ');
        this.isValidLogin = false;
      } else {
        this.isCheckingLogin = true;
        if (!this.useEmailForLogin) {
          this.registerService
            .searchDuplicateLogin(this.baseurl, value)
            .subscribe({
              next: (result: any) => {
                this.setLoginMessage(loginCtrl!, result);
                this.isCheckingLogin = false;
              },
              error: (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              },
            });
        } else {
          this.registerService
            .searchDuplicateEmail(this.baseurl, value)
            .subscribe({
              next: (result: any) => this.setLoginMessage(loginCtrl!, result),
              error: (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              },
            });
        }
      }
    });

    emailCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((email) => {
      this.setEmailMessage(emailCtrl!, email);
    });

    phoneCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value) {
        const pn = new PhoneNumber(
          value,
          this.secondaryAccountFrmGrp.get('phoneGp.regionCode')!.value
        );
        this.secondaryAccountFrmGrp.get('phoneGp')!.patchValue({
          isValid: pn.isPossible(),
        });
      }
    });

    countryCtrl!.valueChanges.subscribe((value) => {
      if (value) {
        this.setMaskAndExample(value);
        this.secondaryAccountFrmGrp.get('phoneGp')!.patchValue({
          regionCode: value,
          countryCode:
            this.phoneHelperService.getCountryCodeForRegionCode(value),
          phoneNumber: '',
          isValid: false,
        });
        this.secondaryAccountFrmGrp.patchValue({
          state: '',
          zip5: '',
        });
        if (value === 'US') {
          this.secondaryAccountFrmGrp
            .get('zip5')!
            .setValidators([
              Validators.required,
              Validators.pattern(this.zipPattern),
            ]);
        } else {
          this.secondaryAccountFrmGrp
            .get('zip5')!
            .setValidators([Validators.required, Validators.maxLength(6)]);
        }
        if (value === 'US' || value === 'MX' || value === 'CA') {
          this.selectedCountry = value;
          this.secondaryAccountFrmGrp.get('state')!.enable();
          this.secondaryAccountFrmGrp
            .get('state')!
            .setErrors({ required: true });
          this.secondaryAccountFrmGrp.updateValueAndValidity();
          this.getStates();
        } else {
          this.secondaryAccountFrmGrp.get('state')!.disable();
          this.secondaryAccountFrmGrp
            .get('state')!
            .setErrors({ required: false });
          this.secondaryAccountFrmGrp.updateValueAndValidity();
        }
      }
    });
  }

  displayAccountForm() {
    this.showAccountForm = true;
    this.isEdit = false;
    this.isValidLogin = false;
    this.isCheckingLogin = false;
    this.secondaryAccountFrmGrp.get('loginId')!.enable();
    this.secondaryAccountFrmGrp.reset();
    this.secondaryAccountFrmGrp.patchValue({
      country: 'US',
    });
    this.setMaskAndExample('US');
  }

  loadEmployerAccounts() {
    this.profileAccounts = [];
    this.numOfAccounts = 0;

    this.registerService.getEmployerProfileAccounts(this.baseurl).subscribe({
      next: (accounts: any) => {
        this.profileAccountResults = accounts;
        this.profileAccountResults.loginDetails.forEach((account) => {
          this.profileAccounts.push(account);
        });

        this.profileAccounts = this.profileAccounts.sort((a, b) =>
          a.isAccountManager > b.isAccountManager ? -1 : 1
        );
        this.numOfAccounts = this.profileAccounts.length;
        sessionStorage.setItem('numberOfUsers', this.numOfAccounts.toString());
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  insertUpdateSecondaryAccount() {
    if (this.secondaryAccountFrmGrp.valid) {
      if (this.secondaryAccountFrmGrp.dirty) {
        const secondaryAccount = {
          loginId: this.useEmailForLogin
            ? this.secondaryAccountFrmGrp.get('email')!.value.trim()
            : this.secondaryAccountFrmGrp.get('loginId')!.value.trim(),
          firstName: this.secondaryAccountFrmGrp.get('firstName')!.value.trim(),
          middleName:
            this.secondaryAccountFrmGrp.get('middleName')!.value === null
              ? ''
              : this.secondaryAccountFrmGrp.get('middleName')!.value.trim(),
          lastName: this.secondaryAccountFrmGrp.get('lastName')!.value.trim(),
          address1: this.secondaryAccountFrmGrp.get('address1')!.value.trim(),
          address2:
            this.secondaryAccountFrmGrp.get('address2')!.value === null
              ? ''
              : this.secondaryAccountFrmGrp.get('address2')!.value.trim(),
          city: this.secondaryAccountFrmGrp.get('city')!.value.trim(),
          state: this.secondaryAccountFrmGrp.get('state')!.value.trim(),
          zip5: this.secondaryAccountFrmGrp.get('zip5')!.value.trim(),
          zip4: '',
          countryCode:
            this.secondaryAccountFrmGrp.get('country')!.value === null
              ? 'US'
              : this.secondaryAccountFrmGrp.get('country')!.value.trim(),
          phone: this.secondaryAccountFrmGrp
            .get('phoneGp.phoneNumber')!
            .value.trim(),
          phoneExt:
            this.secondaryAccountFrmGrp.get('phoneExt')!.value === null
              ? ''
              : this.secondaryAccountFrmGrp.get('phoneExt')!.value.trim(),
          email: this.secondaryAccountFrmGrp.get('email')!.value.trim(),
        };

        if (!this.isEdit) {
          this.registerService
            .addSecondaryAccount(this.baseurl, secondaryAccount)
            .subscribe(
              (response: any) => {
                this.profileConfirmationEmitter.emit({
                  success: true,
                  message:
                    'Account for ' +
                    secondaryAccount.firstName +
                    ' ' +
                    secondaryAccount.lastName +
                    ' has been created. An email was sent to ' +
                    secondaryAccount.email +
                    ' with more instructions to complete their registration.',
                  confirmationNumber: response.confirmationNumber,
                  showConfirmationEmail: false,
                });
                this.onSaveComplete();
              },
              (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              }
            );
        } else {
          this.registerService
            .updateUserAccount(
              this.baseurl,
              secondaryAccount,
              +this.secondaryUserNumber
            )
            .subscribe(
              (response: any) => {
                this.profileConfirmationEmitter.emit({
                  success: true,
                  message:
                    'Account for ' +
                    secondaryAccount.firstName +
                    ' ' +
                    secondaryAccount.lastName +
                    ' has been updated. A confirmation email was sent to ' +
                    secondaryAccount.email +
                    '.',
                  confirmationNumber: response.confirmationNumber,
                  showConfirmationEmail: false,
                });
                this.onSaveComplete();
              },
              (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              }
            );
        }
      }
    }
  }

  onSaveComplete() {
    this.secondaryAccountFrmGrp.reset();
    this.showAccountForm = false;
    this.secondaryUserNumber = '';
    // this.loadEmployerAccounts();
    // this.router.navigate(['/employer/profile/account']);
  }

  editaccount(account: IEmpProfileAccountLogin) {
    this.states = [];
    this.showAccountForm = true;
    this.isEdit = true;
    this.secondaryUserNumber = account.userNumberExternal;
    this.secondaryAccountFrmGrp.patchValue(
      {
        loginId: account.loginId,
        firstName: account.contactInfo.firstName,
        middleName: account.contactInfo.middleName,
        lastName: account.contactInfo.lastName,
        address1: account.contactInfo.address1,
        address2: account.contactInfo.address2,
        country: account.contactInfo.countryId,
        city: account.contactInfo.city,
        zip5: account.contactInfo.zip5.trim(),
        phoneExt: account.contactInfo.phoneExt,
        state: account.contactInfo.state,
      },
      { emitEvent: true }
    );
    this.secondaryAccountFrmGrp.patchValue(
      {
        email: account.contactInfo.email,
      },
      { emitEvent: false }
    );

    if (account.contactInfo.state.trim() !== '') {
      this.secondaryAccountFrmGrp
        .get('zip5')!
        .setValidators([
          Validators.required,
          Validators.pattern(this.zipPattern),
        ]);
      this.secondaryAccountFrmGrp
        .get('state')!
        .setValidators([Validators.required]);
      this.secondaryAccountFrmGrp.updateValueAndValidity();
    } else {
      this.secondaryAccountFrmGrp
        .get('zip5')!
        .setValidators([Validators.required, Validators.maxLength(6)]);
      this.secondaryAccountFrmGrp.get('zip5')!.updateValueAndValidity();
      this.secondaryAccountFrmGrp.get('state')!.clearValidators();
      this.secondaryAccountFrmGrp.get('state')!.disable();
      this.secondaryAccountFrmGrp.updateValueAndValidity();
    }
    this.secondaryAccountFrmGrp.get('phoneGp')!.patchValue(
      {
        regionCode: account.contactInfo.countryId,
        phoneNumber: account.contactInfo.phone
          .substring(account.contactInfo.phone.trim().lastIndexOf(' '))
          .trim(),
        countryCode: this.phoneHelperService.getCountryCodeForRegionCode(
          account.contactInfo.countryId
        ),
        isValid: true,
      },
      { emitEvent: true }
    );
    this.secondaryAccountFrmGrp.get('loginId')!.disable();

    this.setMaskAndExample(
      this.secondaryAccountFrmGrp.get('country')!.value.trim()
    );

    console.log(this.secondaryAccountFrmGrp);
    console.log(this.secondaryAccountFrmGrp.valid);
  }

  deleteaccount(account: IEmpProfileAccountLogin) {
    const message = `Are you sure you want to delete this account?`;
    const acctdetail = account.userName + ' - ' + account.loginId;
    const dialogData = new ConfirmDialogModel(
      'Confirm Deleting Account',
      message,
      acctdetail,
      '',
      '',
      '',
      ''
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '700px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe({
      next: (dialogResult: any) => (this.deleteresult = dialogResult),
      error: (error: any) => console.log(error),
      complete: () => {
        if (this.deleteresult) {
          this.registerService
            .deleteSecondaryAccount(this.baseurl, +account.userNumberExternal)
            .subscribe({
              next: (success: any) => {
                this.profileConfirmationEmitter.emit({
                  success: true,
                  message:
                    'Account for ' +
                    account.contactInfo.firstName +
                    ' ' +
                    account.contactInfo.lastName +
                    ' has been deleted. An email was sent to ' +
                    account.contactInfo.email +
                    ' with confirmation.',
                  confirmationNumber: success.confirmationNumber,
                  showConfirmationEmail: false,
                });
                // this.loadEmployerAccounts();
                this.onSaveComplete();
              },
              error: (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              },
            });
        }
      },
    });
  }

  moveToPrimary(account: IEmpProfileAccountLogin) {
    const message = `Are you sure you change the Account Manager to:`;
    const acctdetail = account.userName + ' - ' + account.loginId;
    const dialogData = new ConfirmDialogModel(
      'Confirm Changing Account',
      message,
      acctdetail,
      '',
      '',
      '',
      ''
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '700px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe({
      next: (dialogResult: any) => (this.deleteresult = dialogResult),
      error: (error: any) => console.log(error),
      complete: () => {
        if (this.deleteresult) {
          this.registerService
            .makeAccountManager(this.baseurl, +account.userNumberExternal)
            .subscribe({
              next: (success: any) => this.onSaveComplete(),
              error: (error: any) => {
                this.logError(error);
                this.handleError(error.status);
              },
              complete: () => {
                this.openSnackBar(
                  'Account ' + account.loginId + ' is now Account Manager.',
                  5000,
                  'snackbar-success'
                );
                sessionStorage.setItem('isAccountManager', 'false');
                this.isAccountManager = false;
                this.profileAccountHeader = this.profileAccountHeader.filter(
                  (x) => x !== 'Action'
                );
              },
            });
        }
      },
    });
  }

  loadFormGroups() {
    this.secondaryAccountFrmGrp = this.formbuilder.group({
      loginId: [
        '',
        [ Validators.required,
          Validators.minLength(8),
          //Validators.maxLength(12),
          Validators.pattern(this.loginPattern),],
      ],
      firstName: ['', Validators.required],
      middleName: '',
      lastName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip5: ['', [Validators.required]],
      country: ['US', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phoneGp: this.formbuilder.group({
        regionCode: 'US',
        phoneNumber: ['', [Validators.required]],
        countryCode: '1',
        isValid: [false, Validators.requiredTrue],
      }),
      phoneExt: '',
    });

    // this.setMaskAndExample('US');
    // set default country to US
    // this.secondaryAccountFrmGrp.patchValue({
    //   country: 'US',
    // });

    if (this.useEmailForLogin) {
      this.secondaryAccountFrmGrp.get('loginId')?.clearValidators();
      this.secondaryAccountFrmGrp.updateValueAndValidity();
    }
  }

  getCountries() {
    this.commonService.getCountries(this.baseurl).subscribe({
      next: (countries: any) => {
        this.countries = countries;
        this.countries = this.countries.sort((a, b) =>
          a.countryName < b.countryName ? 1 : -1
        );
        this.countries.push({
          country: 'MX',
          countryName: 'Mexico',
          alpha3IsoCode: 'MEX',
          numeric3IsoCode: '484',
        });
        this.countries.push({
          country: 'CA',
          countryName: 'Canada',
          alpha3IsoCode: 'CAN',
          numeric3IsoCode: '124',
        });
        this.countries.push({
          country: 'US',
          countryName: 'United States',
          alpha3IsoCode: 'USA',
          numeric3IsoCode: '840',
        });
        this.countries.reverse();
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  getStates() {
    this.selectedCountry = this.secondaryAccountFrmGrp.get('country')!.value;
    this.commonService.getStates(this.baseurl, this.selectedCountry).subscribe({
      next: (states) => (this.states = states),
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  setEmailMessage(emailControl: AbstractControl, email: any): void {
    this.resetEmailCheck();
    if (emailControl.touched || emailControl.dirty) {
      if (!emailControl.errors) {
        this.isCheckingEmail = true;
        this.registerService
          .searchDuplicateEmail(this.baseurl, email)
          .subscribe({
            next: (result: any) => {
              this.isValidEmail = !result.isDuplicate;
              if (this.isValidEmail) {
                this.emailSuccessMessage = 'Email is available!';
              } else {
                emailControl.setErrors({ isDuplicate: true });
                this.emailErrorMessage = Object.keys(emailControl.errors!)
                  .map((key) => this.validationEmailMessages[key])
                  .join(' ');
                this.secondaryAccountFrmGrp.updateValueAndValidity();
              }
              this.isCheckingEmail = false;
            },
            error: (error: any) => {
              this.resetEmailCheck();
              this.errorMessage = error as any;
              this.logError(error);
              this.handleError(error.status);
            },
          });
      } else {
        this.emailErrorMessage = Object.keys(emailControl.errors)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      }
    }
  }
  setLoginMessage(loginControl: AbstractControl, result: any) {
    this.loginErrorMessage = '';
    this.loginSuccessMessage = '';

    if (loginControl.dirty) {
      this.isValidLogin = !result.isDuplicate;
      if (this.isValidLogin) {
        this.loginSuccessMessage = 'Username Available!';
      } else {
        loginControl.setErrors({ isDuplicate: true });
        if (!this.useEmailForLogin) {
          this.loginErrorMessage = Object.keys(loginControl.errors!)
            .map((key) => this.validationLoginIdMessages[key])
            .join(' ');
        } else {
          this.loginErrorMessage = Object.keys(loginControl.errors!)
            .map((key) => this.validationEmailMessages[key])
            .join(' ');
        }
      }
    }
  }

  resetEmailCheck() {
    this.isValidEmail = false;
    this.isCheckingEmail = false;
    this.emailSuccessMessage = '';
    this.emailErrorMessage = '';
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  setMaskAndExample(regionCode: string) {
    this.phoneExample = this.phoneHelperService.getExample(regionCode);
    this.phoneMask = this.phoneHelperService.getMask(regionCode);
  }

  closeForm() {
    this.showAccountForm = false;
    this.secondaryAccountFrmGrp.get('loginId')!.enable();
    this.secondaryAccountFrmGrp.reset();
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}

