import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-webaccessibility',
  templateUrl: './webaccessibility.component.html'
})
export class WebAccessibilityComponent implements OnInit {
  pagetitle = 'Accessibility';

  constructor() { }

  ngOnInit(): void {
  }

}
