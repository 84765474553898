import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'interactive-credit-card-emp',
  templateUrl: './interactive-credit-card-emp.component.html',
  styleUrls: ['./interactive-credit-card-emp.component.scss']
})
export class InteractiveCreditCardEMPComponent {
  @Input('flip') flip = false;

  _no: string = "---- ---- ---- ----";
  _cvc: string = "---";
  _expMonth: string = "--";
  _expYear: string = "--";
  _holder: string = "----- ----"

  @Input('cvvHide') cvvHide!: boolean;
  @Input('ccHide') ccHide!: boolean;

  @Input('ccType') ccType = '';

  @Input('no') set no(value: any) {
    if (value) {
      this._no = this.formatCreditCardNumber(value);
    }
  }

  @Input('cvc') set cvc(value: any) {
    if (value) {
      this._cvc = value;
    } 
    else {
      this._cvc = ""
    }
  }

  @Input('holder') set holder(value: any) {
    if (value) {
      this._holder = value;
    }
    else {
      this._holder = '';
    }
  }

  @Input('expMonth') set expMonth(value: any) {
    if (value) this._expMonth = value;
  }

  @Input('expYear') set expYear(value: any) {
    if (value) this._expYear = value;
  }

  formatCreditCardNumber(value: any) {
    var v = value.replace(/\s+/g, '').replace(/([^0-9])/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    for (var i=0, len=match.length; i<len; i+=4) {
      parts.push(match.substring(i, i+4))
    }
    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }
}
