import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CommonSettings } from 'epay-library';
import { AppModule } from './app/app.module';

if (
  CommonSettings.Environment === 'PROD' ||
  CommonSettings.Environment === 'prod'
) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
    const errorMsgTitle = document.querySelector('#errorMsgTitle');
    const errorMsgElement = document.querySelector('#errorMsgElement');
    let message = '';
    let title =
      'An error occurred loading the website.  Please try back at a later time.';
    if (err) {
      if (err.message) {
        message = 'ERROR: ' + err.message;
      } else {
        message = 'ERROR: ' + err;
      }
    }
    errorMsgTitle!.textContent = title;
    errorMsgElement!.textContent = message;
  });
