<nav [ngClass]="(isLoggedIn$ | async) ? 'authed' : 'unauthed'" id="mainNav">
  <header class="site-header">
    <div class="branding">

      <div *ngIf="!(isLoggedIn$ | async)">
        <a class="logo-home-link" [routerLink]="['/home']">
          <span class="logo-home-block">
            <img class="fit" src="../../../../assets//images/thumbnail_okhs_logo.png"></span>
        </a>
      </div>

      <div *ngIf="(isLoggedIn$ | async) && (usertype$ | async) === 'employer'">
        <a class="logo-home-link" [routerLink]="['/employer/home']">
          <span class="logo-home-block">
            <img class="fit" src="../../../../assets//images/thumbnail_okhs_logo.png"></span>
        </a>
      </div>

      <div *ngIf="(isLoggedIn$ | async) && (usertype$ | async) === 'NCP'">
        <a class="logo-home-link" [routerLink]="['/ncp/achd/landing']">
          <span class="logo-home-block">
            <img class="fit" src="../../../../assets//images/thumbnail_okhs_logo.png"></span>
        </a>
      </div>

      <span class="site-title">
        <b class="tf-serif">SMART Oklahoma <span class="text-unit">Child Support</span></b>
        <span class="subtitle tf-sans">Internet Payment Website</span>
      </span>
    </div>
    <div class="welcome welcome-area">
      <span *ngIf="(isLoggedIn$ | async) && !isMobile">Welcome, {{username$ | async}} </span>
    </div>
    <button class="global-nav-toggler navbar-toggler" type="button" aria-expanded="false"
      aria-label="Toggle Global Navigation" (click)="toggleGlobalNav($event)" #mobileNavToggleBtn>
      <b class="fa fa-bars fa-lg"></b>
    </button>
  </header>
  <div class="navbar navbar-expand-lg page-nav-block">
    <div id="global-nav-block" class="collapse navbar-collapse" [ngClass]="{'show': isGlobalNavOpen}" #globalNavBlock>
      <div *ngIf="(isLoggedIn$ | async); else elseBlock" class="authed-nav-block">
        <div *ngIf="(usertype$ | async) === 'employer'; else ncpNavBlock" class="employer-nav-block" #employerNavBlock>
          <ul ngbNav #navEmployer="ngbNav" class="nav-pills global-nav-menu">
            <li [ngbNavItem]="1">
              <a class="nav-link global-nav-link" [routerLink]="['/employer/home']">Home</a>
            </li>
            <li [ngbNavItem]="2" ngbDropdown>
              <a href (click)="false" class="nav-link" ngbDropdownToggle>ACH Debit
                <span class="fa fa-chevron-circle-down"></span>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-child" [routerLink]="['/employer/achd/landing']" ngbDropdownItem>ACH Debit Home</a>
                <a class="dropdown-child" [routerLink]="['/employer/achd/payment']" ngbDropdownItem>Create New
                  Payment</a>
                <a class="dropdown-child" [routerLink]="['/employer/achd/paymentaccounts']" ngbDropdownItem>Manage Bank
                  Accounts</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-child" [routerLink]="['/employer/achd/information']" ngbDropdownItem>About ACH
                  Debit</a>
              </div>
            </li>
            <li [ngbNavItem]="3" ngbDropdown>
              <a href (click)=false class="nav-link" ngbDropdownToggle>ACH Credit (EFT/EDI)
                <span class="fa fa-chevron-circle-down"></span>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-child" [routerLink]="['/employer/eft/landing']" ngbDropdownItem>ACH Credit (EFT/EDI)
                  Home</a>
                <a class="dropdown-child" [routerLink]="['/employer/eft/createfile']" ngbDropdownItem>Reconcile
                  Employees and/or Create
                  File</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-child" [routerLink]="['/employer/eft/information']" ngbDropdownItem>About ACH Credit
                  (EFT/EDI)</a>
              </div>
            </li>
            <li [ngbNavItem]="4" ngbDropdown>
              <a href (click)=false class="nav-link" ngbDropdownToggle>Credit Card
                <span class="fa fa-chevron-circle-down"></span>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-child" [routerLink]="['/employer/creditcard/landing']" ngbDropdownItem>Credit Card
                  Home</a>
                <a class="dropdown-child" [routerLink]="['/employer/creditcard/payment']" ngbDropdownItem>Make a Credit
                  Card Payment</a>
              </div>
            </li>
            <li [ngbNavItem]="5" ngbDropdown>
              <a href (click)="false" class="nav-link" ngbDropdownToggle>Helpful Links
                <span class="fa fa-chevron-circle-down"></span>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-child" [routerLink]="['/employer/achd/information']" ngbDropdownItem>ACH Debit </a>
                <a class="dropdown-child" [routerLink]="['/employer/eft/information']" ngbDropdownItem>ACH Credit
                  (EFT/EDI) </a>
                <a class="dropdown-child" download="employereft" target="_blank" href="/assets/forms/employereft.pdf"
                  ngbDropdownItem>
                  Employer ACH Credit (EFT/ EDI) Packet
                  <i class="fa fa-download" aria-hidden="true"></i></a>
                <a class="dropdown-child"
                  href="https://www.nacha.org/system/files/resources/Child-Support-User-%20Guide-%20Feb%202017%202.pdf"
                  target="_blank" ngbDropdownItem>NACHA&rsquo;s User Guide for Electronic Payments</a>
                <a class="dropdown-child" [routerLink]="['/demo']" ngbDropdownItem>Website Help</a>
              </div>
            </li>
            <li class="nav-spacing" aria-hidden="true"></li>
            <li [ngbNavItem]="6" ngbDropdown>
              <a href (click)=false class="nav-link" ngbDropdownToggle>Account
                <span class="fa fa-user-circle-o"></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-child" [routerLink]="employerProfileUrl" ngbDropdownItem>Profile and Settings</a>
                <a class="dropdown-child" (click)="logout()" ngbDropdownItem>Log Out</a>
              </div>
            </li>
          </ul>
          <div [ngbNavOutlet]="navEmployer" class="authed-global-nav-block"></div>

        </div>
        <ng-template #ncpNavBlock>
          <div class="ncp-nav-block" id="ncp-nav-block">
            <ul ngbNav #navNcp="ngbNav" class="nav-pills global-nav-menu">
              <li [ngbNavItem]="1">
                <a class="nav-link global-nav-link" [routerLink]="['/ncp/achd/landing']">Home</a>
              </li>
              <li [ngbNavItem]="2" ngbDropdown>
                <a href (click)="false" class="nav-link" ngbDropdownToggle>ACH Debit
                  <span class="fa fa-chevron-circle-down"></span>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-child" [routerLink]="['/ncp/achd/landing']" ngbDropdownItem>ACH Debit Home</a>
                  <a class="dropdown-child" [routerLink]="['/ncp/achd/payment']" ngbDropdownItem>Create New
                    Payment</a>
                  <a class="dropdown-child" [routerLink]="['/ncp/achd/paymentaccount']" ngbDropdownItem>Manage Bank
                    Accounts</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-child" [routerLink]="['/ncp/achd/information']" ngbDropdownItem>About ACH
                    Debit</a>
                </div>
              </li>
              <li [ngbNavItem]="3" ngbDropdown>
                <a href (click)=false class="nav-link" ngbDropdownToggle>Credit Card
                  <span class="fa fa-chevron-circle-down"></span>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-child" [routerLink]="['/ncp/creditcard']" ngbDropdownItem>Make a Credit Card
                    Payment
                  </a>
                </div>
              </li>
              <li [ngbNavItem]="5" ngbDropdown>
                <a href (click)="false" class="nav-link" ngbDropdownToggle>Helpful Links
                  <span class="fa fa-chevron-circle-down"></span>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-child" [routerLink]="['/employer/achd/information']" ngbDropdownItem>ACH Debit </a>
                  <a class="dropdown-child" [routerLink]="['/employer/eft/information']" ngbDropdownItem>ACH Credit
                    (EFT/EDI) </a>
                  <a class="dropdown-child" download="employereft" target="_blank" href="/assets/forms/employereft.pdf"
                    ngbDropdownItem>
                    Employer ACH Credit (EFT/ EDI) Packet
                    <i class="fa fa-download" aria-hidden="true"></i></a>
                  <a class="dropdown-child"
                    href="https://www.nacha.org/system/files/resources/Child-Support-User-%20Guide-%20Feb%202017%202.pdf"
                    target="_blank" ngbDropdownItem>NACHA&rsquo;s User Guide for Electronic Payments</a>
                  <a class="dropdown-child" [routerLink]="['/demo']" ngbDropdownItem>Website Help</a>
                </div>
              </li>
              <li class="nav-spacing" aria-hidden="true"></li>
              <li [ngbNavItem]="5" ngbDropdown>
                <a href (click)="false" class="nav-link global-nav-link" ngbDropdownToggle>Account
                  <span class="fa fa-user-circle-o"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-child" [routerLink]="['/ncp/profile/settings']" ngbDropdownItem>Profile and
                    Settings</a>
                  <a class="dropdown-child" (click)="logout()" ngbDropdownItem>Log Out</a>
                </div>
              </li>
            </ul>
            <div [ngbNavOutlet]="navNcp" class="authed-global-nav-block"></div>
          </div>

        </ng-template>
      </div>
      <ng-template #elseBlock>
        <ul ngbNav #nav="ngbNav" class="nav-pills global-nav-menu">
          <li [ngbNavItem]="1"><a class="nav-link global-nav-link" [routerLink]="['/home']">Home</a></li>
          <li [ngbNavItem]="2" ngbDropdown>
            <a href class="nav-link global-nav-link" (click)=false ngbDropdownToggle>Noncustodial Parent
              <span class="fa fa-chevron-circle-down"></span>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <!-- <a class="dropdown-child" [routerLink]="['/ncp']" ngbDropdownItem>Information</a>
              <div class="dropdown-divider"></div> -->
              <a class="dropdown-child" [routerLink]="['/login']" ngbDropdownItem>Login</a>
              <a class="dropdown-child" [routerLink]="['/ncp/register']" ngbDropdownItem>Create Account</a>
            </div>
          </li>
          <li [ngbNavItem]="3" ngbDropdown>
            <a href class="nav-link global-nav-link" (click)=false ngbDropdownToggle>Employers
              <span class="fa fa-chevron-circle-down"></span>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="dropdown-child" [state]="{ userType: 'employer' }" [routerLink]="['/login']"
                ngbDropdownItem>Login</a>
              <a class="dropdown-child" [routerLink]="['/employer/register']" ngbDropdownItem>Create Account</a>
            </div>
          </li>
          <li [ngbNavItem]="4" ngbDropdown>
            <a href class="nav-link global-nav-link dropdown-menu-toggle" (click)=false ngbDropdownToggle>Helpful Links
              <span class="fa fa-chevron-circle-down"></span>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="dropdown-child" [routerLink]="['/employer/achd/information']" ngbDropdownItem>ACH Debit </a>
              <a class="dropdown-child" [routerLink]="['/employer/eft/information']" ngbDropdownItem>ACH Credit
                (EFT/EDI) </a>
              <a class="dropdown-child" download="employereft" target="_blank" href="/assets/forms/employereft.pdf"
                ngbDropdownItem>
                Employer EFT/EDI Packet
                <i class="fa fa-download" aria-hidden="true"></i></a>
              <a class="dropdown-child"
                href="https://www.nacha.org/system/files/resources/Child-Support-User-%20Guide-%20Feb%202017%202.pdf"
                target="_blank" ngbDropdownItem>NACHA&rsquo;s User Guide for Electronic Payments</a>
              <a class="dropdown-child" [routerLink]="['/demo']" ngbDropdownItem>Website Help</a>
            </div>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="unauthed-global-nav-block"></div>
      </ng-template>
    </div>
  </div>
</nav>
