import { Component, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'app-ncp-faq',
  templateUrl: './ncp-faq.component.html'
})
export class NcpFAQComponent implements OnInit {
  chaturl: string ='';

  constructor() {
    this.chaturl = CommonSettings.ChatUrl;
   }

  ngOnInit(): void {
  }

  openChat() {
    window.open(this.chaturl, '', 'width=510, height=900');
  }

}
