import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../services/employer.service';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-home',
  templateUrl: './home.component.html'
})
export class EMPHomeComponent implements OnInit {
  pagetitle = 'Review Payment Methods';
  achdInfo = '';
  eftInfo = '';
  baseurl = '';

  constructor(private employerService: EmployerService) {
    this.baseurl = CommonSettings.BaseApi;
  }

  ngOnInit() {
    this.employerService.getACHDInfo().subscribe(
      result => {
        this.achdInfo = result;
      }
    );
    this.employerService.getEFTInfo().subscribe(
      result => {
        this.eftInfo = result;
      }
    );
  }
}
