<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main max-w" id="page-content-main">
    <div class="">
      <epay-library-employer-eft-createfile [baseurl]="baseurl" [termsContent]="termsContent" [clientName]="clientName"
        [secondaryIdAlias]="secondaryIdAlias"
      [routingHelpImage]="routingHelpImage">
      </epay-library-employer-eft-createfile>
    </div>
  </div>
</epay-page-content>
