<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main box-panel shadow max-w max-gm" id="page-content-main">
    <div class="m--pa4">
      <div class="mb-5">
        <h2 class="mb-5">Security Statement</h2>
        <p>Every time you use this web site, the data transmitted to or from your PC is sent encrypted
          over a 128 bit secure socket layer (SSL). SSL encrypts all information exchanged between this
          web site and your PC, such as credit card numbers and other personal data, using a unique
          session key. Each session key is used only once, during a single session (which may include
          one or more transactions) with a single user. These layers of privacy protection ensure that
          information cannot be viewed if it is intercepted by unauthorized parties. When a message is
          sent, the sending and receiving computers each generate a code based on the message content.
          If even a single character in the message content is altered en route, the receiving computer
          will generate a different code, and then alert the recipient that the message is not legitimate.
          With message integrity, both parties involved in the transaction know that what they’re seeing is
          exactly what the other party sent.</p>
        <p>A trusted third party certificate authority issues this site’s certificate so you can be
          assured this web site is authentic and belongs to the Oklahoma E-Commerce Solution.</p>
      </div>
    </div>
  </div>
</epay-page-content>
