import { Component, Input, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-forgotusername',
  templateUrl: './forgotusername.component.html',
})
export class ForgotUsernameComponent implements OnInit {
  isMobile = false;
  baseurl = '';
  pagetitle = 'Forgot Username';

  constructor() {
    this.baseurl = CommonSettings.BaseApi;
  }

  ngOnInit() {}
}
