import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../../services/employer.service';

@Component({
  selector: 'epay-eft-information',
  templateUrl: './eft-information.component.html'
})
export class EMPEFTInformationComponent implements OnInit {
  pagetitle = 'ACH Credit (EFT/EDI)';
  eftInfo= '';

  constructor(private employerService: EmployerService) { }

  ngOnInit() {
    this.employerService.getEFTInfo().subscribe(
      result => {
        this.eftInfo = result;
      }
    );
  }

}
