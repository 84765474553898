import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import {
  FormControl,
  ValidationErrors,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import {
  StepperOrientation,
  STEPPER_GLOBAL_OPTIONS,
} from '@angular/cdk/stepper';
import { debounceTime } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import PhoneNumber from 'awesome-phonenumber';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { IEmpSearchResults, ErrorHandlerService, CommonService, 
  RegisterLibraryService, PhoneHelperService, ViewportService, HelperDialogModel, HelperDialogComponent, IChallengeQuestion, ICountry, IState, CommonSettings } from 'epay-library';
import { IEmployer } from 'epay-library/lib/features/employer/models/employer/employer';
import { IEmpBankAccount } from 'epay-library/lib/features/employer/models/employer/employerBackAccount';
import { IEmpLogin } from 'epay-library/lib/features/employer/models/user/employerLogin';
import { IEmpPerson } from 'epay-library/lib/features/employer/models/user/employerPerson';
import { IEmpUser } from 'epay-library/lib/features/employer/models/user/employerUser';
import { EmployerService } from '../../services/employer.service';
import { Location } from '@angular/common';
import { ErrorDialogComponent, ErrorHandlerDialogModel } from 'src/app/helpers/components/error-dialog/error-dialog.component';

@Component({
  selector: 'epay-employer-register',
  templateUrl: './employer.register.component.html',
})
export class EMPRegisterComponent implements OnInit {
  @Input() baseurl = '';
  @Input() termsContent = '';
  @Input() loginHelp = '';
  @Input() canCreateNewEmployer = false;
  @Input() useEmailForLogin = false;
  @Input() allowEINSearch = false;

  @ViewChild('stepper') myStepper!: MatStepper;

  pagetitle = "Employer: Create Account";

  useEmailAsUsername = false;
  validatingEmployer = false;
  empOnHold = false;
  empSelected = false;

  stepperOrientation: StepperOrientation = 'horizontal';

  errorMatcher = new CrossFieldErrorMatcher();
  termsFrmGp: FormGroup = new FormGroup({});
  searchFrmGrp: FormGroup = new FormGroup({});
  selectedEmployerFrmGp: FormGroup = new FormGroup({});
  createEmployerFrmGp: FormGroup = new FormGroup({});
  demographicFrmGp: FormGroup = new FormGroup({});
  loginFrmGp: FormGroup = new FormGroup({});
  reviewFrmGrp: FormGroup = new FormGroup({});
  employer: IEmployer = {} as IEmployer;
  empuser: IEmpUser = {} as IEmpUser;
  emplogin: IEmpLogin = {} as IEmpLogin;
  empperson: IEmpPerson = {} as IEmpPerson;
  emppaymentaccount: IEmpBankAccount = {} as IEmpBankAccount;
  countries: ICountry[];
  selectedCountry = '';
  states: IState[];
  selectedState = '';
  empsearchresults: IEmpSearchResults[];
  selectedEmployer: IEmpSearchResults = {} as IEmpSearchResults;
  questions: IChallengeQuestion[];
  errorMessage = '';
  searchErrorMessage = '';
  displayedColumns = ['Select', 'Name', 'Address', 'City', 'State', 'Zip'];
  phonePattern = /^((\\+91-?)|0)?[0-9]{10}$/;
  loginPattern =
    /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;
  // loginPattern = /^[a-zA-Z0-9.@]*$/;

  passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  zipPattern = /^[0-9]{5}$/;
  emailPattern = /\S+@\S+\.\S+/;
  // einPattern =
  //   /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;
  einPattern = /^[0-9]{9}$/

  empsearchname = '';
  empsearchein = '';
  selectedEmployerId = '';
  selectedEmployerDataSource = '';
  emailErrorMessage = '';
  emailSuccessMessage = '';
  einErrorMessage = '';
  einSuccessMessage = '';
  loginErrorMessage = '';
  loginSuccessMessage = '';
  selectedUsername = '';
  passwordErrorMessage = '';
  password2ErrorMessage = '';
  selectedPassword = '';
  loginHintMessage =
    'Must contain at least 8 characters. Alphanumerical values and email format are accepted.';

  passwordHintMessage = 'Must contain at least 6 letters, 2 numbers, and at least one special character with a maximum of 12 characters.';
  selectedChallengeQuestion = '';

  isTermsContentRead = false;
  isValidEmail = false;
  isCheckingEmail = false;
  isValidLogin = false;
  isCheckingLogin = false;
  isValidEin = false;
  isCheckingEin = false;
  passwordHide = true;
  confirmpasswordHide = true;
  isSearching = false;
  hasSearched = false;
  isTermsSubmitted = false;
  isEmpSearchSubmitted = false;
  showCreateEmp = false;
  employerCreatedSuccessfully = false;
  displayAddEmployerButton = true;
  employerCreatedMessage = '';

  confirmationTitle = '';
  confirmationMessage = '';
  confirmationEmailAddress = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';
  openConfirmation = false;

  showDialogHelp = false;

  phoneExample = '';
  phoneMask = '';
  cellExample = '';
  cellMask = '';

  searchMessage = '';

  dialogData: any;
  dialogRef: any;
  deleteResult = '';

  validationEmailMessages: { [key: string]: string } = {
    required: 'Required.',
    pattern: 'Please enter a valid email address.',
    isDuplicate: 'Registration already exist for this email.',
  };

  validationEinMessages: { [key: string]: string } = {
    required: 'Required.',
    pattern: 'Please enter a valid EIN.',
    isDuplicate: 'Registration already exist for this EIN.',
  };

  validationLoginIdMessages: { [key: string]: string } = {
    required: 'Username is required.',
    pattern:
    'Invalid Username.  Must contain at least 6 letters and 2 numbers with no special characters or must match the provided email (in previous step) if using email.',
    minlength: 'Invalid Username.  Must contain at least 8 characters.',
    isDuplicate: 'Username is not available for use.',
    isSameAsPassword: 'Invalid Username.  Username cannot be same as password.',
    emailMismatch: 'Username must match the provided email (in previous step) if using email format.'
  };

  validationPasswordMessages: { [key: string]: string } = {
    required: 'Password is required.',
    pattern:
      'Invalid Password.  Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.',
    isSameAsLogin: 'Invalid Password.  Password cannot be same as username.',
  };

  validationMessages: { [key: string]: string } = {
    required: 'Required',
    pattern: 'Invalid format.',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private commonService: CommonService,
    private registerService: RegisterLibraryService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private phoneHelperService: PhoneHelperService,
    private viewpointService: ViewportService,
    private employerService: EmployerService,
    private location: Location
  ) {
    this.baseurl = CommonSettings.BaseApi;
    this.canCreateNewEmployer = CommonSettings.CanCreateNewEmployer;
    this.useEmailForLogin = CommonSettings.UseEmailForLogin;
    this.allowEINSearch = CommonSettings.AllowEINSearch;

    this.states = [];
    this.countries = [];
    this.questions = [];
    this.empsearchresults = [];
    this.selectedCountry = 'US';
  }

  ngOnInit() {
    this.employerService.getEmployerTerms().subscribe((result: any) => {
      this.termsContent = result;
    });
    this.employerService.getLoginHelp().subscribe((result: any) => {
      this.loginHelp = result;
    });

    this.loadFormGroups();
    this.getCountries();
    this.getStates();
    this.getChallengeQuestions();
    this.empsearchname = '';
    this.empsearchein = '';
    this.selectedEmployerId = '';
    this.selectedEmployerDataSource = '';

    this.searchMessage = this.allowEINSearch
      ? 'Please enter your employer name or EIN number to search for your company.'
      : 'Please enter your employer name to search for your company.';

    this.viewpointService.StepperOrient.subscribe(
      (orient) => (this.stepperOrientation = orient)
    );

    const emailCtrl = this.demographicFrmGp.get('email')!;
    const phoneCtrl = this.demographicFrmGp.get('phoneGp.phoneNumber')!;
    const cellCtrl = this.demographicFrmGp.get('cellGp.cellNumber')!;
    const countryCtrl = this.demographicFrmGp.get('country')!;
    const loginCtrl = this.loginFrmGp.get('loginId')!;
    const passwrd1Ctrl = this.loginFrmGp.get('passwordGp.password1')!;
    const einCtrl = this.createEmployerFrmGp.get('ein')!;

    einCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((ein: any) => this.setEinMessage(einCtrl, ein));

    emailCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((email: any) => this.setEmailMessage(emailCtrl, email));

    loginCtrl.valueChanges
    .pipe(debounceTime(500))
    .subscribe((username: any) => {
      this.setLoginMessage(loginCtrl, username);
    });

    passwrd1Ctrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((passwrd: any) =>
        this.setPasswordMessage(passwrd1Ctrl, passwrd)
      );

    phoneCtrl.valueChanges.pipe(debounceTime(500)).subscribe((value: any) => {
      if (value) {
        const pn = new PhoneNumber(
          value,
          this.demographicFrmGp.get('phoneGp.regionCode')!.value
        );
        this.demographicFrmGp.get('phoneGp')!.patchValue({
          isValid: pn.isPossible(),
        });
      }
    });

    cellCtrl.valueChanges.pipe(debounceTime(500)).subscribe((value: any) => {
      if (value) {
        const pn = new PhoneNumber(
          value,
          this.demographicFrmGp.get('cellGp.regionCode')!.value
        );
        this.demographicFrmGp.get('cellGp')!.patchValue({
          isValid: pn.isPossible(),
        });
      }
    });

    countryCtrl.valueChanges.subscribe((value: any) => {
      if (value) {
        this.setMaskAndExample(value);
        this.demographicFrmGp.get('phoneGp')!.patchValue({
          regionCode: value,
          countryCode:
            this.phoneHelperService.getCountryCodeForRegionCode(value),
          phoneNumber: '',
          isValid: false,
        });
        this.demographicFrmGp.get('cellGp')!.patchValue({
          regionCode: value,
          countryCode:
            this.phoneHelperService.getCountryCodeForRegionCode(value),
          cellNumber: '',
          isValid: false,
        });
        this.demographicFrmGp.patchValue({
          state: '',
          zip5: '',
        });
        if (value === 'US') {
          this.demographicFrmGp
            .get('zip5')!
            .setValidators([
              Validators.required,
              Validators.pattern(this.zipPattern),
            ]);
          this.demographicFrmGp.get('zip5')!.updateValueAndValidity();
          this.createEmployerFrmGp
            .get('zip5')!
            .setValidators([
              Validators.required,
              Validators.pattern(this.zipPattern),
            ]);
          this.createEmployerFrmGp.get('zip5')!.updateValueAndValidity();
        } else {
          this.demographicFrmGp
            .get('zip5')!
            .setValidators([Validators.required, Validators.maxLength(6)]);
          this.demographicFrmGp.get('zip5')!.updateValueAndValidity();
          this.createEmployerFrmGp
            .get('zip5')!
            .setValidators([Validators.required, Validators.maxLength(6)]);
          this.createEmployerFrmGp.get('zip5')!.updateValueAndValidity();
        }
        if (value === 'US' || value === 'MX' || value === 'CA') {
          this.selectedCountry = value;
          this.demographicFrmGp.get('state')!.enable();
          this.demographicFrmGp.get('state')!.setErrors({ required: true });
          this.demographicFrmGp.updateValueAndValidity();
          this.getStates();
        } else {
          this.demographicFrmGp.get('state')!.disable();
          this.demographicFrmGp.get('state')!.setErrors({ required: false });
          this.demographicFrmGp.updateValueAndValidity();
        }
      }
    });
  }

  stringify(json: any): string {
    return JSON.stringify(json, null, 2);
  }

  updateUsernameValidation(loginId: any) {
    var username = loginId.target.value

    this.loginFrmGp.get('loginId')!.clearValidators();
    this.loginFrmGp.get('loginId')!.updateValueAndValidity();

    if (username.indexOf('@') !== -1 || username.indexOf('.') !== -1) {
      //console.log("USERNAME IS EMAIL FORMAT");
      this.useEmailAsUsername = true;

      this.loginFrmGp.get('loginId')!.clearValidators();
      this.loginFrmGp.get('loginId')!.setValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.emailPattern),
      ]);
      this.loginFrmGp.get('loginId')!.updateValueAndValidity();
    }
    else {
      //console.log("USERNAME IS LOGIN FORMAT");
      this.useEmailAsUsername = false;

      this.loginFrmGp.get('loginId')!.clearValidators();
      this.loginFrmGp.get('loginId')!.setValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(12),
          Validators.pattern(this.loginPattern),
      ]);
      this.loginFrmGp.get('loginId')!.updateValueAndValidity();
    }
  }

  Search() {
    this.searchErrorMessage = '';
    this.selectedEmployerId = '';
    this.selectedEmployerDataSource = '';
    this.selectedEmployer = {} as IEmpSearchResults;
    this.selectedEmployerFrmGp.patchValue({ empselected: '' });
    this.selectedEmployerFrmGp
      .get('empselected')!
      .setValidators(Validators.requiredTrue);
    this.selectedEmployerFrmGp.get('empselected')!.updateValueAndValidity();
    this.isSearching = true;

    this.employerService
      .getEmployers(
        this.baseurl,
        this.searchFrmGrp.get('empname')!.value,
        this.searchFrmGrp.get('empein')!.value,
        true,
        false
      )
      .subscribe({
        next: (employers) => {
          this.empsearchresults = employers;
          this.hasSearched = true;
          if (
            this.empsearchresults.length === 0 &&
            !this.canCreateNewEmployer
          ) {
            this.searchErrorMessage = `No results found. Please review and minimize search criteria to broaden search results.
                                      If you cannot find your business, please contact the state.`;
          } else if (
            this.empsearchresults.length === 0 &&
            this.canCreateNewEmployer
          ) {
            this.searchErrorMessage = `No results found. Please review and minimize search criteria to broaden search results.
                                      If you cannot find your business, see the link below to Register a New Company.`;
          }
        },
        error: (error: any) => {
          this.hasSearched = false;
          this.logError(error);
          this.handleError(error.status);
        },
        complete: () => {
          this.isSearching = false;
          this.hasSearched = true;
        },
      });
  }

  ClearSearch() {
    this.searchFrmGrp.get('empein')!.reset()

    this.hasSearched = false;
    this.selectedEmployerId = '';
    this.selectedEmployerDataSource = '';
    this.selectedEmployer = {} as IEmpSearchResults;
    this.empsearchresults = [];
    this.searchErrorMessage = '';
    this.searchFrmGrp.patchValue({
      empein: '',
      empname: '',
    });
    this.selectedEmployerFrmGp.patchValue({
      empselected: '',
    });
  }

  displayCreateEmployer(stepper: MatStepper) {
    this.selectedEmployerFrmGp.patchValue({ empselected: '' });
    this.selectedEmployerFrmGp.get('empselected')!.clearValidators();
    this.selectedEmployerFrmGp.get('empselected')!.updateValueAndValidity();
    this.showCreateEmp = true;
    stepper.next();
  }

  createNewEmployer() {
    if (this.createEmployerFrmGp.valid) {
      if (this.createEmployerFrmGp.dirty) {
        this.employer.employerName =
          this.createEmployerFrmGp.get('empName')!.value;
        this.employer.ein = this.createEmployerFrmGp.get('ein')!.value;
        this.employer.addressLine1 =
          this.createEmployerFrmGp.get('address1')!.value;
        this.employer.addressLine2 =
          this.createEmployerFrmGp.get('address2')!.value;
        this.employer.city = this.createEmployerFrmGp.get('city')!.value;
        this.employer.state = this.createEmployerFrmGp.get('state')!.value;
        this.employer.zip5 = this.createEmployerFrmGp.get('zip5')!.value;
        this.employer.country = this.createEmployerFrmGp.get('country')!.value;
      }
    }
    this.registerService.addNewEmployer(this.baseurl, this.employer).subscribe(
      (response: any) => {
        //console.log("RES ", response)

        this.selectedEmployer.addressCity = this.employer.city;
        this.selectedEmployer.addressLine1 = this.employer.addressLine1;
        this.selectedEmployer.addressLine2 = this.employer.addressLine2;
        this.selectedEmployer.addressState = this.employer.state;
        this.selectedEmployer.addressZip5 = this.employer.zip5;
        this.selectedEmployer.hasAccount = false;
        this.selectedEmployer.hasHold = false;
        this.selectedEmployer.isSelected = true;

        this.selectedEmployer.thirdPartyID = response.thirdPartyId;
        this.selectedEmployer.thirdPartyName = this.employer.employerName;

        this.selectedEmployerId = response.thirdPartyId;
        this.selectedEmployerDataSource = response.thirdPartyDataSource;

        this.employer.thirdPartyId = response.thirdPartyId;
        this.employer.thirdPartyDataSource = response.thirdPartyDataSource;
        this.employer.ein = response.ein;

        this.employerCreatedSuccessfully = true;
        this.employerCreatedMessage =
          'New employer created! Click Next to continue.';
        this.displayAddEmployerButton = false;
      },
      (error: any) => {
        this.displayAddEmployerButton = true;
        this.employerCreatedSuccessfully = false;
        this.employerCreatedMessage = '';
        this.logError(error);
        this.handleError(error.status);
      }
    );
  }

  createAccount() {
    let demoData;
    let loginData;

    if (this.demographicFrmGp.valid) {
      if (this.demographicFrmGp.dirty) {
        this.empperson.thirdPartyId = this.selectedEmployerId;
        this.empperson.thirdPartyDataSource = this.selectedEmployerDataSource;
        this.empperson.isElectronicStatement = false;
        demoData = { ...this.empperson, ...this.demographicFrmGp.value };
        if (demoData.state === undefined || demoData.state === null) {
          demoData.state = '';
        }
        demoData.phone = this.demographicFrmGp.get(
          'phoneGp.phoneNumber'
        )!.value;
        if (this.demographicFrmGp.get('cellGp.cellNumber')!.value.length > 0) {
          demoData.cell = this.demographicFrmGp.get('cellGp.cellNumber')!.value;
        } else {
          demoData.cell = '';
        }
        this.empuser.empStep1Validated = true;
      }
    }

    if (this.loginFrmGp.valid) {
      if (this.loginFrmGp.dirty) {
        loginData = { ...this.emplogin, ...this.loginFrmGp.value };
        loginData.loginId = this.useEmailForLogin
          ? this.demographicFrmGp.get('email')!.value
          : this.loginFrmGp.get('loginId')!.value;
        loginData.password1 = this.loginFrmGp.get(
          'passwordGp.password1'
        )!.value;
        loginData.password2 = this.loginFrmGp.get(
          'passwordGp.password2'
        )!.value;
        this.empuser.empStep2Validated = true;
      }
    }
    this.emppaymentaccount.account1 = '';
    this.emppaymentaccount.account2 = '';
    this.emppaymentaccount.routing1 = '';
    this.emppaymentaccount.routing2 = '';
    this.emppaymentaccount.bankName = '';
    this.emppaymentaccount.accountType = '';
    this.emppaymentaccount.bankNickName = '';
    this.emppaymentaccount.phone = '';
    this.emppaymentaccount.doNotProvide = true;

    this.empuser.empStep1Validated = true;
    this.empuser.empStep2Validated = true;
    this.empuser.empStep3Validated = true;

    this.empuser.empStep1 = demoData;
    this.empuser.empStep2 = loginData;
    this.empuser.empStep3 = this.emppaymentaccount;

    this.registerService.addEmpAccount(this.baseurl, this.empuser).subscribe(
      (response: any) => {
        //console.log("RES: ", response)

        this.confirmationTitle = 'Success';
        this.confirmationMessage =
          'Account for ' +
          this.empuser.empStep1.firstName +
          ' ' +
          this.empuser.empStep1.lastName +
          ' has been created.';
        this.confirmationEmailAddress = this.empuser.empStep1.email;
        this.confirmationNumber = response.confirmation.confirmationNumber;
        this.goToPrimaryUrl = 'login';
        this.goToPrimary = 'Login';
        this.openConfirmation = true;
        this.onSaveComplete();
      },
      (error: any) => {
        //console.log("ERR: ", error)

        if (!error.error.empStep1Validated || !error.error.empStep2Validated || !error.error.empStep3Validated) {
          var errTitle = '';
          var errMsg = '';
          if (!error.error.empStep1Validated) {
            errTitle += error.error.errors.step1[0].errorCode + ' (Employer Search) ';
            errMsg += error.error.errors.step1[0].errorMessage + ' ';
          }
          if (!error.error.empStep2Validated) {
            errTitle += error.error.errors.step2[0].errorCode + ' (Contact Information) ';
            errMsg += error.error.errors.step2[0].errorMessage + ' ';
          }
          if (!error.error.empStep3Validated) {
            errTitle += error.error.errors.step3[0].errorCode + ' (Login Information) ';
            errMsg += error.error.errors.step3[0].errorMessage + ' ';
          }

          this.dialogData = new ErrorHandlerDialogModel(
            "Error Occurred: Internal Error Occurred",
            "Error Code: " + errTitle,
            errMsg,
            '',
            '',
            '',
            true
          );
  
          this.dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '800px',
            data: this.dialogData,
          });

          this.dialogRef.afterClosed().subscribe((dialogResult: string) => {
            this.deleteResult = dialogResult;
            if (this.deleteResult) {
                //this.location.back();
            }
          });
        }
        else {
          this.logError(error);
          this.handleError(error.status);
        }

      }
    );
  }

  onSaveComplete() {
    this.demographicFrmGp.reset();
    this.loginFrmGp.reset();
    this.termsFrmGp.reset();
    this.searchFrmGrp.reset();
    this.reviewFrmGrp.reset();
  }

  addRemoveEmployer(event: any, i: number) {
    this.validatingEmployer = false;
    //this.isEmpSearchSubmitted = false;
    this.empSelected = false;
    this.empOnHold = false;

    this.selectedEmployerId = '';
    this.selectedEmployerDataSource = '';
    if (this.canCreateNewEmployer) {
      this.showCreateEmp = false;
    }
    this.empsearchresults.forEach((emp) => {
      emp.isSelected = false;
    });

    if (event.target.checked) {
      this.validatingEmployer = true;
      this.empSelected = true;

      this.selectedEmployerFrmGp
        .get('empselected')!
        .setValidators(Validators.requiredTrue);
      this.selectedEmployerFrmGp.get('empselected')!.updateValueAndValidity();
      this.empsearchresults[i].isSelected = true;
      this.selectedEmployer = {
        thirdPartyName: this.empsearchresults[i].thirdPartyName,
        thirdPartyID: this.empsearchresults[i].thirdPartyID,
        dataSource: this.empsearchresults[i].dataSource,
        addressLine1: this.empsearchresults[i].addressLine1,
        addressLine2: this.empsearchresults[i].addressLine2,
        addressCity: this.empsearchresults[i].addressCity,
        addressState: this.empsearchresults[i].addressState,
        addressZip5: this.empsearchresults[i].addressZip5,
        isSelected: true,
        hasAccount: this.empsearchresults[i].hasAccount,
        hasHold: this.empsearchresults[i].hasHold,
        primaryEmailAddress: this.empsearchresults[i].primaryEmailAddress,
      };
      this.selectedEmployerId = this.empsearchresults[i].thirdPartyID;
      this.selectedEmployerDataSource = this.empsearchresults[i].dataSource;
      if (this.selectedEmployer.hasAccount) {
        this.selectedEmployerFrmGp
          .get('empselected')!
          .setErrors({ hasAccount: true });
      }
      if (this.selectedEmployer.hasHold) {
        this.selectedEmployerFrmGp
          .get('empselected')!
          .setErrors({ hasHold: true });
      }
    } else {
      this.empsearchresults[i].isSelected = false;
      this.selectedEmployer.isSelected = false;
      this.selectedEmployerFrmGp
        .get('empselected')!
        .setErrors({ hasAccount: false });
      this.selectedEmployerFrmGp
        .get('empselected')!
        .setErrors({ hasHold: false });
    }

    this.employerService
    .getEmployers(
      this.baseurl,
      this.selectedEmployer.thirdPartyName,
      this.searchFrmGrp.get('empein')!.value,
      true,
      true
    ).subscribe({
      next: (res: any) => { 
        console.log("RES: ", res)
        this.validatingEmployer = false;
        this.isEmpSearchSubmitted = true;

        console.log("1 ", this.selectedEmployer?.hasAccount)
        console.log("2 ", this.selectedEmployer?.isSelected)
        console.log("3 ", this.isEmpSearchSubmitted)
        console.log("4 ", this.empOnHold)

        if (res.length !== 0) {
          if (!res[0].hasHold) {
            console.log("NOT ON HOLD")
            this.empOnHold = false;
          }
          else{
            console.log("HAS HOLD")
            this.empOnHold = true;
          }
        }
      },
      error: (error: any) => {
        this.hasSearched = false;
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  challengeQuestionChangeEvent() {
    const q: IChallengeQuestion[] = this.questions.filter(
      (question) =>
        question.index ===
        this.loginFrmGp.get('authenticationQuestionIndex')!.value
    );
    this.selectedChallengeQuestion = q[0].name;
  }

  getChallengeQuestions() {
    this.registerService.getChallengeQuestions(this.baseurl).subscribe({
      next: (questions) => (this.questions = questions),
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  getCountries() {
    this.commonService.getCountries(this.baseurl).subscribe({
      next: (countries: any) => {
        this.countries = countries;
        this.countries = this.countries.sort((a, b) =>
          a.countryName < b.countryName ? 1 : -1
        );
        this.countries.push({
          country: 'MX',
          countryName: 'Mexico',
          alpha3IsoCode: 'MEX',
          numeric3IsoCode: '484',
        });
        this.countries.push({
          country: 'CA',
          countryName: 'Canada',
          alpha3IsoCode: 'CAN',
          numeric3IsoCode: '124',
        });
        this.countries.push({
          country: 'US',
          countryName: 'United States',
          alpha3IsoCode: 'USA',
          numeric3IsoCode: '840',
        });
        this.countries.reverse();
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  getStates() {
    this.selectedCountry = this.demographicFrmGp.get('country')!.value;
    this.commonService.getStates(this.baseurl, this.selectedCountry).subscribe({
      next: (states) => (this.states = states),
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  loadFormGroups() {
    this.termsFrmGp = this.formbuilder.group({
      accepted: ['', Validators.requiredTrue],
    });

    this.searchFrmGrp = this.formbuilder.group({
      empname: '',
      empein: '',
    });

    this.selectedEmployerFrmGp = this.formbuilder.group({
      empselected: ['', Validators.requiredTrue],
    });

    this.searchFrmGrp.setValidators([
      this.oneOfControlRequired(
        this.searchFrmGrp.get('empname')!,
        this.searchFrmGrp.get('empein')!
      ),
    ]);

    this.createEmployerFrmGp = this.formbuilder.group({
      empName: ['', Validators.required],
      ein: ['', [Validators.required, Validators.pattern(this.einPattern)]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip5: ['', [Validators.required, Validators.pattern(this.zipPattern)]],
      country: ['', Validators.required],
    });

    this.demographicFrmGp = this.formbuilder.group({
      firstName: ['', Validators.required],
      middleName: '',
      lastName: ['', Validators.required],
      title: '',
      suffix: '',
      address1: ['', Validators.required],
      address2: '',
      address3: '',
      address4: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip5: ['', [Validators.required, Validators.pattern(this.zipPattern)]],
      zip4: '',
      country: ['', Validators.required],
      phoneExtension: '',
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      county: '',
      fax: '',
      ein: '',
      sein: '',
      relationId: '',
      phoneGp: this.formbuilder.group({
        regionCode: 'US',
        phoneNumber: ['', [Validators.required]],
        countryCode: '1',
        isValid: [false, Validators.requiredTrue],
      }),
      cellGp: this.formbuilder.group({
        regionCode: 'US',
        cellNumber: [''],
        countryCode: '1',
        isValid: false,
      }),
    });

    this.setMaskAndExample('US');
    // set default country to US
    this.demographicFrmGp.patchValue({
      country: 'US',
    });

    this.loginFrmGp = this.formbuilder.group({
      loginId: [
        '',
        this.useEmailForLogin
          ? ''
          : [
              Validators.required,
              Validators.minLength(8),
              //Validators.maxLength(12),
              Validators.pattern(this.loginPattern),
            ],
      ],
      userName: '',
      passwordGp: this.formbuilder.group(
        {
          password1: [
            '',
            [Validators.required, Validators.pattern(this.passwordPattern)],
          ],
          password2: [
            '',
            [Validators.required],
          ],
        },
        { validators: passwordMatcher }
      ),
      challengeAnswer: ['', Validators.required],
      authenticationQuestionIndex: ['', Validators.required],
      authenticationCaption: '',
      authenticationImageIndex: 0,
    });

    this.reviewFrmGrp = this.formbuilder.group({});
  }

  checkReading() {}

  submitTerms() {
    this.isTermsSubmitted = true;
  }

  submitEmployer() {
    console.log("SUBMIT EMP HIT");

    this.validatingEmployer = true;

    this.employerService
    .getEmployers(
      this.baseurl,
      this.selectedEmployer.thirdPartyName,
      this.searchFrmGrp.get('empein')!.value,
      true,
      true
    ).subscribe((res: any) => {
      console.log("2nd search res: ", res)
      this.validatingEmployer = false;

      if (res.length !== 0) {
        if (!res[0].hasHold) {
          console.log("DOESNT HAVE HOLD")
          this.isEmpSearchSubmitted = true;
          this.empOnHold = false;
        }
        else{
          console.log("HAS HOLD")
          this.isEmpSearchSubmitted = false;
          this.empOnHold = true;
        }
        
      }
    });
    
  }

  oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (!Validators.required(aControl)) {
          return null;
        }
      }
      return { oneOfRequired: true };
    };
  }

  setEmailMessage(emailControl: AbstractControl, email: any): void {
    this.resetEmailCheck();
    if (emailControl.touched || emailControl.dirty) {
      if (!emailControl.errors) {
        this.isCheckingEmail = true;
        this.registerService
          .searchDuplicateEmail(this.baseurl, email)
          .subscribe({
            next: (result: any) => {
              this.isValidEmail = !result.isDuplicate;
              if (this.isValidEmail) {
                this.emailSuccessMessage = 'Email is available!';
              } else {
                emailControl.setErrors({ isDuplicate: true });
                this.emailErrorMessage = Object.keys(emailControl.errors!)
                  .map((key: any) => this.validationEmailMessages[key])
                  .join(' ');
                this.demographicFrmGp.updateValueAndValidity();
              }
              this.isCheckingEmail = false;
            },
            error: (error: any) => {
              this.resetEmailCheck();
              this.errorMessage = error as any;
              this.logError(error);
              this.handleError(error.status);
            },
          });
      } else {
        this.emailErrorMessage = Object.keys(emailControl.errors)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      }
    }
  }

  setLoginMessage(loginControl: AbstractControl, loginId: any): void {
    this.resetLoginCheck();
    if (loginControl.touched || loginControl.dirty) {
      if (this.useEmailAsUsername && loginId !== this.demographicFrmGp.get('email')!.value) {
        loginControl.setErrors({ emailMismatch: true });
        this.loginErrorMessage = Object.keys(loginControl.errors!)
          .map((key) => this.validationLoginIdMessages[key])
          .join(' ');
      }

      if (!loginControl.errors) {
        if (loginId === this.selectedPassword) {
          loginControl.setErrors({ isSameAsPassword: true });
          this.loginErrorMessage = Object.keys(loginControl.errors!)
            .map((key) => this.validationLoginIdMessages[key])
            .join(' ');
        } 
        else {
            this.isCheckingLogin = true;
            this.registerService
              .searchDuplicateLogin(this.baseurl, loginId)
              .subscribe({
                next: (result: any) => {
                  this.isValidLogin = !result.isDuplicate;
                  if (this.isValidLogin) {
                    this.loginSuccessMessage = 'Username is available!';
                    this.selectedUsername = loginId;
                  } else {
                    loginControl.setErrors({ isDuplicate: true });
                    this.loginErrorMessage = Object.keys(loginControl.errors!)
                      .map((key) => this.validationLoginIdMessages[key])
                      .join(' ');
                    this.loginFrmGp.updateValueAndValidity();
                  }
                  this.isCheckingLogin = false;
                },
                error: (error: any) => {
                  this.resetLoginCheck();
                  this.errorMessage = error as any;
                  this.logError(error);
                  this.handleError(error.status);
                },
              });
            }
      } else {
        this.loginErrorMessage = Object.keys(loginControl.errors)
          .map((key) => this.validationLoginIdMessages[key])
          .join(' ');
      }
    }
  }

  setPasswordMessage(passwordControl: AbstractControl, passwrd: any): void {
    this.passwordErrorMessage = '';
    if (
      passwordControl.touched ||
      passwordControl.dirty ||
      this.loginFrmGp.invalid
    ) {
      if (!passwordControl.errors) {
        if (this.selectedUsername === passwrd) {
          passwordControl.setErrors({ isSameAsLogin: true });
          this.passwordErrorMessage = Object.keys(passwordControl.errors!)
            .map((key) => this.validationPasswordMessages[key])
            .join(' ');
        }
      } else {
        this.passwordErrorMessage = Object.keys(passwordControl.errors)
          .map((key) => this.validationPasswordMessages[key])
          .join(' ');
      }
    }
  }

  setEinMessage(einControl: AbstractControl, ein: any): void {
    this.resetEinCheck();
    if (einControl.touched || einControl.dirty) {
      if (!einControl.errors) {
        this.isCheckingEin = true;
        this.registerService.searchDuplicateEin(this.baseurl, ein).subscribe({
          next: (result: any) => {
            this.isValidEin = !result.isDuplicate;
            if (this.isValidEin) {
              this.einSuccessMessage = 'EIN is valid!';
            } else {
              einControl.setErrors({ isDuplicate: true });
              this.einErrorMessage = Object.keys(einControl.errors!)
                .map((key) => this.validationEinMessages[key])
                .join(' ');
              this.createEmployerFrmGp.updateValueAndValidity();
            }
            this.isCheckingEin = false;
          },
          error: (error: any) => {
            this.resetEinCheck();
            this.errorMessage = error as any;
            this.logError(error);
            this.handleError(error.status);
          },
        });
      } else {
        this.einErrorMessage = Object.keys(einControl.errors)
          .map((key) => this.validationEinMessages[key])
          .join(' ');
      }
    }
  }

  getNewEmpErrorMessage(name: any): string {
    let message = '';
    const fieldControl = this.createEmployerFrmGp.get(name);
    if (
      fieldControl!.touched ||
      fieldControl!.dirty ||
      this.demographicFrmGp.invalid
    ) {
      if (fieldControl!.hasError('required')) {
        message = 'Required';
      } else {
        message = 'Invalid Format';
      }
    }
    return message;
  }

  getDemographicErrorMessage(name: any): string {
    let message = '';
    const fieldControl = this.demographicFrmGp.get(name);
    if (
      fieldControl!.touched ||
      fieldControl!.dirty ||
      this.demographicFrmGp.invalid
    ) {
      if (fieldControl!.hasError('required')) {
        message = 'Required';
      } else {
        message = 'Invalid Format';
      }
    }
    return message;
  }

  togglePasswordHide() {
    this.passwordHide = !this.passwordHide;
  }

  toggleConfirmPasswordHide() {
    this.confirmpasswordHide = !this.confirmpasswordHide;
  }

  resetEmailCheck() {
    this.isValidEmail = false;
    this.isCheckingEmail = false;
    this.emailSuccessMessage = '';
    this.emailErrorMessage = '';
  }

  resetEinCheck() {
    this.isValidEmail = false;
    this.isCheckingEmail = false;
    this.emailSuccessMessage = '';
    this.emailErrorMessage = '';
  }

  resetLoginCheck() {
    this.isValidLogin = false;
    this.isCheckingLogin = false;
    this.loginSuccessMessage = '';
    this.loginErrorMessage = '';
    this.selectedUsername = '';
  }

  setMaskAndExample(regionCode: string) {
    this.phoneExample = this.phoneHelperService.getExample(regionCode);
    this.phoneMask = this.phoneHelperService.getMask(regionCode);
    this.cellExample = this.phoneHelperService.getExample(regionCode);
    this.cellMask = this.phoneHelperService.getMask(regionCode);
  }

  goToMainHome() {
    this.router.navigate(['home']);
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  displayLoginHelp() {
    const imagePath = '';
    const dialogData = new HelperDialogModel(
      'Login Information Help',
      this.loginHelp,
      imagePath
    );

    const dialogRef = this.dialog.open(HelperDialogComponent, {
      maxWidth: '1000px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe({
      next: (dialogResult) => (this.showDialogHelp = false),
    });
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password1Ctrl = c.get('password1');
  const password2Ctrl = c.get('password2');
  if (password1Ctrl === null || password2Ctrl === null) {
    return null;
  }
  if (password1Ctrl.pristine || password2Ctrl.pristine) {
    return null;
  }
  if (password1Ctrl.value === password2Ctrl.value) {
    return null;
  }
  return { doNotMatch: true };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (control) {
      return (control.dirty || control.touched) && control.parent!.invalid;
    }
    return false;
  }
}
function next(value: IEmpSearchResults[]): void {
  throw new Error('Function not implemented.');
}

