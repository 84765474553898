import { Component, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-forgotpassword',
  templateUrl: './forgotpassword.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  pagetitle = 'Forgot Password';
  baseurl = '';

  constructor() {
    this.baseurl = CommonSettings.BaseApi;
  }

  ngOnInit() {}
}
