import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../../../employer/services/employer.service';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-createfile',
  templateUrl: './createfile.component.html',
})
export class EMPEFTCreateFileComponent implements OnInit {
  pagetitle = 'Employer: Create ACH Credit File';
  baseurl = '';
  secondaryIdAlias = 'Family Group Number'
  clientName = '';
  termsContent = '';
  routingHelpImage = '';
  isMobile = false;

  constructor(private employerService: EmployerService) {
    this.baseurl = CommonSettings.BaseApi;
    this.clientName = CommonSettings.ClientName;
    this.routingHelpImage = '../../../../assets/help/aba.jpg';
  }

  ngOnInit() {
    this.employerService.getEmployerTerms().subscribe((result) => {
      this.termsContent = result;
    });
  }
}
