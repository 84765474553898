<div id="page" class="page page-tn">
  <div class="main">
    <epay-global-nav [baseurl]="baseurl"
      [isMobile]="isMobile">
    </epay-global-nav>
    <div class="spinner-overlay" [hidden]="!busy">
      <div class="spinner-center">
        <mat-spinner diameter="85">
        </mat-spinner>
        {{message}}
      </div>
    </div>
    <div [hidden]="busy" class="masterpanel">
      <router-outlet>
      </router-outlet>
    </div>
  </div>
  <epay-footer></epay-footer>
</div>
