<epay-page-content [pagetitle]="pagetitle">
  <div class="container">
    <div class="shadow max-w max-gs" id="page-content-main">
      <mat-card class="" style="border-radius: 10px">
        <mat-card-content>
          <h4 style="color: rgb(92, 92, 92); margin-left: 1vw">Helpful Links</h4>

          <div class="accordion-flush" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <h3>Noncustodial Parent Links</h3>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="mb-5 mt-3">
                    <h2 class="h3 mb-1 panel-default">Noncustodial Parent Registration</h2>
                    <p><a target="_blank" href="/assets/help/How To Register_NCP_Self.pdf">
                        How to Register a New Account (for self)
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                    <p><a target="_blank" href="/assets/help/How To Register_NCP_Third_Party.pdf">
                        How to Register a New Account (for someone else)
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                  <div class="mb-5">
                    <h2 class="h3 mb-1">Bank Account</h2>
                    <p><a target="_blank" href="/assets/help/How To Add Bank Account_NCP.pdf">
                        How to Add a Bank Account
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                  <div class="mb-5">
                    <h2 class="h3 mb-1">ACHD Payments</h2>
                    <p><a target="_blank" href="/assets/help/How To Create Payments_NCP.pdf">
                        How to Create a New Payment
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h3>Employer Links</h3>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="mb-5 mt-3">
                    <h2 class="h3 mb-1">Employer Registration</h2>
                    <p><a target="_blank" href="/assets/help/How To Register.pdf">
                        How to Register a New Account
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                  <div class="mb-5">
                    <h2 class="h3 mb-1">Bank Account</h2>
                    <p><a target="_blank" href="/assets/help/How To Add Bank Account.pdf">
                        How to Add a Bank Account
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                  <div class="mb-5">
                    <h2 class="h3 mb-1">ACHD Payments</h2>
                    <p><a target="_blank" href="/assets/help/How To Create Payments.pdf">
                        How to Create a New Payment
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                  <div class="">
                    <h2 class="h3 mb-1">EFT/EDI</h2>
                    <p><a target="_blank" href="/assets/help/How To Reconcile Employees Create File.pdf">
                        How to Reconcile Employees and/or Create File
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</epay-page-content>
