import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import {
  AuthService,
  EMPLOYER_PROFILE_SETTINGS_URL,
  NCP_PROFILE_SETTINGS_URL,
  CommonSettings
} from 'epay-library';
import { Observable, of } from 'rxjs';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'epay-global-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
})
export class GlobalNavComponent implements OnInit, OnChanges, OnDestroy {
  sessionTimeout: string;
  isGlobalNavOpen: boolean = false;
  routerNavEndSubscription: Subscription;
  employerProfileUrl = EMPLOYER_PROFILE_SETTINGS_URL;
  ncpProfileUrl = NCP_PROFILE_SETTINGS_URL;

  @Input() isMobile = false;
  @Input() baseurl = '';

  username$: Observable<string> = of('');
  usertype$: Observable<string> = of('');
  isLoggedIn$: Observable<boolean> = of(false);

  @ViewChild('globalNavBlock', { static: true }) globalNavBlock:
    | ElementRef
    | undefined;
  @ViewChild('mobileNavToggleBtn', { static: true })
  mobileNavToggleBtn: ElementRef | undefined;

  @HostListener('window:click', ['$event'])
  onClickOutside(event: PointerEvent) {
    const clickedEl = event.target;
    const hasClickedOutsideNav =
      !this.globalNavBlock!.nativeElement.contains(clickedEl) &&
      !this.mobileNavToggleBtn!.nativeElement.contains(clickedEl);
    if (hasClickedOutsideNav && this.isGlobalNavOpen) {
      this.isGlobalNavOpen = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  onEscKey(event: KeyboardEvent) {
    const whichKey = event.key;
    if (this.isGlobalNavOpen && whichKey === 'Escape') {
      this.isGlobalNavOpen = false;
    }
  }

  constructor(
    private authservice: AuthService,
    private router: Router,
    public snackbar: MatSnackBar
  ) {
    this.sessionTimeout = CommonSettings.TimeOutInMins;
    this.routerNavEndSubscription = this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe(() => {
        this.isGlobalNavOpen = false;
      });
  }

  ngOnInit() {
    this.isLoggedIn$ = this.authservice.isLoggedIn;
    this.username$ = this.authservice.currentUserName;
    this.usertype$ = this.authservice.currentUserType;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isMobile'] && !this.isMobile) {
      this.isGlobalNavOpen = false;
    }
  }

  ngOnDestroy() {
    this.routerNavEndSubscription.unsubscribe();
  }

  logout() {
    this.authservice.logout(this.baseurl);
    // this.isLoggedIn = false;
    // this.usertype = '';
    // this.username = '';
    this.openSnackBar(
      'You have been successfully logged out.',
      3500,
      'snackbar-success'
    );
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  toggleGlobalNav(event: any): void {
    this.isGlobalNavOpen = !this.isGlobalNavOpen;
  }
}
