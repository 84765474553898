import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  requestId = '';
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request;
    let guid = Guid.create().toString();

    request = req.clone({
      params:  req.params.set("requestId", guid)
    });

    return next.handle(request) as any;
  }
}
