<epay-page-content>
  <div class="page-content-main box-panel shadow t-alignC max-w max-gs" id="page-content-main">
    <h2 class="mb-5">Select Account Type</h2>
    <div class="account-type-links mx-5">
      <div class="mb-3">
        <a class="btn-link mx-3 mb-3"[routerLink]="['/ncpFAQ']">Noncustodial Parent</a>
        <a class="btn-link mx-3 mb-3"[routerLink]="['/employerFAQ']">Employer</a>

      </div>
    </div>
  </div>
</epay-page-content>
