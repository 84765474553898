<div class="tab-content profile-security">
    <div class="bg-white">
      <div class="container">
        <div class="row m--full-bleed">
          <div class="col-12 col-lg-6 form-display-card">
            <form [formGroup]="loginFrmGp" (ngSubmit)="updateLogin()">
              <mat-card class="card-container">
                <div *ngIf="useEmailForLogin; then useEmailId else useLoginId">
                </div>
              </mat-card>
              <!-- Edit Email -->
              <ng-template #useEmailId>
                <mat-card-header>
                  <mat-card-title>Change Username</mat-card-title>
                </mat-card-header>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Current Username" formControlName="currentLoginId" autocomplete="off">
                    <mat-error>{{currentLoginErrorMessage}}</mat-error>
                    <mat-error *ngIf="loginFrmGp.get('currentLoginId')?.hasError('required')">{{
                      validationLoginIdMessages["required"] }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Current Password" [type]="currentLoginPasswordHide ? 'password' : 'text'"
                      formControlName="currentPassword" autocomplete="off">
                    <mat-icon matSuffix (click)="toggleLoginPasswordHide()">{{currentLoginPasswordHide ? 'visibility_off'
                      : 'visibility'}}</mat-icon>
                    <mat-error>{{currentLoginPasswordErrorMessage}}</mat-error>
                    <mat-error>{{getPasswordError("currentPassword")}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2 mb-3">
                  <mat-form-field>
                    <input matInput placeholder="New Username" #loginId formControlName="newLoginId" autocomplete="off">
                    <mat-hint *ngIf="!isValidLogin">{{hintMessageUsingEmail}}</mat-hint>
                    <mat-hint *ngIf="isValidLogin" class="mat-hint-success">{{newLoginSuccessMessage}}</mat-hint>
                    <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                    <span *ngIf="isValidLogin" matSuffix><i class="fa fa-check c-green0"></i></span>
                    <mat-error>{{newLoginErrorMessage}}</mat-error>
                    <mat-error *ngIf="loginFrmGp.get('newLoginId')?.hasError('required')">{{
                      validationLoginIdMessages["required"] }}</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="flex-input-col pt-2 pb-2">
                  <label class="smart-checkbox">
                    <input type="checkbox" class="form-check-input smart-checks checkmark" formControlName="updateEmail">
                    <span class="ts-s">Set Contact Email to New Username</span>
                    <span class="checkmark"></span>
                  </label>
                </div>
  
                <div class="flex-btn-row pt-2 pb-2">
                  <button class="btn btn-submit" mat-raised-button type="submit">Save</button>
                </div>
              </ng-template>
  
              <!-- Update Username -->
              <ng-template #useLoginId>
                <mat-card-header>
                  <mat-card-title>Change Username</mat-card-title>
                </mat-card-header>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Current Username" formControlName="currentLoginId" autocomplete="off">
                    <mat-error>{{currentLoginErrorMessage}}</mat-error>
                    <mat-error *ngIf="loginFrmGp.get('currentLoginId')?.hasError('required')">{{
                      validationLoginIdMessages["required"] }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Current Password" [type]="currentLoginPasswordHide ? 'password' : 'text'"
                      formControlName="currentPassword" autocomplete="off">
                    <mat-icon matSuffix (click)="toggleLoginPasswordHide()">{{currentLoginPasswordHide ? 'visibility_off'
                      : 'visibility'}}</mat-icon>
                    <mat-error>{{currentLoginPasswordErrorMessage}}</mat-error>
                    <!-- <mat-error>{{getPasswordError("currentPassword")}}</mat-error> -->
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="New Username" #loginId formControlName="newLoginId" autocomplete="off">
                    <mat-hint *ngIf="!isValidLogin">{{loginHintMessage}}</mat-hint>
                    <mat-hint *ngIf="isValidLogin" class="mat-hint-success">{{newLoginSuccessMessage}}</mat-hint>
                    <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                    <span *ngIf="isValidLogin" matSuffix><i class="fa fa-check c-green0"></i></span>
                    <mat-error>{{newLoginErrorMessage}}</mat-error>
                    <mat-error *ngIf="loginFrmGp.get('newLoginId')?.hasError('required')">{{
                      validationLoginIdMessages["required"] }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-btn-row pt-2 pb-2">
                  <button class="btn btn-submit" mat-raised-button type="submit">Save</button>
                </div>
              </ng-template>
            </form>
          </div>
          <!-- Update Password -->
          <div class="col-12 col-lg-6 form-display-card">
            <form [formGroup]="passwordFrmGp" (ngSubmit)="updatePassword()">
              <mat-card class="card-container">
                <mat-card-header>
                  <mat-card-title>Change Password</mat-card-title>
                </mat-card-header>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Current Password"
                      [type]="currentChangePasswordHide ? 'password' : 'text'" formControlName="currentPassword"
                      autocomplete="off">
                    <mat-icon matSuffix (click)="toggleChangeCurrentPasswordHide()">{{currentChangePasswordHide ?
                      'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error>{{getPasswordError("currentPassword")}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="New Password" [type]="newChangePasswordHide ? 'password' : 'text'"
                      formControlName="newPassword1" autocomplete="off">
                    <mat-hint>{{passwordHintMessage}}</mat-hint>
                    <mat-icon matSuffix (click)="toggleChangePasswordHide()">{{newChangePasswordHide ? 'visibility_off' :
                      'visibility'}}</mat-icon>
                    <mat-error>{{getPasswordError("newPassword1")}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput [errorStateMatcher]="errorMatcher" placeholder="Confirm New Password"
                      [type]="newChangeConfirmPasswordHide ? 'password' : 'text'" formControlName="newPassword2"
                      autocomplete="off">
                    <mat-icon matSuffix (click)="toggleChangeConfirmPasswordHide()">{{newChangeConfirmPasswordHide ?
                      'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="passwordFrmGp.hasError('doNotMatch')">
                      Passwords do not match.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-btn-row pt-2 pb-2">
                  <button class="btn btn-submit" mat-raised-button type="submit">Save</button>
                </div>
              </mat-card>
            </form>
          </div>
        </div>
      </div>
    </div>