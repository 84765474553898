import { Component, OnInit } from '@angular/core';
import { AuthService } from 'epay-library';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'epay-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  username$: Observable<string> = of('');
  usertype$: Observable<string> = of('');
  isLoggedIn$: Observable<boolean> = of(false);

  constructor(private authservice: AuthService) {}

  ngOnInit() {
    this.isLoggedIn$ = this.authservice.isLoggedIn;
    this.username$ = this.authservice.currentUserName;
    this.usertype$ = this.authservice.currentUserType;
  }
}
