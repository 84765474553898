<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main" id="page-content-main">
    <div *ngIf="!openConfirmation">
      <div class="container m--full-bleed">
        <div class="row m--full-bleed">
          <!-- <div class="col-3 form-display-card">
            <mat-card style="padding: 0px; margin-top: 10px; margin-left: 20px">
              <mat-card-header class="profile-nav-title" (click)="goToProfileHome()">
                <mat-card-title style="padding: 7px 0 0 20px">Profile & Settings</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <ul class="sidebar-nav">
                    <li>
                      <a (click)="showDetails()">Your Contact Details <span
                          class="fa fa-chevron-right profile-sidenav-icon"></span></a>
                    </li>
                    <li>
                      <a (click)="showSecurity()">Sign-in Security <span
                          class="fa fa-chevron-right profile-sidenav-icon"></span></a>
                    </li>
                    <li>
                      <a (click)="showAccounts()">User Account Management <span
                          class="fa fa-chevron-right profile-sidenav-icon"></span></a>
                    </li>
                  </ul>
                </div>
              </mat-card-content>
            </mat-card>
          </div> -->
          <mat-tab-group [(selectedIndex)]="selectedTab" animationDuration="0ms" #employerProfileTabGroup>
            <mat-tab label="Contact Details">
              <div class="bg-white">
                <div class="pa-3">
                  <h2 class="mb-1">Your Contact Details</h2>
                  <p class="c-grey6 ts-s">Update your email, phone or address, and more.</p>
                </div>
                <epay-lib-employer-profile-detail *ngIf="displayDetails" [baseurl]="baseurl"
                  (profileConfirmationEmitter)="displayConfirmationPage($event)" [useEmailForLogin]="useEmail">
                </epay-lib-employer-profile-detail>
              </div>
            </mat-tab>
            <mat-tab label="Sign-in Security">
              <div class="bg-white">
                <div class="pa-3">
                  <h2 class="mb-1">Sign-in Security</h2>
                  <p class="c-grey6 ts-s">Update your username and password to ones that are unique to this account.</p>
                </div>

                <!-- <epay-lib-employer-profile-security *ngIf="displaySecurity" [baseurl]="baseurl"
                  (profileConfirmationEmitter)="displayConfirmationPage($event)" [useEmailForLogin]="useEmail"
                  [newEmailFromLogin]="newEmailFromLogin">
                </epay-lib-employer-profile-security> -->

                <employer-profile-security *ngIf="displaySecurity" [baseurl]="baseurl"
                  (profileConfirmationEmitter)="displayConfirmationPage($event)" [useEmailForLogin]="useEmail"
                  [newEmailFromLogin]="newEmailFromLogin">
                </employer-profile-security>

              </div>
            </mat-tab>
            <mat-tab label="Manage Users">
              <div class="bg-white">
                <div class="pa-3">
                  <h2 class="mb-1">User Accounts</h2>
                  <p class="c-grey6 ts-s">Organize your accounts, add/edit other users, and more.</p>
                </div>
                <!-- <epay-lib-employer-profile-account *ngIf="displayAccounts" [baseurl]="baseurl"
                  (profileConfirmationEmitter)="displayConfirmationPage($event)" [useEmailForLogin]="useEmail">
                </epay-lib-employer-profile-account> -->

              <employer-profile-account *ngIf="displayAccounts" [baseurl]="baseurl"
                (profileConfirmationEmitter)="displayConfirmationPage($event)" [useEmailForLogin]="useEmail">
              </employer-profile-account>
              
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
  <epay-lib-common-confirmation *ngIf="openConfirmation" [confirmationTitle]="confirmationTitle"
    [confirmationMessage]="confirmationMessage" [confirmationNumber]="confirmationNumber"
    [showConfirmationEmail]="showConfirmationEmail" [restart]="restart" [goToPrimary]="goToPrimary"
    [goToPrimaryUrl]="goToPrimaryUrl" [showEmailMismatch]="showEmailMismatch">
  </epay-lib-common-confirmation>
</epay-page-content>
