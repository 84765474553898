import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CommonSettings } from 'epay-library';
import { AuthService } from 'epay-library';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token = '';
  excludeConfig: string[] = [];

  constructor(private authservice: AuthService) {
    this.excludeConfig.push('/assets/config/config.json');
    this.excludeConfig.push('EPay/api/v1/Parameters');
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authservice.currentUserToken.subscribe({
      next: (result: any) => (this.token = result),
    });
    let request;

    if (this.skipConfig(req.url.toString())) {
      request = req.clone({
        setHeaders: {
          Client: environment.clientID,
        },
      });
      return next.handle(request) as any;
    } else {
      if (this.token !== null) {
        if (this.token.startsWith('"')) {
          this.token = this.token.substring(1, this.token.length - 1);
        }
      }

      if (this.token !== null && this.token !== undefined) {
        request = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            Environment: CommonSettings.Environment,
            Client: CommonSettings.ClientID,
            ClientName: CommonSettings.ClientName,
          },
        });
      } else {
        request = req.clone({
          setHeaders: {
            Environment:
              CommonSettings.Environment === undefined
                ? ''
                : CommonSettings.Environment,
            Client:
              CommonSettings.ClientID === undefined
                ? ''
                : CommonSettings.ClientID,
            ClientName:
              CommonSettings.ClientName === undefined
                ? ''
                : CommonSettings.ClientName,
          },
        });
      }

      return next.handle(request) as any;
    }
  }

  skipConfig(url: string): boolean {
    let block = false;
    for (const api of this.excludeConfig) {
      if (url.toLowerCase().includes(api.toLowerCase())) {
        block = true;
        break;
      } else {
        block = false;
      }
    }
    return block;
  }
}
