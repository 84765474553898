<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main" id="page-content-main">
    <!-- <epay-lib-employer-register [baseurl]="baseurl" [termsContent]="termsContent" [loginHelp]="loginHelp"
      [canCreateNewEmployer]="canCreateNewEmployer" [useEmailForLogin]="useEmailForLogin">
    </epay-lib-employer-register> -->

    <div *ngIf="!openConfirmation">
      <div class="stepper-panel">
        <div class="justify-content-center">
          <div class="form-panel register-form-field">
            <mat-stepper [orientation]="stepperOrientation" #stepper linear="true">
              <!-- 1. T&C -->
              <mat-step class="stepper-icon" [stepControl]="termsFrmGp">
                <form [formGroup]="termsFrmGp">
                  <ng-template matStepLabel>Terms and Conditions</ng-template>
                  <div>
                    <div #cguContainer class="terms-panel" (scroll)="checkReading()" [innerHTML]="termsContent">
                    </div>
                  </div>
                  <div class="pt-2 pb-2">
                    <label class="smart-checkbox ts-s">
                      <input type="checkbox" class="form-check-input smart-checks checkmark" formControlName="accepted"
                        required>
                      <span>Accept Terms and Conditions</span>
                      <span class="checkmark"></span>
                      <div class="c-red1" *ngIf="isTermsSubmitted && termsFrmGp.get('accepted' )!.value !== true">Please
                        accept the terms and conditions to move forward.</div>
                    </label>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button (click)="goToMainHome()">Back</button>
                    <button class="btn" (click)="submitTerms()" mat-button matStepperNext>Next</button>
                  </div>
                </form>
              </mat-step>
              <!-- 2. Employer Search -->
              <mat-step class="stepper-icon" [stepControl]="searchFrmGrp">
                <form [formGroup]="searchFrmGrp" (ngSubmit)="Search()" autocomplete="off">
                  <ng-template matStepLabel>Complete Employer Search</ng-template>
                  <p>{{searchMessage}}</p>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Employer Name" formControlName="empname">
                    </mat-form-field>
                    <mat-form-field *ngIf="allowEINSearch">
                      <input matInput mask="999999999" placeholder="EIN" formControlName="empein" minlength="9" maxlength="9">
                      <mat-hint><strong>Ex: 123456789</strong></mat-hint>
                      <mat-error *ngIf="searchFrmGrp.get('empein')!.value && 
                      searchFrmGrp.get('empein')!.value?.length !== 9">EIN must be 9 digits. (Ex: 123456789)</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" type="reset" mat-button (click)="ClearSearch()">Clear</button>
                    <button [disabled]="searchFrmGrp.get('empein')!.value && searchFrmGrp.get('empein')!.value?.length < 9" 
                    class="btn btn-submit" type="submit">Search</button>
                  </div>
                </form>
                <form [formGroup]="selectedEmployerFrmGp" (ngSubmit)="submitEmployer()">
                  <div class="pt-2 pb-2">
                    <span *ngIf="isSearching">
                      <i class="fa fa-refresh fa-spin fa-2x fa-fw mt-4"></i>
                    </span>
                    <div *ngIf="empsearchresults && empsearchresults?.length; else noresults">
                      <h4>Select Employer</h4>
                      <div class="search-result-panel mt-3">
                        <table class="table">
                          <thead>
                            <tr>
                              <th *ngFor="let col of displayedColumns">{{col}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let emp of empsearchresults; let i = index" [class.active]='emp.isSelected'>
                              <td>
                                <label class="smart-checkbox">
                                  <input type="checkbox" (change)="addRemoveEmployer($event, i)" [checked]=emp.isSelected
                                    formControlName="empselected">
                                  <span class="checkmark"></span>
                                </label>
                              </td>
                              <td>{{emp.thirdPartyName}}</td>
                              <td>{{emp.addressLine1}} {{emp.addressLine2}}</td>
                              <td>{{emp.addressCity }}</td>
                              <td>{{emp.addressState}}</td>
                              <td>{{emp.addressZip5}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="mt-2" *ngIf="
                                  selectedEmployerFrmGp.get('empselected')!.value !== true &&
                                  isEmpSearchSubmitted">
                        <span class="fa fa-exclamation-triangle fa-md-red"></span>
                        <span class="c-red1">
                          Please select an employer to move forward.
                        </span>
                      </div>
                      <div *ngIf="canCreateNewEmployer" class="register-text mt-3">Can't
                        find your employer ?
                        <i class="fa fa-user-plus fa-xs-blue"></i> <a class="login-signup"
                          (click)="displayCreateEmployer(stepper)"> Register a New Company</a>
                      </div>
                      <div *ngIf="!canCreateNewEmployer" class="register-text mt-3">
                        If you cannot find your business, please contact the state.
                      </div>
                    </div>
                    <ng-template #noresults>
                      <p class="ts-l">{{searchErrorMessage}}</p>
                      <div *ngIf="canCreateNewEmployer && hasSearched" class="register-text mt-3">
                        Can&rsquo;t find your employer?
                        <i class="fa fa-user-plus fa-xs-blue"></i> <a class="login-signup"
                          (click)="displayCreateEmployer(stepper)"> Register a New Company</a>
                      </div>
                    </ng-template>
                  </div>

                  <div class="mt-3 mb-3" *ngIf="empOnHold && selectedEmployer?.isSelected && isEmpSearchSubmitted">
                    <span class="fa fa-exclamation-triangle fa-md-red"></span>
                    <span class="c-red1">
                      There is a hold on this account. Please call the telephone number located on the Contact Us Page.
                    </span>
                  </div>

                  <div class="mt-3 mb-3"
                    *ngIf="selectedEmployer?.hasAccount && selectedEmployer?.isSelected && !selectedEmployer.hasHold">
                    <span class="fa fa-exclamation-triangle fa-md-red"></span>
                    <span class="c-red1">
                      There has already been an account established with this employer. However, you can email the
                      primary account at {{selectedEmployer.primaryEmailAddress}} to add you as a secondary user.
                    </span>
                  </div>
    
                  <div *ngIf="empsearchresults && empsearchresults.length > 0" class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                    <!-- <button
                      *ngIf="!isEmpSearchSubmitted"
                      [disabled]="selectedEmployer?.hasAccount && selectedEmployer?.isSelected && !selectedEmployer.hasHold || !selectedEmployer?.isSelected"
                      class="btn btn-primary" type="submit" (click)="submitEmployer()" mat-button
                     >Validate Employer</button> -->

                    <button
                      [disabled]="selectedEmployer?.hasAccount && selectedEmployer?.isSelected || !selectedEmployer?.isSelected || empOnHold"
                      class="btn btn-primary" type="submit" (click)="submitEmployer()" mat-button
                      matStepperNext>Next</button>

                      <!-- <span *ngIf="validatingEmployer" matSuffix><i class="fa fa-refresh fa-spin fa-2x fa-fw"></i></span>
                      <span *ngIf="!validatingEmployer && isEmpSearchSubmitted && !empOnHold && empSelected && !selectedEmployer?.hasAccount" class="text-success font-weight-bold" matSuffix>Available for Registration <i class="fa fa-check c-green0 ts-l"></i></span>
                      <span *ngIf="!validatingEmployer && isEmpSearchSubmitted && empOnHold && empSelected && !selectedEmployer?.hasAccount" class="text-danger font-weight-bold" matSuffix>Employer placed on hold <i class="fa fa-times-circle c-red2 ts-l"></i></span> -->
                  </div>
                </form>
              </mat-step>
              <!-- 3. New Employer -->
              <mat-step *ngIf="showCreateEmp" [stepControl]="createEmployerFrmGp">
                <form [formGroup]="createEmployerFrmGp" autocomplete="off">
                  <ng-template matStepLabel> Create New Employer </ng-template>
                  <div>Please enter your Business Information below.</div>
                  <div class="flex-input-col pt-3 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Employer Name" formControlName="empName" required>
                      <mat-error>{{getNewEmpErrorMessage("empName")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput mask="999999999" placeholder="EIN" formControlName="ein" required minlength="9" maxlength="9">
                      <mat-hint *ngIf="!isCheckingEin && !isValidEin"><strong>Ex: 123456789</strong></mat-hint>
                      <mat-hint *ngIf="(!createEmployerFrmGp.get('ein')!.errors && isValidEin && !isCheckingEin)" 
                      class="mat-hint-success">{{einSuccessMessage}}
                      </mat-hint>
                      <span *ngIf="isCheckingEin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                      <span *ngIf="!createEmployerFrmGp.get('ein')!.errors && isValidEin && !isCheckingEin" matSuffix><i 
                        class="fa fa-check c-green0 ts-l"></i></span>
                      <!-- <span *ngIf="!isValidEin? && !isCheckingEin? && einErrorMessage?.length > 0" matSuffix><i
                            class="fa fa-exclamation-triangle" style="font-size: 20px;color:red"></i></span> -->
                      <!-- <mat-error>{{einErrorMessage}}</mat-error> -->
                      <mat-error *ngIf="createEmployerFrmGp.get('ein')!.value && 
                      createEmployerFrmGp.get('ein')!.value?.length !== 9">EIN must be 9 digits. (Ex: 123456789)</mat-error>
                      <mat-error *ngIf="!createEmployerFrmGp.get('ein')!.value">Required</mat-error>

                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Address" formControlName="address1" required>
                      <mat-error>{{getNewEmpErrorMessage("address1")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Line 2" formControlName="address2">
                      <mat-error>{{getNewEmpErrorMessage("address2")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="City" formControlName="city" required>
                      <mat-error>{{getNewEmpErrorMessage("city")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="State"
                        formControlName="state" required>
                        <mat-option *ngFor="let state of states" [value]="state.code">
                          {{state.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error>{{getNewEmpErrorMessage("state")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Zip" formControlName="zip5" required>
                      <mat-error>{{getNewEmpErrorMessage("zip5")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="Country"
                        formControlName="country" required>
                        <mat-option *ngFor="let country of countries" [value]="country.country">
                          {{country.countryName}}
                        </mat-option>
                      </mat-select>
                      <mat-error>{{getNewEmpErrorMessage("country")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                    <button *ngIf="displayAddEmployerButton" class="btn btn-primary-short"
                      [disabled]="createEmployerFrmGp.invalid" (click)="createNewEmployer()" mat-button>Add
                      Employer</button>
                    <button *ngIf="employerCreatedSuccessfully" class="btn btn-primary-short" mat-button
                      matStepperNext>Next</button>
                  </div>
                  <div *ngIf="employerCreatedSuccessfully" class="c-green0">
                    <p>{{employerCreatedMessage}}</p>
                  </div>
                </form>
              </mat-step>
              <!-- 4. Contact -->
              <mat-step [stepControl]="demographicFrmGp">
                <form [formGroup]="demographicFrmGp" autocomplete="off">
                  <ng-template matStepLabel>Fill out your Contact Information</ng-template>
                  <div class="">
                    <p>Please enter your Contact Information below.</p>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="First name" formControlName="firstName" required>
                      <mat-error>{{getDemographicErrorMessage("firstName")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Middle name" formControlName="middleName">
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Last name" formControlName="lastName" required>
                      <mat-error>{{getDemographicErrorMessage("lastName")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Address" formControlName="address1" required>
                      <mat-error>{{getDemographicErrorMessage("address1")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Line 2" formControlName="address2">
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="City" formControlName="city" required>
                      <mat-error>{{getDemographicErrorMessage("city")}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="State"
                        formControlName="state" required>
                        <mat-option *ngFor="let state of states" [value]="state.code">
                          {{state.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error>{{getDemographicErrorMessage("state")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Zip" formControlName="zip5" required>
                      <mat-error>{{getDemographicErrorMessage("zip5")}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="Country"
                        formControlName="country" required>
                        <mat-option *ngFor="let country of countries" [value]="country.country">
                          {{country.countryName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field formGroupName="phoneGp">
                      <!-- <input matInput placeholder="Phone" [mask]="phoneMask" formControlName="phoneNumber" required> -->
                      <input matInput placeholder="Phone" formControlName="phoneNumber" [mask]="phoneMask" required>
                      <span matPrefix>+{{demographicFrmGp.get('phoneGp.countryCode' )!.value}}&nbsp;&nbsp;</span>
                      <mat-hint>{{phoneExample}}</mat-hint>
                      <mat-error *ngIf="!demographicFrmGp.get('phoneGp.isValid')?.value ||
                                            demographicFrmGp.get('phoneGp ')?.hasError('requiredTrue')">Invalid Format
                      </mat-error>
                      <mat-error *ngIf="demographicFrmGp.get('phoneGp.phoneNumber ')?.hasError('required')">Required
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Phone Ext" formControlName="phoneExtension">
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field formGroupName="cellGp">
                      <!-- <input matInput placeholder="Cell Phone" [mask]="cellMask" formControlName="cellNumber"> -->
                      <input matInput placeholder="Cell Phone" formControlName="cellNumber" [mask]="phoneMask">
                      <span matPrefix>+{{demographicFrmGp.get('cellGp.countryCode' )!.value}}&nbsp;&nbsp;</span>
                      <mat-hint>{{cellExample}}</mat-hint>
                      <mat-error *ngIf="!demographicFrmGp.get('cellGp.isValid')?.value ||
                                           demographicFrmGp.get('cellGp ')?.hasError('requiredTrue')">Invalid format
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Email" formControlName="email" required>
                      <mat-hint *ngIf="isValidEmail && !isCheckingEmail" class="mat-hint-success">{{emailSuccessMessage}}
                      </mat-hint>
                      <span *ngIf="isCheckingEmail" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                      <span *ngIf="isValidEmail" matSuffix><i class="fa fa-check"
                          style="font-size: 25px;color:darkgreen"></i></span>
                      <mat-error>{{emailErrorMessage}}</mat-error>
    
                    </mat-form-field>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                    <button class="btn btn-primary-short" [disabled]="demographicFrmGp.invalid" mat-button
                      matStepperNext>Next</button>
                  </div>
                </form>
              </mat-step>
              <!-- 5. Login Info -->
              <mat-step [stepControl]="loginFrmGp">
                <form [formGroup]="loginFrmGp" autocomplete="off">
                  <ng-template matStepLabel>Fill out Login Information</ng-template>
                  <div class="mt-3">
                    Please complete your Login Information below.
                    <span (click)="displayLoginHelp()" class="ml-2 fa fa-question-circle fa-sm-blue"
                      matTooltip="LOGIN INFORMATION HELP" matTooltipClass="tooltip-md">
                    </span>
                  </div>
                    <div class="flex-input-col pt-2 pb-3">
                      <mat-form-field>
                        <input matInput placeholder="Username" #loginId formControlName="loginId" 
                        (change)="updateUsernameValidation($event)" required>
                        <!-- <mat-hint *ngIf="!isValidLogin && !isCheckingLogin">{{loginHintMessage}}</mat-hint> -->
                        <mat-hint *ngIf="!isValidLogin && !isCheckingLogin">
                          <strong>Alphanumerical format:</strong> Must contain at least 8 characters (at least 6 letters, 2 numbers) with a max of 12 characters. <br>
                          <strong>Email Format:</strong> Must match the email provided in the previous step. 
                        </mat-hint>
                        <mat-hint *ngIf="isValidLogin && !isCheckingLogin" class="c-green0">{{loginSuccessMessage}}
                        </mat-hint>
                        <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                        <span *ngIf="isValidLogin" matSuffix><i class="fa fa-check c-green0 ts-l"></i></span>
                        <mat-error>{{loginErrorMessage}}</mat-error>
                        <mat-error *ngIf="loginFrmGp.get('loginId')?.hasError('required') &&
                                          loginErrorMessage?.length === 0">Required</mat-error>
                      </mat-form-field>
                    </div>
                  <div formGroupName="passwordGp">
                    <div class="flex-input-col pt-2 pb-2">
                      <mat-form-field>
                        <input matInput placeholder="Password" [type]="passwordHide ? 'password' : 'text'"
                          formControlName="password1" required>
                        <mat-hint>{{passwordHintMessage}}</mat-hint>
                        <mat-icon matSuffix (click)="togglePasswordHide()">{{passwordHide ? 'visibility_off' :
                          'visibility'}}</mat-icon>
                        <mat-error>{{passwordErrorMessage}}</mat-error>
                        <mat-error *ngIf="loginFrmGp.invalid && passwordErrorMessage?.length === 0">Required</mat-error>
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput [errorStateMatcher]="errorMatcher" placeholder="Confirm Password"
                          [type]="confirmpasswordHide ? 'password' : 'text'" formControlName="password2" required>
                        <mat-icon matSuffix (click)="toggleConfirmPasswordHide()">{{confirmpasswordHide ? 'visibility_off' :
                          'visibility'}}</mat-icon>
                        <mat-error *ngIf="loginFrmGp.get('passwordGp')?.hasError('doNotMatch')">Passwords do not match.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <mat-select matInput disableOptionCentering panelClass="matSelectClass"
                        placeholder="Challenge Question" (selectionChange)="challengeQuestionChangeEvent()"
                        formControlName="authenticationQuestionIndex">
                        <mat-option *ngFor="let question of questions" [value]="question.index">
                          {{question.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-input-col pt-2 pb-2">
                    <mat-form-field>
                      <input matInput placeholder="Challenge Answer" formControlName="challengeAnswer">
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                    <button class="btn btn-primary-short" [disabled]="loginFrmGp.invalid" mat-button
                      matStepperNext>Next</button>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="reviewFrmGrp">
                <form [formGroup]="reviewFrmGrp">
                  <ng-template matStepLabel>Review Your Information</ng-template>
                  <div class="container">
                    <div class="row m--full-bleed">
    
                      <!-- Employer information card-->
                      <div class="col-12 col-lg-4 form-display-card mb-5 m--mb0">
                        <div class="mb-2">
                          <mat-card class='mat-card-display'>
                            <mat-card-header>
                              <mat-card-title class="justify-content-start">
                                <h2 class="mat-card-title align-items-start">
                                  <span class="fa fa-building-o fa-md-black mr-2"></span> Employer Information
                                </h2>
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="">
                                <div class="row review-payment justify-content-center">
    
                                  <div class="col-12 col-lg-6">
                                    <dl class="account-details-sum">
                                      <div>
                                        <dt class="account-dt">
                                          Employer Name
                                        </dt>
                                        <dd class="account-dt-info">
                                          {{selectedEmployer.thirdPartyName}}
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
    
                                  <div class="col-12 col-lg-6">
                                    <dl class="account-details-sum">
                                      <div>
                                        <dt class="account-dt">
                                          Address
                                        </dt>
                                        <dd class="account-dt-info">
                                          {{selectedEmployer.addressLine1}}
                                          {{selectedEmployer.addressLine2}}
                                          {{selectedEmployer.addressCity}},
                                          {{selectedEmployer.addressState}}
                                          {{selectedEmployer.addressZip5}}
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
    
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <!--<mat-card class='mat-card-display'>
                          <mat-card-header>
                            <mat-card-title class="justify-content-start">
                              <h2 class="mat-card-title align-items-start">
                                <span class="fa fa-building-o fa-md-black mr-2"></span> Employer Information
                              </h2>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <div class="col-sm tw-b">Employer Name</div>
                              <div class="col-sm tw-b">Address</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{selectedEmployer.thirdPartyName}}</div>
                              <div class="col-sm">{{selectedEmployer.addressLine1}}{{selectedEmployer.addressLine2}}</div>
                            </div>
                            <div class="row">
                              <div class="col-sm"></div>
                              <div class="col-sm">{{selectedEmployer.addressCity}}, {{selectedEmployer.addressState}}
                                {{selectedEmployer.addressZip5}}</div>
                            </div>
                          </mat-card-content>
                        </mat-card>-->
                      </div>
    
    
                      <!-- Personal information card-->
                      <div class="col-12 col-lg-4 form-display-card mb-5 m--mb0">
                        <div class="mb-2">
                          <mat-card class='mat-card-display'>
                            <mat-card-header>
                              <mat-card-title class="justify-content-start">
                                <h2 class="mat-card-title align-items-start">
                                  <span class="fa fa-address-card-o fa-md-black mr-2"></span> Contact Information
                                </h2>
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="">
                                <div class="row review-payment justify-content-center">
    
                                  <div class="col-12 col-lg-6">
                                    <dl class="account-details-sum">
                                      <div>
                                        <dt class="account-dt">
                                          Name
                                        </dt>
                                        <dd class="account-dt-info">
                                          {{this.demographicFrmGp.get('firstName' )!.value}}
                                          {{this.demographicFrmGp.get('middleName' )!.value}}
                                          {{this.demographicFrmGp.get('lastName' )!.value}}
                                        </dd>
                                      </div>
    
                                      <div>
                                        <dt class="account-dt">
                                          Address
                                        </dt>
                                        <dd class="account-dt-info">
                                          {{this.demographicFrmGp.get('address1')!.value}}
                                          {{this.demographicFrmGp.get('address2' )!.value}}
                                          {{this.demographicFrmGp.get('city' )!.value}}
                                          {{this.demographicFrmGp.get('state')!.value}}
                                          {{this.demographicFrmGp.get('zip5' )!.value}}
                                        </dd>
                                      </div>
                                      <div>
                                        <dt class="account-dt">
                                          Email
                                        </dt>
                                        <dd class="account-dt-info">
                                          {{this.demographicFrmGp.get('email' )!.value}}
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
    
                                  <div class="col-12 col-lg-6">
                                    <dl class="account-details-sum">
                                      <div>
                                        <dt class="account-dt">
                                          Home Phone
                                        </dt>
                                        <dd class="account-dt-info">
                                          +{{this.demographicFrmGp.get('phoneGp.countryCode' )!.value}} &nbsp;
                                          {{this.demographicFrmGp.get('phoneGp.phoneNumber' )!.value | mask: phoneMask}}
                                        </dd>
                                      </div>
                                      <div>
                                        <dt class="account-dt">
                                          Cell Phone
                                        </dt>
                                        <dd class="account-dt-info">
                                          +{{this.demographicFrmGp.get('cellGp.countryCode' )!.value}} &nbsp;&nbsp;
                                          {{this.demographicFrmGp.get('cellGp.cellNumber' )!.value | mask: phoneMask}}
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
    
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <!--<mat-card class='mat-card-display'>
                          <mat-card-header>
                            <mat-card-title class="justify-content-start">
                              <h2 class="mat-card-title align-items-start">
                                <span class="fa fa-address-card-o fa-md-black mr-2"></span> Contact Information
                              </h2>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <div class="col-sm tw-b">Name</div>
                              <div class="col-sm tw-b">Home Phone</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{this.demographicFrmGp.get('firstName' )!.value}}
                                {{this.demographicFrmGp.get('middleName' )!.value}}
                                {{this.demographicFrmGp.get('lastName' )!.value}}</div>
                              <div class="col-sm">+{{this.demographicFrmGp.get('phoneGp.countryCode' )!.value}} &nbsp;&nbsp;
                                {{this.demographicFrmGp.get('phoneGp.phoneNumber' )!.value}}</div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-sm tw-b">Address</div>
                              <div class="col-sm tw-b">Cell phone</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{this.demographicFrmGp.get('address1' )!.value}}
                                {{this.demographicFrmGp.get('address2' )!.value}}</div>
                              <div class="col-sm" *ngIf="this.demographicFrmGp.get('cellGp.cellNumber' )!.value">
                                +{{this.demographicFrmGp.get('cellGp.countryCode' )!.value}} &nbsp;&nbsp;
                                {{this.demographicFrmGp.get('cellGp.cellNumber' )!.value}}</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{this.demographicFrmGp.get('city' )!.value}},
                                {{this.demographicFrmGp.get('state' )!.value}} {{this.demographicFrmGp.get('zip5' )!.value}}
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-sm tw-b">Email</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{this.demographicFrmGp.get('email' )!.value}}</div>
                            </div>
                          </mat-card-content>
                        </mat-card>-->
                      </div>
    
                      <div class="col-12 col-lg-4 form-display-card mb-5 m--mb0">
                        <mat-card class='mat-card-display'>
                          <mat-card-header>
                            <mat-card-title class="justify-content-start">
                              <h2 class="mat-card-title align-items-start">
                                <span class="fa fa-user-circle-o fa-md-black mr-2"></span> Login Information
                              </h2>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="">
                              <div class="row review-payment justify-content-center">
    
                                <div class="col-12 col-lg-6">
                                  <dl class="account-details-sum">
                                    <div>
                                      <dt class="account-dt">
                                        Username
                                      </dt>
                                      <dd class="account-dt-info">
                                        <div *ngIf="useEmailForLogin">
                                          {{this.demographicFrmGp.get('email')!.value}}
                                        </div>
                                        <div *ngIf="!useEmailForLogin">
                                          {{this.loginFrmGp.get('loginId')!.value}}
                                        </div>
                                      </dd>
                                    </div>
                                    <div>
                                      <dt class="account-dt">
                                        Password
                                      </dt>
                                      <dd class="account-dt-info">
                                        {{this.loginFrmGp.get('passwordGp.password1')!.value | maskdata: 0}}
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
    
                                <div class="col-12 col-lg-6">
                                  <dl class="account-details-sum">
                                    <div>
                                      <dt class="account-dt">
                                        Challenge Question
                                      </dt>
                                      <dd class="account-dt-info">
                                        {{selectedChallengeQuestion}}
                                      </dd>
                                    </div>
                                    <div>
                                      <dt class="account-dt">
                                        Challenge Answer
                                      </dt>
                                      <dd class="account-dt-info">
                                        {{this.loginFrmGp.get('challengeAnswer')!.value}}
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
    
                              </div>
                            </div>
    
                            <!--<div class="row">
                              <div class="col-sm tw-b">Username</div>
                              <div class="col-sm tw-b">Challenge Question</div>
                            </div>
                            <div class="row">
                              <div *ngIf="useEmailForLogin" class="col-sm">{{this.demographicFrmGp.get('email')!.value}}
                              </div>
                              <div *ngIf="!useEmailForLogin" class="col-sm">{{this.loginFrmGp.get('loginId')!.value}}</div>
                              <div class="col-sm">{{selectedChallengeQuestion}}</div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-sm tw-b">Password</div>
                              <div class="col-sm tw-b">Challenge Answer</div>
                            </div>
                            <div class="row">
                              <div class="col-sm">{{this.loginFrmGp.get('passwordGp.password1')!.value | maskdata: 0}}</div>
                              <div class="col-sm">{{this.loginFrmGp.get('challengeAnswer')!.value}}</div>
                            </div>-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                  <div class="flex-btn-row pt-2 pb-2">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                    <button class="btn btn-submit" mat-raised-button (click)="createAccount()">Create Account</button>
                  </div>
                </form>
              </mat-step>
            </mat-stepper>
          </div>
        </div>
      </div>
    </div>
    
    <epay-lib-common-confirmation *ngIf="openConfirmation" [confirmationTitle]="confirmationTitle"
      [confirmationMessage]="confirmationMessage" [confirmationEmailAddress]="confirmationEmailAddress"
      [confirmationNumber]="confirmationNumber" [goToPrimary]="goToPrimary" [goToPrimaryUrl]="goToPrimaryUrl">
    </epay-lib-common-confirmation>

  </div>
</epay-page-content>
