import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CommonSettings } from 'epay-library';

var MINUTES_UNITL_AUTO_LOGOUT = 0; // in mins
//const MINUTES_UNITL_AUTO_LOGOUT = 15
const CHECK_INTERVAL = 15000; // in ms
const WARNING_TIME = 30000;
const STORE_KEY =  'lastAction';

@Injectable(
 {
  providedIn: 'root'
 })

export class AutoLogoutService {
  val: any;
  isLoggedIn = false;


 public getLastAction() {
    // tslint:disable-next-line:radix
    return parseInt(sessionStorage.getItem(STORE_KEY)!);
  }
 public setLastAction(lastAction: number) {
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router,
              private authservice: AuthService) {

    MINUTES_UNITL_AUTO_LOGOUT = +CommonSettings.TimeOutInMins;
    this.authservice.isLoggedIn.subscribe(status => this.isLoggedIn = status);
    this.check();
    this.initListener();
    this.initInterval();
    sessionStorage.setItem(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
    window.addEventListener('storage', () => this.storageEvt());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
    //console.log(CHECK_INTERVAL)
  }

  check() {
    if (this.isLoggedIn) {
        const now = Date.now();
        const timeleft = this.getLastAction() + +MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isWarning = diff < WARNING_TIME;
        const isTimeout = diff < 0;
        if (isWarning) {
            this.router.navigateByUrl('logoutwarning');
        }

        if (isTimeout) {
          this.authservice.logout(CommonSettings.BaseApi);
        }

        //console.log(diff)
    }
  }

  storageEvt() {
    this.val = sessionStorage.getItem(STORE_KEY);
  }
}

