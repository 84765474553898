<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main max-w max-gl" id="page-content-main">
    <div *ngIf="!openConfirmation">
      <div class="container m--full-bleed">
        <!--Scheduled payments-->
        <div class="row m--full-bleed mb-3">
          <div class="col-12">
            <mat-card class="card-container">
              <mat-card-header>
                <mat-card-title>
                  <h2 class="mat-card-title mb-0">Scheduled Payments
                    <a target="_blank" href="/assets/help/How To Create Payments.pdf" class="dib ml-1">
                      <span class="fa fa-question-circle fa-sm-blue" matTooltip="HOW TO SETUP A SCHEDULED PAYMENT"
                        matTooltipClass="tooltip-md">
                      </span>
                    </a>
                  </h2>
                  <div *ngIf="isPaymentAccountSetup">
                    <a [routerLink]="['/employer/achd/payment']" class="ts-s">
                      Add
                      <span class="fa fa-plus-circle fa-sm-blue" matTooltip="ADD NEW PAYMENT"
                        matTooltipClass="tooltip-md">
                      </span>
                    </a>
                  </div>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="max-height-scrollable-block">
                  <epay-lib-employer-paymentlist [baseurl]="baseurl"
                    [secondaryIdAlias]="secondaryIdAlias"
                    (deletePaymentEmitter)="displayConfirmationPage($event)">
                  </epay-lib-employer-paymentlist>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <!--Payment history-->
        <div class="row m--full-bleed">
          <div class="col-12 col-lg-8">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Payment History</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <epay-lib-employer-paymenthistory [baseurl]="baseurl"
                  [secondaryIdAlias]="secondaryIdAlias"
                  [numOfDaysForPaymentHistory]="numOfDaysForPaymentHistory" [showParticipantCase]="showParticipantCase">
                </epay-lib-employer-paymenthistory>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="!isMobile" class="col-12 col-lg-4 form-display-card">
            <div class="">
              <mat-card class="info-help-panel">
                <mat-card-header>
                  <mat-card-title>My Account</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="flex-ico-row mb-3">
                    <div class="flex-ico">
                      <span class="fa fa-address-card-o fa-lg-gray"></span>
                    </div>
                    <div class="flex-text">
                      <h3 class="mb-1">{{username}} {{isManager && '(Manager)'}}
                      </h3>
                      <address class="ts-s">
                        {{accountAddress}} {{accountCSZ}}
                      </address>
                    </div>
                  </div>
                  <dl class="account-details-sum">
                    <div class="account-sum-row">
                      <dt class="account-dt">Employer</dt>
                      <dd class="account-dt-info">{{employerName}} </dd>
                    </div>
                    <div class="account-sum-row">
                      <dt class="account-dt">Phone</dt>
                      <dd class="account-dt-info">{{accountPhone | mask: phoneMask}} </dd>
                    </div>
                    <div class="account-sum-row">
                      <dt class="account-dt">Email</dt>
                      <dd class="account-dt-info">{{accountEmail}} </dd>
                    </div>
                  </dl>
                </mat-card-content>
                <hr>
                <div *ngIf="numOfUsers === 1 && isManager" class="text-center">
                  <mat-card-content>
                    <div class="ts-base">
                      <strong>No additional user found.</strong><br />
                      Click <a [routerLink]="['/employer/profile/settings']" [state]="{display: 'account'}"><i
                          class="fa fa-user-plus"></i> HERE</a> to add an additional user.
                    </div>
                  </mat-card-content>
                  <hr>
                </div>
                <mat-card-content>
                  <div class="t-alignC">
                    <a class="btn-link mx-3 mb-3" [routerLink]="profileSettingsUrl">Profile and Settings</a>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <!-- <div class="row mt-3">
            <mat-card class="info-help-panel">
            <mat-card-header>
                <mat-card-title>Need Help</mat-card-title>
            </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <span class="fa fa-question fa-lg-gray"></span>
                        <div class="col-sm ml-2">
                            <div class="row"><a href="employer/faq">Frequently Asked Questions</a></div>
                            <div class="row"><a href="employer/contact">Contact Us</a></div>
                        </div>
                    </div>
                </mat-card-content>
            <hr>
            <mat-card-content>
                <div class="row justify-content-center">
                    <button class="btn btn-primary">Help and Support</button>
                </div>
              </mat-card-content>
            </mat-card>
          </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <epay-lib-common-confirmation *ngIf="openConfirmation" [sender]="confirmationSender"
    [confirmationTitle]="confirmationTitle" [confirmationMessage]="confirmationMessage"
    [confirmationNumber]="confirmationNumber" [goToPrimary]="goToPrimary" [goToPrimaryUrl]="goToPrimaryUrl"
    (confirmationEmitter)="hideConfirmationPage($event)">
  </epay-lib-common-confirmation>
</epay-page-content>
