import { Component, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-changepassword',
  templateUrl: './changepassword.component.html',
})
export class ChangePasswordComponent implements OnInit {
  pagetitle = 'Change Password';
  baseurl: string;
  constructor() {
    this.baseurl = CommonSettings.BaseApi;
  }

  ngOnInit() {}
}
