<div class="flip-card" [class.flip]="flip">
    <div class="flip-card-inner">
      <div class="flip-card-front bg">
        <!-- <img class="card-square" src="https://image.ibb.co/cZeFjx/little_square.png"> -->
        <img class="card-logo card-front-logo" [src]="ccType">
        <p *ngIf="!ccHide && _no !== ''" class="card-numer">{{ _no }}</p>
        <p *ngIf="ccHide && _no !== ''" class="card-numer" mask="0000 0000 0000 0000">{{ _no | maskdata: 4 }}</p>
        <div class="card-holder">
          <span class="card-label">Card Holder</span>
          <p class="card-info">{{ _holder }}</p>
        </div>
        <div class="card-expires">
          <span class="card-label">Expires</span>
          <p class="card-info">{{ _expMonth }}/{{ _expYear }}</p>
        </div>      
      </div>
      <div class="flip-card-back bg">
        <div class="card-black-line"></div>
        <div class="card-back-content">
          <div class="card-secret">
            <p *ngIf="!cvvHide && _cvc !== ''" class="card-secret-last">{{ _cvc }}</p>
            <p *ngIf="cvvHide && _cvc !== ''" class="card-secret-last">{{ _cvc | maskdata: 0 }}</p>
            <p *ngIf="_cvc === ''" style="color: rgba(0,0,0,0)">XXX</p>
          </div>
          <img class="card-square card-back-square" [src]="ccType">
                <!-- <img class="card-logo card-back-logo" src="https://www.fireeye.com/partners/strategic-technology-partners/visa-fireeye-cyber-watch-program/_jcr_content/content-par/grid_20_80_full/grid-20-left/image.img.png/1505254557388.png"> -->
        </div>
      </div>
    </div>
  </div>