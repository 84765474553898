import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-employer.module',
  templateUrl: './employer.module.component.html'
})
export class EmployerModuleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
