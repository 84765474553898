import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, EMPLOYER_PROFILE_SETTINGS_URL, CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-emp-profile',
  templateUrl: './profile.component.html',
})
export class EMPProfileComponent implements OnInit {
  pagetitle = 'Employer: Profile & Settings';
  displayDetails = true;
  displaySecurity = true;
  displayAccounts = true;
  selectedTab = 0;

  confirmationTitle = '';
  confirmationMessage = '';
  confirmationNumber = '';
  showConfirmationEmail = false;
  restart = false;
  goToPrimary = '';
  goToPrimaryUrl = '';
  openConfirmation = false;
  useEmail = CommonSettings.UseEmailForLogin;
  email = '';
  newEmailFromLogin = '';
  showEmailMismatch = false;

  baseurl = '';
  employerProfileUrl = EMPLOYER_PROFILE_SETTINGS_URL;

  @Input() passState: any;

  constructor(private router: Router, private authService: AuthService) {
    this.baseurl = CommonSettings.BaseApi;
  }

  ngOnInit() {
    switch (history.state.display) {
      case 'detail': {
        this.selectedTab = 0;
        break;
      }
      case 'security': {
        this.newEmailFromLogin = history.state.newEmail;
        this.selectedTab = 1;
        break;
      }
      case 'account': {
        this.selectedTab = 2;
        break;
      }
      default: {
        this.selectedTab = 0;
      }
    }
  }

  goToProfileHome() {
    this.router.navigate([this.employerProfileUrl]);
  }

  displayConfirmationPage(result: any) {
    if (result.success) {
      this.confirmationTitle = 'Success';
      this.confirmationMessage = result.message;
      this.confirmationNumber = result.confirmationNumber;
      this.showConfirmationEmail = result.showConfirmationEmail;
      this.restart = result.restart;
      this.goToPrimaryUrl = 'employer/home';
      this.goToPrimary = 'Home';
      this.openConfirmation = true;
      this.displayAccounts = false;
      this.displayDetails = false;
      this.displaySecurity = false;
      this.showEmailMismatch = result.showEmailMismatch;
    }
  }
}
