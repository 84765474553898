<div class="container m--full-bleed mt-2">
    <div class="row m--full-bleed justify-content-center">
      <div class="col-12 flex-input-col">
        <div *ngIf="!openConfirmation" class="form-display-card">
          <form [formGroup]="passwordFrmGp" (ngSubmit)="changePassword()">
            <mat-card class="max-w max-gs card-container">
              <mat-card-header>
                <mat-card-title>Change Password is Required</mat-card-title>
              </mat-card-header>
  
              <div class="row">
                <div class="flex-input-col col-lg-6">
                  <mat-form-field>
                    <input matInput placeholder="Current Password" [type]="currentPasswordHide ? 'password' : 'text'"
                      formControlName="currentpassword">
                    <mat-icon matSuffix (click)="toggleCurrentPasswordHide()">{{currentPasswordHide ?
                      'visibility_off'
                      :
                      'visibility'}}
                    </mat-icon>
                    <mat-error *ngIf="passwordFrmGp.get('currentpassword').hasError('required')">Required.</mat-error>
                  </mat-form-field>
                </div>
              </div>
  
  
              <div class="flex-input-col">
                <mat-form-field>
                  <input matInput placeholder="New Password" [type]="passwordHide ? 'password' : 'text'"
                    formControlName="newpassword1">
                  <mat-hint>{{passwordHint}}</mat-hint>
                  <mat-icon matSuffix (click)="togglePasswordHide()">{{passwordHide ? 'visibility_off' :
                    'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="passwordFrmGp.get('newpassword1').hasError('pattern')">
                    Must contain at least 6 letters, 2 numbers, and at least 1 special character with a maximum of 12 characters in length.
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput [errorStateMatcher]="errorMatcher" placeholder="Confirm New Password"
                    [type]="passwordConfirmHide ? 'password' : 'text'" formControlName="newpassword2">
                  <mat-icon matSuffix (click)="toggleConfirmPasswordHide()">{{passwordConfirmHide ?
                    'visibility_off' :
                    'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="passwordFrmGp.hasError('doNotMatch')">
                    Passwords do not match.
                  </mat-error>
                </mat-form-field>
              </div>
  
              <div>
                <button [disabled]="passwordFrmGp.invalid" class="btn btn-submit mt-4" mat-raised-button
                  type="submit">Save</button>
              </div>
            </mat-card>
          </form>
        </div>
      </div>
    </div>
  
  
    <epay-lib-common-confirmation *ngIf="openConfirmation" [baseurl]="baseurl" [confirmationTitle]="confirmationTitle"
      [confirmationMessage]="confirmationMessage" [confirmationNumber]="confirmationNumber" [restart]="restart"
      [goToPrimary]="goToPrimary" [goToPrimaryUrl]="goToPrimaryUrl">
    </epay-lib-common-confirmation>