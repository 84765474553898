import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-creditcard-success',
  templateUrl: './creditcard-success.component.html'
})
export class CreditCardSuccessComponent implements OnInit {
  pagetitle = "Credit Card Payment Success";
  encryptedTransactionId: string;
  baseurl = CommonSettings.BaseApi;
  clientId = CommonSettings.ClientID;

  constructor(private route: ActivatedRoute) {
    this.encryptedTransactionId = '';
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.encryptedTransactionId = params['tid'];
    });
  }

}
