import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-creditcard-cancel',
  templateUrl: './creditcard-cancel.component.html'
})
export class CreditCardCancelComponent implements OnInit {
  pagetitle = "Credit Card Payment Cancelled";

  constructor() { }

  ngOnInit(): void {
  }



}
