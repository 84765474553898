<div class="page-title-bar">
  <h1 class="page-title">
    {{pagetitle}}
  </h1>
</div>
<div class="page-content footer-gap">
  <!--Container-->
  <div class="page-container">
    <ng-content></ng-content>
  </div>
</div>
