import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-securitypolicy',
  templateUrl: './securitypolicy.component.html'
})
export class SecurityPolicyComponent implements OnInit {
  pagetitle = 'Security Measures';

  constructor() { }

  ngOnInit(): void {
  }

}
