<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main max-w" id="page-content-main">
    <div class="">

    <div *ngIf="!openConfirmation" class="stepper-panel">
      <div class="container m--full-bleed">
        <div class="row m--full-bleed">
          <div class="col-12">
            <div class="form-panel">
              <mat-stepper [orientation]="stepperOrientation" #stepper linear="true">
                <!-- 1. T&C -->
                <mat-step class="stepper-icon" [stepControl]="termsFrmGp">
                  <form [formGroup]="termsFrmGp">
                    <ng-template matStepLabel>Terms and Conditions</ng-template>
                    <div>
                      <div #cguContainer class="terms-panel" (scroll)="checkReading()" [innerHTML]="termsContent"></div>
                    </div>
                    <div class="mt-3">
                      <label class="smart-checkbox pb-2">
                        <input type="checkbox" class="form-check-input smart-checks checkmark" formControlName="accepted"
                          required>
                        <span class="ts-s pt-2">Accept Terms and Conditions</span>
                        <span class="checkmark"></span>
                        <p class="c-red1" *ngIf="isTermsSubmitted && termsFrmGp.get('accepted')?.value !== true">Please
                          accept the terms and conditions to move forward.</p>
                      </label>
                    </div>
                    <div class="flex-btn-row pt-2 pb-2">
                      <button class="btn btn-secondary" mat-button matStepperPrevious (click)="goToHome()">Back</button>
                      <button class="btn btn-primary-short" (click)="submitTerms()" mat-button matStepperNext>Next</button>
                    </div>
                  </form>
                </mat-step>
                <!-- 2. Add Payor Info -->
                <mat-step>
                  <ng-template matStepLabel>Complete Payor Information</ng-template>
                  <!-- <epay-lib-employer-payor-validation [baseurl]="baseurl" [clientName]="clientName"
                    [validationType]="validationType" [isEditPayment]="iseditccPayment"
                    [editPayorCollection]="paymentCollection"
                    (validCreditCardPaymentEmitter)="validCCPaymentEmitted($event)">
                  </epay-lib-employer-payor-validation> -->

                  
                  <form [formGroup]="payorFrmGp" (ngSubmit)="addSavePayorCollection()">

                      <p class="mb-1">
                          Please Import an Excel file or enter the payor details individually below. </p>
                      <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item" (click)="resetToggle()">
                              <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                                      style="font-weight: bold">
                                      Import Excel File
                                  </button>
                              </h2>
                              <div id="flush-collapseOne" class="accordion-collapse collapse"
                                  [ngClass]="{'closed': toggleEditPayor, 'show': !toggleEditPayor && isEditPayor}"
                                  aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                      <div class="file-import-block mt-2 mb-2 mx-2">
                                          <div class="flex-input-col pt-2 pb-2">
                                              <div class="row">
                                                  <p>Microsoft® Excel must be installed on your device to upload or
                                                      download files.</p>
                                                  <div class="col-12 col-lg-5 custom-file">
                                                      <input id="impFile" #inputFile type="file" class="custom-file-input mb-3"
                                                          multiple="false" accept=".xls,.xlsx" (change)="onFileChange($event)"
                                                          (click)="resetFile()" formControlName="filename">
                                                      <label class="custom-file-label" for="impFile"><i *ngIf="!fileImported"
                                                              class="fa fa-search"></i>{{payorFrmGp.get('filename')!.value}}</label>
                                                      <p class="c-grey6 ts-xs">{{fileImportHint}}</p>
                                                  </div>
                                                  <div *ngIf="isScheduledPaymentValidation" class="col-12 col-lg-7">
                                                      <a download="ACHD Payor Example" target="_blank"
                                                          href="/assets/forms/employerACHDPayorList.xlsx">
                                                          Sample File
                                                          <i class="fa fa-download" aria-hidden="true"></i>
                                                      </a>
                                                  </div>
                                                  <div *ngIf="isCreditCardValidation" class="col-12 col-lg-7">
                                                      <a download="Credit Card Payor Example" target="_blank"
                                                          href="/assets/forms/employerCCPayorList.xlsx">
                                                          Sample File
                                                          <i class="fa fa-download" aria-hidden="true"></i>
                                                      </a>
                                                  </div>
                                              </div>
                                          </div>
                                          <div *ngIf="!isFileValid && fileImported" class="c-red1 ts-s mb-2">
                                              <i matPrefix class="fa fa-exclamation-triangle pa-1"></i>{{fileErrorMessage}}
                                          </div>
                                      </div>
                  
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingTwo">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                                      style="font-weight: bold">
                                      Enter Payor Detail
                                  </button>
                              </h2>
                              <div id="flush-collapseTwo" [ngClass]="{'closed': !toggleEditPayor, 'show': toggleEditPayor}"
                                  class="accordion-collapse collapse closed" aria-labelledby="flush-headingTwo"
                                  data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                      <div class="payor-info-block">
                                          <div formGroupName="collectionFrmGp">
                                              <div class="flex-input-col pt-1 pb-1">
                                                  <mat-form-field>
                                                      <input #LastName matInput placeholder="Last Name" formControlName="lastname">
                                                  </mat-form-field>
                                                  <mat-form-field>
                                                      <input [type]="ssnHide ? 'password' : 'text'" matInput ngxInputRegulate
                                                          ngxir-pattern="^[0-9 -]*$" placeholder="Social Security #"
                                                          formControlName="ssn">
                                                      <mat-icon matSuffix (click)="toggleSSNHide()">{{ssnHide ? 'visibility_off' :
                                                          'visibility'}}
                                                      </mat-icon>
                                                      <mat-hint>xxx-xx-xxxx or xxxxxxxxx</mat-hint>
                                                      <mat-error>Invalid Format.</mat-error>
                                                  </mat-form-field>
                                              </div>
                                              <div class="flex-input-col pt-1 pb-1">
                                                  <mat-form-field *ngIf="validateCaseNumber">
                                                      <input matInput placeholder="{{secondaryIdAlias}}" formControlName="casenumber">
                                                      <mat-error>Required</mat-error>
                                                  </mat-form-field>
                                                  <mat-form-field>
                                                      <input type="number" matInput placeholder="Amount" formControlName="amount">
                                                      <mat-error>Invalid Format</mat-error>
                                                  </mat-form-field>
                                              </div>
                                          </div>
                                          <div class="flex-btn-row">
                                              <button *ngIf="!isEditPayor" class="btn btn-submit" type="submit">Add</button>
                                              <button *ngIf="isEditPayor" class="btn btn-submit" type="submit">Save</button>
                                          </div>
                                      </div>
                  
                                  </div>
                              </div>
                          </div>
                      </div>
                      <hr>
                      <div class="mb-2 load-payors">
                          <div class="flex-input-col pt-2 pb-2">
                              <label class="smart-checkbox">
                                  <input type="checkbox" [checked]="previousPayorsChecked" class="form-check-input smart-checks checkmark"
                                      label="get previous payment payor" (change)="getPreviousPaymentPayors($event)">
                                  <span class="checkmark"></span>
                                  Get Previous Payment Payors
                              </label>
                          </div>
                          <div class="flex-input-col pt-2 pb-2">
                              <label class="smart-checkbox mb-4">
                                  <input type="checkbox" [checked]="allPayorsChecked" class="form-check-input smart-checks checkmark"
                                      label="get all payors" (change)="getAllPreviousPayors($event)">
                                  <span class="checkmark"></span>
                                  Get ALL Previous Payors
                              </label>
                              <span *ngIf="isLoadingPreviousPayors">
                                  <i class="fa fa-refresh fa-spin fa-2x fa-fw ts-l"></i>
                                  Loading Previous Payors...
                              </span>
                              <span *ngIf="noPreviousPayors" class="c-red1">
                                  <i class="fa fa-exclamation-triangle fa-2x fa-fw ts-l"></i>
                                  {{noPreviousPayors}}
                              </span>
                          </div>
                      </div>
                      <div *ngIf="payorCollection?.length">
                          <hr>
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <h4>Payors List &nbsp;&nbsp;
                                      <span class=" ts-s c-red1" *ngIf="numInvalid && numWarning">{{numInvalid}}
                                          Invalid payor(s) found. {{numWarning}} Payor name change(s) completed.</span>
                                      <span class="ts-s c-red1" *ngIf="numInvalid && numWarning === 0">{{numInvalid}}
                                          Invalid payor(s) found.</span>
                                      <span class="ts-s c-gold0" *ngIf="numWarning && numInvalid === 0">{{numWarning}} Payor name
                                          change(s) completed.</span>
                                      <span class="ts-s c-green0" *ngIf="payorsValid">All Payors are VALID!
                                      </span>
                                      <span *ngIf="isValdiatingPayment" matSuffix><i
                                              class="fa fa-refresh fa-spin fa-2x fa-fw ts-l"></i></span>
                                  </h4>
                              </div>
                              <div class="col-12 col-md-6 ts-s">
                                  <div class="flex-responsive-justify">
                                      <strong class="mr-2">
                                          Selected:
                                          {{getNumberOfPayments()}}/{{payorCollection.length}}</strong>
                                      <strong>
                                          Amount:
                                          <span
                                              [ngStyle]="{'color': ((totalAmtOfPayments > 9999.99) && validateAttemptMade) ? 'red' : 'black'}">
                                              {{totalAmtOfPayments|currency}}</span>
                                      </strong>
                                  </div>
                              </div>
                          </div>

                          <div class="paymentRecordPanel">
                              <table class="table" id="paymentRecords">
                                  <thead>
                                      <tr>
                                          <th>
                                              <label class="smart-checkbox">
                                                  <input type="checkbox" class="form-check-input smart-checks checkmark" name="Checks"
                                                      [checked]="allChecked" (change)='selectAllPayments($event)'>
                                                  <span class="checkmark"></span>
                                              </label>
                                          </th>
                                          <th>Name</th>
                                          <th>SSN</th>
                                          <th *ngIf="validateCaseNumber">{{secondaryIdAlias}}</th>
                                          <th>Amount</th>
                                          <th>Action</th>
                                          <th>Validation</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let payor of payorCollection; let i = index" [ngClass]="{'unverifiedPayment' : payor.isSelected && !payor.isValidated && !payor.hasError,
                                              'verifiedPayment' : payor.isSelected && payor.isValidated && !payor.hasError,
                                              'warningPayment' : payor.isSelected && payor.hasWarning && !payor.hasError,
                                              'errorPayment' : payor.isSelected && payor.hasError,
                                              'editPayment' : payor.hadEdit}">
                                          <td>
                                              <label class="smart-checkbox">
                                                  <input type="checkbox" id='chkbox{{i}}' name='selectAccount{{i}}'
                                                      (change)="addRemovePayor($event, i)" [checked]=payor.isSelected>
                                                  <span class="checkmark"></span>
                                              </label>
                                          </td>
                                          <td>{{payor.lastName | uppercase}}</td>
                                          <td>{{payor.ssn | maskdata: 4}}</td>
                                          <td *ngIf="validateCaseNumber">{{payor.caseNumber}}</td>
                                          <td>{{payor.amount | currency:'USD':'symbol':'.2-5'}}</td>
                                          <td>
                                              <span matTooltip="EDIT PAYOR" matTooltipClass="tooltip-md" (click)="editPayor(payor, i)"
                                                  class="fa fa-pencil-square-o fa-md-blue">
                                              </span>
                                              <span matTooltip="DELETE PAYOR" matTooltipClass="tooltip-md" (click)="deletePayor(i)"
                                                  class="fa fa-trash-o fa-md-blue ml-2">
                                              </span>
                                          </td>
                                          <td>
                                              <span *ngIf="payor.isSelected && payor.isValidated && !payor.hasError && !payor.hasWarning"
                                                  class="c-green0">
                                                  <i class="fa fa-check fa-md-green" matTooltip="VALID PAYOR"
                                                      matTooltipClass="tooltip-md"></i>
                                                  Valid Payor
                                              </span>
                                              <span *ngIf="payor.isSelected && payor.hasError" class="c-red1">
                                                  <i matPrefix class="fa fa-times-circle fa-md-red" matTooltip="INVALID PAYOR"
                                                      matTooltipClass="tooltip-md"></i>
                                                  {{payor.errorMessage}}
                                              </span>
                                              <span *ngIf="payor.isSelected && payor.hasWarning && !payor.hasError" class="c-gold0">
                                                  <i matPrefix class="fa fa-exclamation-triangle fa-md-yellow" matTooltip="WARNING"
                                                      matTooltipClass="tooltip-md"></i>
                                                  {{payor.errorMessage}}
                                              </span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <p *ngIf="!atLeastOnePayorSelected && validateAttemptMade" class="c-red1 mt-1">
                              <i matPrefix class="fa fa-exclamation-triangle fa-md-red"></i>
                              Please select at least one Payor to Validate.
                          </p>
                          <p *ngIf="(totalAmtOfPayments > 9999.99) && validateAttemptMade" class="c-red1 mt-1">
                              <i matPrefix class="fa fa-exclamation-triangle fa-md-red"></i>
                              Payment amount cannot exceed $9999.99.
                          </p>
                          <p *ngIf="(totalAmtOfPayments < 1.00) && validateAttemptMade" class="c-red1 mt-1">
                              <i matPrefix class="fa fa-exclamation-triangle fa-md-red"></i>
                              Payment amount must exceed $0.99.
                          </p>
                          <p *ngIf="nameChangeOccured && validateAttemptMade" class="c-gold0 mt-1">
                              <i matPrefix class="fa fa-exclamation-triangle fa-md-yellow"></i>
                              Please Validate again to confirm name change.
                          </p>
                      </div>
                  </form>

                  <div class="flex-btn-row pt-2 pb-2">
                      <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                  
                      <button *ngIf="!payorsValid" type="button" [disabled]="payorCollection.length === 0" class="btn btn-submit"
                          mat-button (click)="validatePayors()">
                          Validate Payors
                      </button>
                      <button *ngIf="payorsValid" [disabled]="totalAmtOfPayments >= 9999/.99" class="btn btn-primary-short" mat-button
                          (click)="emitValidPayorPerValidation()" matStepperNext>Next</button>
                  </div>
                  
                </mat-step>

                <!-- 3. Payment Information -->
                <mat-step [stepControl]="ccFrmGp">
                  <ng-template matStepLabel>Complete Payment Information</ng-template>

                    <div class="containr">
                      <form [formGroup]="ccFrmGp">

                        <div class="accordion mb-3" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style="text-decoration: none;">
                                <h4 [style.color]="!billingSectionIncomplete ? 'black' : 'red'">
                                  Billing Information
                                  <span *ngIf="billingSectionIncomplete" class="fa fa-exclamation-triangle" matTooltip="Information Needed"
                                    matTooltipClass="tooltip-md">
                                  </span>
                                </h4>
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <div class="flex-input-col pt-2 pb-2">
                                  <!-- <mat-form-field>
                                    <input matInput placeholder="First Name" formControlName="ccFirstName" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field> -->
          
                                  <mat-form-field>
                                    <input matInput placeholder="First Name" formControlName="ccFirstName" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>

                                  <mat-form-field>
                                    <input matInput placeholder="Last Name" formControlName="ccLastName" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
                                </div>
          
                                <div class="flex-input-col pt-2 pb-2">
                                  <mat-form-field>
                                    <input matInput placeholder="Address" formControlName="address1" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
          
                                  <mat-form-field>
                                    <input matInput placeholder="Line 2" formControlName="address2">
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
                                </div>
          
                                <div class="flex-input-col pt-2 pb-2">
                                  <mat-form-field>
                                    <input matInput placeholder="City" formControlName="city" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
          
                                  <mat-form-field>
                                    <mat-select matInput disableOptionCentering panelClass="matSelectClass"
                                      [placeholder]="this.ccFrmGp.get('country')!.value.trim() === 'CA' ? 'Province' : 'State'"
                                      formControlName="state" required>
                                      <mat-option *ngFor="let state of states" [value]="state.code">
                                        {{state.name}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
          
                                  <mat-form-field>
                                    <input matInput placeholder="Zip" formControlName="zip5" required>
                                    <mat-error>Required</mat-error>
                                  </mat-form-field>
                                </div>
          
                                <div class="flex-input-col pt-2 pb-2">
                                  <mat-form-field>
                                    <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="Country"
                                      formControlName="country" [(value)]="selectedCountry"
                                      (selectionChange)="onCountrySelectionChange($event)" required>
                                      <mat-option *ngFor="let country of countries" [value]="country.country">
                                        {{country.countryName}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
          
                                <div class="flex-input-col pt-2 pb-2"> 
                                  <mat-form-field formGroupName="phoneGp">
                                    <input matInput placeholder="Phone" formControlName="phoneNumber" [mask]="phoneMask" required>
                                    <span matPrefix>+{{ccFrmGp.get('phoneGp.countryCode')!.value}}&nbsp;&nbsp;</span>
                                    <mat-hint>Ex. {{phoneExample}}</mat-hint>
                                    <mat-error *ngIf="!ccFrmGp.get('phoneGp.isValid')?.value">Invalid Format</mat-error>
                                    <mat-error *ngIf="ccFrmGp.get('phoneGp.phoneNumber')!.hasError('required')">Required</mat-error>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Phone Ext" formControlName="phoneExt" autocomplete="off">
                                  </mat-form-field>

                                  <mat-form-field>
                                    <input matInput placeholder="Email" formControlName="email">
                                    <!-- <mat-error>{{emailErrorMessage}}</mat-error> -->
                                  </mat-form-field>
                                </div>
          
                                <!-- <div class="flex-input-col pt-2 pb-2"> 
                                  <mat-form-field>
                                    <input matInput placeholder="Email" formControlName="email">
                                    <!-- <mat-error>{{emailErrorMessage}}</mat-error> 
                                  </mat-form-field>
                                </div> 
                              -->
                              </div>

                              <!-- <div class="position-relative">
                                <div class="position-absolute end-0">
                                  <button class="btn btn-primary-short" mat-button>Continue</button>
                                </div>
                              </div> -->

                            </div>
                          </div>
    
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="text-decoration: none;">
                                <h4 [style.color]="!ccSectionIncomplete ? 'black' : 'red'">
                                  Credit Card Information
                                  <span *ngIf="ccSectionIncomplete" class="fa fa-exclamation-triangle" matTooltip="Information Needed"
                                    matTooltipClass="tooltip-md">
                                  </span>
                                </h4>
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">

                                <div class="flex-input-col pt-2 pb-2 mb-3"> 
                                  <div class="col-4">
                                    <button class="interactive-cc" style="background: none; border: none; cursor: default; margin-left: -20px;">
                                      <interactive-credit-card-emp
                                      [no]="ccInfo.cardNumber"
                                      [cvc]="ccInfo.cvc"
                                      [expMonth]="expMonthCtrl.value?.length === 2 && expMonthCtrl.valid  ? ccInfo.expMonth : '--'"
                                      [expYear]="expYearCtrl.value?.length === 4 && expYearCtrl.valid  ? ccInfo.expYear : '----'"
                                      [flip]="flipCard" 
                                      [ccType]="ccImgURL"
                                      [cvvHide]="cvvHide"
                                      [ccHide]="ccHide"
                                      [holder]="ccFrmGp.get('ccFirstName')?.value && ccFrmGp.get('ccLastName')?.value ? ccFrmGp.get('ccFirstName')?.value[0] + '. ' + ccFrmGp.get('ccLastName')?.value : ''"
                                      >
                                      </interactive-credit-card-emp>
                                    </button>
                                  </div>


                                  <div class="col-8">
                                    <div class="row"> 
                                      <div class="flex-input-col pt-2 pb-2">
                                        <mat-form-field>
                                          <mat-label>Credit Card Type</mat-label>
                                          <mat-select formControlName="ccType" [(ngModel)]="defaultCC"
                                          (selectionChange)="creditCardTypeOnChange($event)" required>
                                            <!-- <option value="" selected disabled></option> -->
                                            <!-- <option value="amex">Amex</option>
                                            <option value="discover">Discover</option> -->
                                            <mat-option *ngFor="let type of creditcardType" [value]="type.value" selected>{{type.viewValue}}</mat-option>
                                          </mat-select>
                                        </mat-form-field>
      
                                      <!-- CC # -->
                                      <mat-form-field>
                                        <input matInput placeholder="Credit Card #" formControlName="ccNumber"
                                        [type]="ccHide ? 'password' : 'text'"
                                        [pattern]="ccRegex" [mask]="ccMask" [(ngModel)]="ccInfo.cardNumber"
                                        (focus)="rotateCard('other')">
                                        <mat-icon matSuffix (click)="toggleCCHide()">
                                          {{ ccHide ? 'visibility_off' : 'visibility' }}
                                        </mat-icon>
                                        <mat-error *ngIf="ccFrmGp.get('ccNumber')!.hasError('required')">Required</mat-error>
                                        <mat-error *ngIf="ccFrmGp.get('ccNumber')!.hasError('pattern')">Invalid Card Number</mat-error>
                                      </mat-form-field>
                                      </div>
                                    </div>
  
                                    <div class="row"> 
                                      <div class="flex-input-col pt-2 pb-2">
                                        <!-- Epiry Month -->
                                        <!-- <mat-form-field>
                                          <mat-label>MM</mat-label>
                                          <mat-select formControlName="ccExpiryMonth" [(ngModel)]="ccInfo.expMonth"
                                          (focus)="rotateCard('other')" required>
                                            <mat-option value="" selected disabled></mat-option>
                                            <mat-option *ngFor="let month of months" [value]="month.value">{{month.value}}</mat-option>
                                          </mat-select>
                                        </mat-form-field> -->

                                        <mat-form-field>
                                          <mat-label>MM</mat-label>
                                            <input
                                            type="text"
                                            required
                                            placeholder=""
                                            aria-label="MM"
                                            matInput
                                            maxlength="2"
                                            [(ngModel)]="ccInfo.expMonth"
                                            [formControl]="expMonthCtrl"
                                            formControlName="ccExpiryMonth"
                                            [matAutocomplete]="expMonthAutocomplete"
                                            (focus)="rotateCard('other')"
                                            required
                                            />
                                            <mat-error *ngIf="this.ccFrmGp.get('ccExpiryMonth')!.hasError('required')"
                                            >Required</mat-error
                                            >
                                            <mat-error *ngIf="this.ccFrmGp.get('ccExpiryMonth')!.hasError('invalidExpMonth')"
                                              >Invalid expiry month.</mat-error
                                            >

                                            <mat-autocomplete autoActiveFirstOption #expMonthAutocomplete="matAutocomplete">
                                            <mat-option
                                              *ngFor="let month of filteredExpMonths$ | async"
                                              [value]="month"
                                            >
                                              {{month}}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>


                                        <!-- Epiry Year -->
                                        <!-- <mat-form-field>
                                          <mat-label>YYYY</mat-label>
                                          <mat-select formControlName="ccExpiryYear" [(ngModel)]="ccInfo.expYear" 
                                          (focus)="rotateCard('other')" required>
                                            <mat-option value="" selected disabled></mat-option>
                                            <mat-option *ngFor="let year of years" [value]="year.value">{{year.value}}</mat-option>
                                          </mat-select>
                                        </mat-form-field> -->
                                        <mat-form-field>
                                          <mat-label>YYYY</mat-label>
                                            <input
                                            type="text"
                                            required
                                            placeholder=""
                                            aria-label="YYYY"
                                            matInput
                                            maxlength="4"
                                            [(ngModel)]="ccInfo.expYear"
                                            [formControl]="expYearCtrl"
                                            formControlName="ccExpiryYear"
                                            [matAutocomplete]="expYearAutocomplete"
                                            (focus)="rotateCard('other')"
                                            required
                                            />
                                            <mat-error *ngIf="this.ccFrmGp.get('ccExpiryYear')!.hasError('required')"
                                            >Required</mat-error
                                            >
                                            <mat-error *ngIf="this.ccFrmGp.get('ccExpiryYear')!.hasError('invalidExpYear')"
                                              >Invalid expiry year.</mat-error
                                            >

                                            <mat-autocomplete autoActiveFirstOption #expYearAutocomplete="matAutocomplete">
                                            <mat-option
                                              *ngFor="let year of filteredExpYears$ | async"
                                              [value]="year"
                                            >
                                              {{year}}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>

                                        <!-- CVV -->
                                        <mat-form-field>
                                          <input matInput placeholder="3-Digit Security Code" (focus)="rotateCard('cvv')"
                                          [type]="cvvHide ? 'password' : 'text'" formControlName="cvv" mask="000" [(ngModel)]="ccInfo.cvc"
                                          style="cursor:text">
                                          <mat-icon matSuffix (click)="toggleCVVHide()">
                                            {{ cvvHide ? 'visibility_off' : 'visibility' }}
                                          </mat-icon>
                                          <mat-error *ngIf="this.ccFrmGp.get('cvv')?.hasError('required')">Required</mat-error>
                                          <mat-error *ngIf="this.ccFrmGp.get('cvv')?.hasError('minlength')">CVV/CVC must be 3 digits in length.</mat-error>
                                        </mat-form-field>
                                      </div>

                                      <mat-error *ngIf="this.ccExpired && this.ccFrmGp.get('ccExpiryMonth')!.value && this.ccFrmGp.get('ccExpiryYear')!.value">Credit Card Expired</mat-error>

                                    </div>
                                  </div>
                                </div>
                                <span>
                                  <strong>Note:</strong> In order to process a payment with a MasterCard® or Visa® credit card, we need the CVV2 or CVC2
                                  security code. This 3 digit code is <u>found on the back of your credit card</u>. For instructions
                                  on how to find this code, please click <u (click)="securityCodeHelp()" style="color: #387aac; font-weight: bold">here</u>.
                                </span>

                                <br><br>

                                <span style="font-style:italic">
                                  To ensure a secure credit card payment, we provide 128 bit SSL encryption. To take advantage of this 
                                  security, please ensure that your browser is configured for 128 bit encryption.
                                </span>

                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="flex-btn-row pt-2 pb-2">
                          <button class="btn btn-secondary" mat-button matStepperPrevious
                          >Back</button>
                          <button class="btn btn-primary-short" mat-button
                            matStepperNext (click)="createCCPaymentAttempt()">Next</button>
                        </div>
                      </form>
                    </div>

                  </mat-step>


                <!-- 4. Review & Submit -->
                <mat-step>
                  <ng-template matStepLabel>Submit Your Payment</ng-template>
                  <!-- <epay-lib-ncp-creditcard-submit
                    [baseurl]="baseurl"
                    [creditCardVendorId]="creditCardVendorId"
                    [creditCardDisclaimer]="creditCardDisclaimer"
                    [creditCardPayment]="creditCardPayment"
                    >
                  </epay-lib-ncp-creditcard-submit> -->

                  <div class="form-display-card">
                    <mat-card class='mat-card-display mb-3'>
                      <mat-card-header>
                        <mat-card-title class="justify-content-center">
                          <h2 class="mat-card-title align-items-start">
                            <span class="fa fa-credit-card fa-md-black dib mr-2"></span> Review Credit
                            Card Payment Information
                          </h2>
                        </mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <table class="table">
                          <thead>
                            <tr>
                              <th *ngFor="let col of paymentheader">{{col}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let participant of creditCardPayment!.participantCollection">
                              <td>{{participant.lastName}}</td>
                              <td>{{participant.ssn | maskdata}}</td>
                              <td>{{participant.caseNumber}}</td>
                              <td>{{participant.amount | currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="col mb-3">
                          <dl class="account-details-sum">
                            <div class="account-sum-row">
                              <dt class="account-dt">Payment Amount</dt>
                              <dd class="account-dt-info">{{creditCardPayment!.amount | currency}}</dd>
                            </div>
                            <!-- <div class="account-sum-row">
                              <dt class="account-dt">Service Fee %</dt>
                              <dd class="account-dt-info">{{creditCardPayment!.feePercentage | percent: '.2'}}</dd>
                            </div>
                            <div class="account-sum-row">
                              <dt class="account-dt">Service Fee Amount</dt>
                              <dd class="account-dt-info">{{creditCardPayment!.fee | currency}}</dd>
                            </div> -->
                            <div class="account-sum-row">
                              <dt class="account-dt">Total Credit Card Amount</dt>
                              <dd class="account-dt-info">{{creditCardPayment!.totalAmount | currency}}</dd>
                            </div>
                          </dl>
                        </div>
                        <div class="pt-2 pb-2">
                          <div class="c-red1 ts-s" [innerHTML]="creditCardDisclaimer"></div>
                        </div>
                      </mat-card-content>
                    
                    </mat-card>
                    <div class="flex-btn-row pt-2 pb-2">
                      <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                      <button class="btn btn-primary-short" (click)="processCreditCard()">{{createPaymentButtonText}}</button>
                    </div>
                  </div>

                </mat-step>

              </mat-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</epay-page-content>
