import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emp-security-code-help',
  templateUrl: './empsecurity-code-help.component.html'
})
export class EMPSecurityCodeHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
