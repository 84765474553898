import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../../services/employer.service';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-payment',
  templateUrl: './payment.component.html',
})
export class EMPPaymentComponent implements OnInit {
  pagetitle = 'Employer: Create ACHD Debit Payment';
  baseurl = '';
  clientName = '';
  termsContent = '';
  validateCaseNumber: boolean = false;
  saveEmployees: boolean = false;

  constructor(private employerService: EmployerService) {
    this.baseurl = CommonSettings.BaseApi;
    this.clientName = CommonSettings.ClientName;
    this.validateCaseNumber = CommonSettings.EmployerUseCaseNumberForPayments;
    this.saveEmployees = CommonSettings.SaveEmployeesForPayments;
  }

  ngOnInit() {
    this.employerService.getEmployerTerms().subscribe((result) => {
      this.termsContent = result;
    });
  }
}
