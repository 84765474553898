import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/common/components/home/home.component';
import { LogoutWarningComponent } from './features/common/components/logoutwarning/logoutwarning.component';
import { ChangePasswordComponent } from './features/common/components/changepassword/changepassword.component';
import { PrivacyPolicyComponent } from './features/common/components/privacypolicy/privacypolicy.component';
import { FaqComponent } from './features/common/components/faq/faq.component';
import { SecurityPolicyComponent } from './features/common/components/securitypolicy/securitypolicy.component';
import { ContactComponent } from './features/common/components/contact/contact.component';
import { TermsAndConditionsComponent } from './features/common/components/termsandconditions/termsandconditions.component';
import { WebAccessibilityComponent } from './features/common/components/webaccessibility/webaccessibility.component';
import { HowTosComponent } from './features/common/components/howtos/howtos.component';
import { RegisterComponent } from './features/common/components/register/register.component';
import { CreditCardComponent } from './features/common/components/creditcard/creditcard.component';
import { LoginComponent } from './features/common/components/login/login.component';
import { ForgotPasswordComponent } from './features/common/components/forgotpassword/forgotpassword.component';
import { ForgotUsernameComponent } from './features/common/components/forgotusername/forgotusername.component';
import { CreditCardSuccessComponent } from '../app/features/common/components/creditcard/creditcard-success/creditcard-success.component';
import { CreditCardCancelComponent } from '../app/features/common/components/creditcard/creditcard-cancel/creditcard-cancel.component';
import { EmployerFAQComponent } from './features/common/components/faq/employer-faq/employer-faq.component';
import { NcpFAQComponent } from './features/common/components/faq/ncp-faq/ncp-faq.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: 'employer',
    loadChildren: () =>
      import('./features/employer/employer.module').then(
        (m) => m.EmployerModule
      ),
  },
  {
    path: 'ncp',
    loadChildren: () =>
      import('./features/ncp/ncp.module').then((m) => m.NcpModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'forgotusername', component: ForgotUsernameComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'creditcard', component: CreditCardComponent },
  { path: 'creditcardsuccess', component: CreditCardSuccessComponent },
  { path: 'creditcardcancel', component: CreditCardCancelComponent },
  { path: 'logoutwarning', component: LogoutWarningComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'securitypolicy', component: SecurityPolicyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'demo', component: HowTosComponent },
  { path: 'termsandconditions', component: TermsAndConditionsComponent },
  { path: 'webaccessibility', component: WebAccessibilityComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'employerFAQ', component: EmployerFAQComponent },
  { path: 'ncpFAQ', component: NcpFAQComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
