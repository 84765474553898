import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IChallengeQuestion, ErrorHandlerService, RegisterLibraryService } from 'epay-library';
import { IEmpLogin } from 'epay-library/lib/features/employer/models/user/employerLogin';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password1Ctrl = c.get('password1');
  const password2Ctrl = c.get('password2');
  if (password1Ctrl === null || password2Ctrl === null) {
    return null;
  }
  if (password1Ctrl.pristine || password2Ctrl.pristine) {
    return null;
  }
  if (password1Ctrl.value === password2Ctrl.value) {
    return null;
  }
  return { doNotMatch: true };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (control) {
      return (control.dirty || control.touched) && control.parent!.invalid;
    }
    return false;
  }
}

@Component({
  selector: 'employer-secondary-registration',
  templateUrl: './empsecondary-registration.component.html'
})
export class EmployerSecondaryRegistrationComponent implements OnInit {
  @Input() baseurl = '';
  @Input() userEmailForLogin = false;

  errorMatcher = new CrossFieldErrorMatcher();
  loginFrmGp: FormGroup = new FormGroup({});
  emplogin: IEmpLogin = {} as IEmpLogin;
  questions: IChallengeQuestion[] = [];
  selectedChallengeQuestion = '';
  errorMessage = '';
  loginErrorMessage = '';
  loginSuccessMessage = '';
  isCheckingLogin = false;
  isValidLogin = false;
  passwordHide = true;
  confirmpasswordHide = true;
  passwordErrorMessage = '';
  selectedUsername = '';

  confirmationTitle = '';
  confirmationMessage = '';
  confirmationEmailAddress = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';
  showConfirmationEmail = false;
  openConfirmation = true;

  loginPattern = /^[a-zA-Z0-9.@]*$/;
  // loginPattern =
  //   /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;

  passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  loginHintMessage =
    'Must contain at least 8 characters. Alphanumerical values and email format are accepted.';

  passwordHintMessage = 'Must contain at least 6 letters, 2 numbers, and at least one special character with a maximum of 12 characters.';

  validationLoginIdMessages: { [key: string]: string } = {
    required: 'Username is required.',
    pattern:
      'Invalid Username. Must contain only alphanumerical values with the exception of email format.',
    minlength: 'Invalid Username.  Must contain at least 8 characters.',
    isNotReady: 'Username is not available for registration.',
    isDuplicate: 'Username is not available for use.',
    isExpired: 'Username has expired.  Contact account manager.',
    isSameAsPassword: 'Invalid Username.  Username cannot be same as password.',
  };

  // validationEmailLoginMessages: { [key: string]: string } = {
  //   required: 'Username is required.',
  //   pattern: 'Invalid username.  Must be in email format.',
  //   isNotReady: 'Username is not available for registation.',
  //   isExpired: 'Username has expired.  Contact account manager.',
  // };

  validationPasswordMessages: { [key: string]: string } = {
    required: 'Password is required.',
    pattern:
      'Invalid Password.  Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.',
    isSameAsLogin: 'Invalid Password.  Password cannot be same as username.',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private registerService: RegisterLibraryService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    // if (!this.userEmailForLogin) {
    //   this.loginHintMessage =
    //     'Must contain at least 6 letters and 2 numbers with no special characters.';
    // } else {
    //   this.loginHintMessage =
    //     'Your username is the email address used for initial registration.';
    // }
    this.loadFormGroups();
    this.getChallengeQuestions();
    const loginCtrl= this.loginFrmGp.get('loginId');
    const passwrd1Ctrl = this.loginFrmGp.get('passwordGp.password1');

    this.openConfirmation = false;

    loginCtrl!.valueChanges.pipe(debounceTime(1000)).subscribe((loginId) => {
      if (
        (loginCtrl!.touched || loginCtrl!.dirty) &&
        loginCtrl!.errors
      ) {
        // if (this.userEmailForLogin) {
        //   this.loginErrorMessage = Object.keys(loginCtrl!.errors)
        //     .map((key) => this.validationEmailLoginMessages[key])
        //     .join(' ');
        // } else {
          this.loginErrorMessage = Object.keys(loginCtrl!.errors)
            .map((key) => this.validationLoginIdMessages[key])
            .join(' ');
        // }
        this.isValidLogin = false;
      } else {
        this.isCheckingLogin = true;
        this.registerService
          .searchPendingRegistration(this.baseurl, loginId)
          .subscribe({
            next: (result: any) => {
              this.setLoginMessage(loginCtrl!, result, loginId);
            },
            error: (error: any) => {
              this.logError(error);
              this.handleError(error.status);
            },
          });
      }
    });

    passwrd1Ctrl!.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((passwrd) => this.setPasswordMessage(passwrd1Ctrl!, passwrd));
  }

  completeRegistration() {
    if (this.loginFrmGp.valid) {
      if (this.loginFrmGp.dirty) {
        const loginData = {
          loginId: this.loginFrmGp.get('loginId')!.value,
          password: this.loginFrmGp.get('passwordGp.password1')!.value,
          confirmPassword: this.loginFrmGp.get('passwordGp.password2')!.value,
          challengeQuestion: this.loginFrmGp.get('challengeQuestion')!.value,
          challengeAnswer: this.loginFrmGp.get('challengeAnswer')!.value,
        };

        this.registerService
          .completeSecondaryRegistration(this.baseurl, loginData)
          .subscribe({
            next: (result: any) => {
              this.confirmationTitle = 'Success';
              this.confirmationMessage =
                'Account for ' +
                result.firstName +
                ' ' +
                result.lastName +
                ' has been created.';
              this.confirmationEmailAddress = result.email;
              this.confirmationNumber = result.confirmationNumber;
              this.goToPrimaryUrl = 'login';
              this.goToPrimary = 'Login';
              this.showConfirmationEmail = true;
              this.openConfirmation = true;
            },
            error: (error: any) => {
              this.openConfirmation = false;
              this.logError(error);
              this.handleError(error.status);
            },
          });
      }
    }
  }

  setLoginMessage(loginCtrl: AbstractControl, result: any, loginId: string) {
    this.loginErrorMessage = '';
    this.loginSuccessMessage = '';
    this.isValidLogin = false;

    if (loginCtrl.touched || loginCtrl.dirty) {
      if (result.isPending && !result.isExpired) {
        this.isValidLogin = true;
      } else if (result.isExpired) {
        loginCtrl.setErrors({ isExpired: true });
      } else if (!result.isPending) {
        loginCtrl.setErrors({ isNotReady: true });
      }

      if (this.isValidLogin) {
        this.loginSuccessMessage = 'Complete login information below!';
        this.selectedUsername = loginId;
        this.isCheckingLogin = false;
      } else {
        // if (this.userEmailForLogin) {
        //   this.loginErrorMessage = Object.keys(loginCtrl.errors!)
        //     .map((key) => this.validationEmailLoginMessages[key])
        //     .join(' ');
        // } else {
          this.loginErrorMessage = Object.keys(loginCtrl.errors!)
            .map((key) => {
              //console.log("KEY: ", key)
              this.validationLoginIdMessages[key]
            })
            .join(' ');
        // }
      }

      this.isCheckingLogin = false;
    }
  }

  setPasswordMessage(passwordControl: AbstractControl, passwrd: any): void {
    this.passwordErrorMessage = '';
    if (passwordControl.touched || passwordControl.dirty) {
      if (!passwordControl.errors) {
        if (this.selectedUsername === passwrd) {
          passwordControl.setErrors({ isSameAsLogin: true });
          this.passwordErrorMessage = Object.keys(passwordControl.errors!)
            .map((key) => this.validationPasswordMessages[key])
            .join(' ');
        }
      } else {
        this.passwordErrorMessage = Object.keys(passwordControl.errors)
          .map((key) => this.validationPasswordMessages[key])
          .join(' ');
      }
    }
  }

  resetLoginCheck() {
    this.isValidLogin = false;
    this.isCheckingLogin = false;
    this.loginSuccessMessage = '';
    this.loginErrorMessage = '';
    this.selectedUsername = '';
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  onSaveComplete() {
    this.loginFrmGp.reset();
    this.router.navigate(['login']);
  }

  loadFormGroups() {
    this.loginFrmGp = this.formbuilder.group({
      loginId: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          //Validators.maxLength(12),
          Validators.pattern(this.loginPattern),
        ],
      ],
      userName: '',
      passwordGp: this.formbuilder.group(
        {
          password1: [
            '',
            [Validators.required, Validators.pattern(this.passwordPattern)],
          ],
          password2: ['', Validators.required],
        },
        { validators: passwordMatcher }
      ),
      challengeAnswer: ['', Validators.required],
      challengeQuestion: ['', Validators.required],
    });
  }

  togglePasswordHide() {
    this.passwordHide = !this.passwordHide;
  }

  toggleConfirmPasswordHide() {
    this.confirmpasswordHide = !this.confirmpasswordHide;
  }
  getChallengeQuestions() {
    this.registerService.getChallengeQuestions(this.baseurl).subscribe({
      next: (questions: any) => {
        this.questions = questions;
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}