<div class="tab-content profile-security">
    <div class="bg-white">
      <div class="form-display-card">
        <mat-card class="card-container">
          <mat-card-header>
            <mat-card-title>User Account Management
              <div *ngIf="isAccountManager && numOfAccounts < 3">
                <a (click)="displayAccountForm()" class="ts-s">
                  Add
                  <span class="fa fa-plus-circle fa-sm-blue" matTooltip="ADD NEW ACCOUNT" matTooltipClass="tooltip-md">
                  </span>
                </a>
              </div>
            </mat-card-title>
            <mat-card-subtitle>
              Multiple login accounts can be created for an employer. The first login account you create is the
              primary account. Additionally, you can establish up to two secondary accounts. Primary and secondary
              accounts have the same access, except the primary account user can also add or inactivate a secondary
              account. Additionally, the primary account user can change their account to a secondary account.
              If only a primary account is established, in other words, no secondary account, then account information
              cannot be accessed if the primary user is not available to log in.
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <table class="table account-users-table x-scrollable-table">
              <thead>
                <tr>
                  <th *ngFor="let col of profileAccountHeader">{{col}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let account of profileAccounts"
                  [ngClass]="{'profile-primary-account' : account.isAccountManager }">
                  <td>{{account.loginId}}</td>
                  <td>{{account.userName}}</td>
                  <td>{{account.isAccountManager ? 'Manager' : account.isRegistrationComplete ? 'Secondary' :
                    'Secondary
                    (Incomplete Registration)'}}</td>
                  <td>{{account.creationTime | date:'MM/dd/yyyy'}}</td>
                  <td *ngIf="isAccountManager">
                    <span *ngIf="isAccountManager" (click)="editaccount(account)" class="fa fa-pencil-square-o fa-md-blue"
                      matTooltip="EDIT ACCOUNT">
                    </span>
                    <span *ngIf="!account.isAccountManager && isAccountManager" (click)="deleteaccount(account)"
                      class="fa fa-trash-o fa-md-blue" matTooltip="DELETE ACCOUNT">
                    </span>
                    <span *ngIf="!account.isAccountManager && account.isRegistrationComplete && isAccountManager"
                      (click)="moveToPrimary(account)" class="fa fa-arrow-circle-o-up fa-md-blue"
                      matTooltip="MAKE PRIMARY ACCOUNT">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div *ngIf="showAccountForm" class="add-edit-account">
      <div class="form-display-card">
        <mat-card class="card-container">
          <mat-card-header>
            <mat-card-title *ngIf="!isEdit">Add Account</mat-card-title>
            <mat-card-title *ngIf="isEdit">Edit Account</mat-card-title>
          </mat-card-header>
          <mat-card-content class="profile-detail-form-field">
            <form [formGroup]="secondaryAccountFrmGrp" (ngSubmit)="insertUpdateSecondaryAccount()" autocomplete="off">

              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field *ngIf="!useEmailForLogin">
                  <input matInput placeholder="Username" #loginId formControlName="loginId" autocomplete="off" required>
                  <mat-hint *ngIf="isValidLogin && !isCheckingLogin" style="color: darkgreen">{{loginSuccessMessage}}
                  </mat-hint>
                  <mat-hint *ngIf="!isValidLogin && !isCheckingLogin">{{loginHintMessage}}</mat-hint>
                  <span *ngIf="isCheckingLogin" matSuffix><i style="font-size: 1.25em;"
                      class="fa fa-refresh fa-spin fa-2x fa-fw"></i></span>
                  <span *ngIf="isValidLogin" matSuffix><i class="fa fa-check c-green0"></i></span>
                  <mat-error>{{loginErrorMessage}}</mat-error>
                </mat-form-field>
              </div>

              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field>
                  <input matInput placeholder="First name" formControlName="firstName" autocomplete="off" required>
                  <mat-error>Required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Middle name" formControlName="middleName" autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Last name" formControlName="lastName" autocomplete="off" required>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field>
                  <input matInput placeholder="Address" formControlName="address1" autocomplete="off" required>
                  <mat-error>Required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Line 2" formControlName="address2" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field>
                  <input matInput placeholder="City" formControlName="city" autocomplete="off" required>
                  <mat-error>Required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-select matInput disableOptionCentering panelClass="matSelectClass"
                    [placeholder]="this.secondaryAccountFrmGrp.get('country')!.value.trim() === 'CA' ? 'Province' : 'State'"
                    formControlName="state" required>
                    <mat-option *ngFor="let state of states" [value]="state.code">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Zip" formControlName="zip5" autocomplete="off" required>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field>
                  <mat-select matInput disableOptionCentering panelClass="matSelectClass" placeholder="Country"
                    formControlName="country" required>
                    <mat-option *ngFor="let country of countries" [value]="country.country">
                      {{country.countryName}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field formGroupName="phoneGp">
                  <!-- <input matInput placeholder="Phone" formControlName="phoneNumber" [mask]="phoneMask" required> -->
                  <input matInput placeholder="Phone" formControlName="phoneNumber" [mask]="phoneMask" required>
                  <span matPrefix>+{{secondaryAccountFrmGrp.get('phoneGp.countryCode')?.value}}&nbsp;&nbsp;</span>
                  <mat-hint>Ex. {{phoneExample}}</mat-hint>
                  <mat-error *ngIf="!secondaryAccountFrmGrp.get('phoneGp.isValid')?.value">Invalid Format</mat-error>
                  <mat-error *ngIf="secondaryAccountFrmGrp.get('phoneGp.phoneNumber')?.hasError('required')">Required
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Phone Ext" formControlName="phoneExt">
                </mat-form-field>
              </div>
              <div class="flex-input-col pt-2 pb-2">
                <mat-form-field>
                  <input matInput placeholder="Email" formControlName="email" autocomplete="off" required>
                  <mat-hint *ngIf="isValidEmail && !isCheckingEmail" class="mat-hint-success">{{emailSuccessMessage}}
                  </mat-hint>
                  <span *ngIf="isCheckingEmail" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                  <span *ngIf="isValidEmail" matSuffix><i class="fa fa-check"
                      style="font-size: 25px;color:darkgreen"></i></span>
                  <mat-error>{{emailErrorMessage}}</mat-error>
                  <mat-error *ngIf="secondaryAccountFrmGrp.get('email')?.hasError('required') &&
                                    emailErrorMessage?.length !== 0">Required</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-btn-row pt-2 pb-2">
                <button class="btn btn-secondary" mat-raised-button (click)="closeForm()">Cancel</button>
                <button *ngIf="isEdit" class="btn btn-submit" [disabled]="secondaryAccountFrmGrp.invalid"
                  (click)=" insertUpdateSecondaryAccount()" mat-raised-button type="submit">Save Account</button>
                <button *ngIf="!isEdit" class="btn btn-submit" [disabled]="secondaryAccountFrmGrp.invalid"
                  mat-raised-button type="submit">Create Account</button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>