<epay-page-content [pagetitle]="pagetitle">
  <div class="box-panel shadow max-w max-gs">
    <div class="m--pa4">
      <!-- <div class="col-10"> -->
      <h3>Your session is about to expire.</h3>
      <!-- </div> -->
      <!-- <div class="col-10"> -->
      <p>
        You've been inactive for a while. For your security, we'll automatically sign you out
        in approximately 30 seconds. You may choose "Stay Signed In" to continue or "Sign Out" if you're done.
      </p>
      <!-- </div> -->
      <div class="flex-btn-col pt-2 pb-2">
        <button class="btn btn-primary" style="border-color: gray; background-color: gray; "
          (click)="stayLoggedIn()">Stay
          Signed In</button>
        <button class="btn btn-primary ml-2" (click)="logout()">Sign Out</button>
      </div>
    </div>
  </div>
</epay-page-content>
