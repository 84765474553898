import { Component, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-paymentaccountlist',
  templateUrl: './paymentaccountlist.component.html'
})
export class EMPPaymentAccountListComponent implements OnInit {
  pagetitle = 'ACH Debit Bank Accounts';
  baseurl: string;
  routingHelpImage: string;

  constructor() {
    this.baseurl = CommonSettings.BaseApi;
    this.routingHelpImage = '../../../../assets/help/aba.jpg';
  }

  ngOnInit() {
  }
}
