<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main pa-0" id="page-content-main">
    <!-- <epay-lib-employer-secondary-registration
        [baseurl] = "baseurl"
        [userEmailForLogin] = "userEmailForLogin">
    </epay-lib-employer-secondary-registration> -->

    <employer-secondary-registration 
    [baseurl] = "baseurl"
    [userEmailForLogin] = "userEmailForLogin">
    </employer-secondary-registration>
  </div>
</epay-page-content>