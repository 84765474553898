import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../../employer/services/employer.service';

@Component({
  selector: 'epay-termsandconditions',
  templateUrl: './termsandconditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit {
  pagetitle = 'Terms and Conditions'
  terms = '';
  constructor(private employerService: EmployerService) { }

  ngOnInit() {
    this.employerService.getEmployerTerms().subscribe(
      (result: any) => {
        this.terms = result;
      }
    );
  }

}
