<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main " id="page-content-main">
    <div class="">
      <epay-lib-employer-payment
        [baseurl]="baseurl"
        [termsContent]="termsContent"
        [clientName]="clientName"
        [saveEmployees]="saveEmployees"
        [validateCaseNumber]="validateCaseNumber">
      </epay-lib-employer-payment>
    </div>
  </div>
</epay-page-content>
