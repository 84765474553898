<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main box-panel shadow max-w max-gm" id="page-content-main">
    <div class="container max-w max-gm">
      <div class="row">
        <div class="col-12 col-md-6 mb-5 m--mb0">
          <epay-lib-employer-achd-home [achdInfo]="achdInfo" [baseurl]="baseurl">
          </epay-lib-employer-achd-home>
        </div>
        <div class="col-12 col-md-6">
          <epay-lib-employer-eft-home [eftInfo]="eftInfo">
          </epay-lib-employer-eft-home>
        </div>
      </div>
    </div>
  </div>
</epay-page-content>
