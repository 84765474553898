<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main box-panel shadow max-w max-gm" id="page-content-main">
    <div class="m--pa4" style="max-height: 30rem; overflow-y:scroll">

      <h2>Privacy Statement</h2>
      <i class="text-muted">Last Updated: 03/18/2024</i>
      <hr>

      This policy addresses how information obtained through your use of this web site is collected, stored, and used. The following 
      topics are covered:  <br />
      <br />

      <ul class="list-bullet mx-3">
        <li>
          Collection and storage of technical information <br />
        </li>

        <li>
          Collection and storage of personal information <br />
        </li>

        <li>
          Collection and storage of account information <br />
        </li>

        <li>
          Disclosure of personal and account information <br />
        </li>

        <li>
          International transactions <br />
        </li>

        <li>
          Securing the web site
        </li>
      </ul>
      <br />

      <STRONG>Collection and Storage of Technical Information </STRONG>
      <hr>
  
      Whenever you browse the web site or download forms, we collect and store information about your visit. This information 
      does not identify you personally and consists of: 
      <br />

      <br />
      <ul class="list-bullet mx-3">
        <li>
          Your Internet protocol address. This is a numerical identifier assigned either to your Internet service provider or your computer. 
          It can often be translated to determine the domain name of your service provider. <br />
        </li>

        <li>
          The date and time of your first and last visit to this site. <br />
        </li>

        <li>
          The web pages or services you accessed during your visit. <br />
        </li>
      </ul>
      <br />

      <STRONG>Collection and Storage of Personal Information</STRONG>
      <hr>
  
      Certain personal information is required when you choose to submit a child support payment via our web site. This information is 
      collected and stored and readily identifies you; for example, your name, address, telephone number, e-mail address, social security 
      number, or case identifier.
      <br />

      <br>
      <STRONG>Collection and Storage of Account Information</STRONG>
      <hr>
  
      If you register for our bank draft service, the checking or savings account number that you provide is stored for processing purposes. 
      If you choose to make a child support payment with your credit card, your card information is collected so we can process your payment, 
      however it is not stored. If you choose to make another payment with your credit card at a future time, you will be required to resubmit 
      your credit card information. The authorization number provided by your credit card company (when your payment is approved) is collected 
      and stored. 
      <br />

      <br />
      <STRONG>Disclosure of Personal and Account Information</STRONG>
      <hr>
  
      It is our policy to treat all personal and account information as confidential. However, we may disclose information to third parties 
      about your account, or the transactions you make, in the following situations: 
      <br />

      <br />
      <div class="mx-3">
        1. Where it is necessary for completing transactions, such as to the processing financial institution; <br />
        <br />
  
        2. In order to verify existence and condition of your account for a third party such as a credit bureau or merchant;  <br />
        <br />
  
        3. If you give us your written permission; and  <br />
        <br />
  
        4. Where required by law or in other appropriate circumstances.  <br />
        <br />
      </div>

      The personal information that you submit, such as a social security number, may become part of the child support file, however, 
      all child support files are confidential. <br />

      <br />
      <STRONG>International Transactions</STRONG>
      <hr>
  
      All payments made through this web site are intended only for payments not associated with a foreign bank account, which would 
      classify it as an international ACH transaction (IAT). 
      <br />

      <br />
      <STRONG>Securing the Web Site</STRONG>
      <hr>
  
      We have taken reasonable steps to safeguard the integrity of the data that we collect and store, along with preventing unauthorized 
      access to information maintained by the web site. These measures are designed and intended to prevent corruption of data, block unknown 
      or unauthorized access to our systems and information, and to provide reasonable protection of private information in our possession. 
      Please be advised that, given the inherent nature of technology and the Internet, no security measures can ever be assumed to be 100% 
      fail-safe.
      <br />

      <br />
      <STRONG>Cookies</STRONG>
      <hr>
  
      Cookies are very commonly used when browsing websites. It allows the website to store information on your computer in order to keep 
      track of your unique session on the site. There are different types of cookies, and this site only uses a session cookie. Session 
      cookies are stored temporarily and then deleted as soon as your web session has completed. It allows you to securely navigate the 
      website using a unique session identifier, and the website is able to keep track of who you are. The storage of information related 
      to the web session is temporary and the cookie will immediately be deleted as soon as you close or navigate away from the site. For 
      security purposes, our website requires the use of session cookies.
      <br />

    </div>
  </div>
</epay-page-content>
