import { Component, OnInit } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { AuthService, CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-logoutwarning',
  templateUrl: './logoutwarning.component.html',
})
export class LogoutWarningComponent implements OnInit {
  pagetitle = '';

  constructor(
    private location: Location,
    private authservice: AuthService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.pagetitle = 'Logout Warning';
  }

  stayLoggedIn() {
    this.location.back();
  }
  logout() {
    this.authservice.logout(CommonSettings.BaseApi);
    this.openSnackBar(
      'You have been successfully logged out.',
      3500,
      'snackbar-success'
    );
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }
}
