<epay-page-content [pagetitle]="pagetitle">
  <div *ngIf="creditCardMessage" class="row justify-content-center">
    <div class="col-8 box-panel mt-5 text-center">
      {{creditCardMessage}}
    </div>
  </div>
  <epay-lib-common-login [tokenurl]="tokenurl" [baseurl]="baseurl" [sessionTimeout]="timeoutInMins"
    [clientId]="clientId">
  </epay-lib-common-login>
  <br>
  <div class="page-content-main max-w max-gs t-alignC">
    <div class="message-panel" *ngIf="message1">
      <div class="box-panel">
        <p class="c-red1b">{{message1}}</p>
        <p class="c-red1b">{{message2}}</p>
        <p class="c-red1b">{{message3}}</p>
      </div>
    </div>
  </div>

</epay-page-content>
