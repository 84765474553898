<div class="stepper-panel username-recovery-flow">
    <div class="form-panel panel-desktop">
      <mat-stepper [orientation]="stepperOrientation" #stepper linear="true">
        <!-- 1. Current Pass & Email -->
        <mat-step class="stepper-icon" [stepControl]="passwordFrmGp">
          <form [formGroup]="passwordFrmGp">
            <ng-template matStepLabel>Enter Your Current Password</ng-template>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>                <input matInput placeholder="Current Password" [type]="passwordHide ? 'password' : 'text'"
                  formControlName="password" autocomplete="off">
                <mat-icon matSuffix (click)="togglePasswordHide()">{{passwordHide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-error>{{loginErrorMessage}}</mat-error>
                <mat-error *ngIf="passwordFrmGp.get('password')?.hasError('required')">Required</mat-error>
                <mat-error *ngIf="passwordFrmGp.get('password')?.hasError('maxlength')">Invalid password. Passwords must be between 8 to 12 characters in length.</mat-error>
                <mat-error *ngIf="passwordFrmGp.get('password')?.hasError('minlength')">Invalid password. Passwords must be between 8 to 12 characters in length.</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput placeholder="Current Email" formControlName="email" autocomplete="off">
                <mat-hint *ngIf="isValidAccount && !isCheckingLogin" class="c-green0">
                  {{accountSuccessMessage}}</mat-hint>
                <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                <span *ngIf="isValidAccount" matSuffix><i class="fa fa-check ts-l c-green0"></i></span>
                <mat-error *ngIf="!accountErrorMessage?.length">{{emailErrorMessage}}</mat-error>
                <mat-error *ngIf="accountErrorMessage?.length">{{accountErrorMessage}}</mat-error>
                <mat-error *ngIf="passwordFrmGp.get('password')?.hasError('required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-btn-row pt-2 pb-2">
              <button class="btn btn-secondary" mat-button (click)="goToLogin()">Back</button>
              <button class="btn btn-primary-short" mat-button matStepperNext [disabled]="!isValidAccount">Next</button>
            </div>
          </form>
        </mat-step>
        <!-- 2. Security Question -->
        <mat-step class="stepper-icon" [stepControl]="securityFrmGp">
          <form [formGroup]="securityFrmGp">
            <ng-template matStepLabel>Answer your Security Question</ng-template>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput formControlName="challengeQuestion" readonly>
              </mat-form-field>
            </div>
            <div class="flex-input-col pt-2 pb-2">
              <mat-form-field>
                <input matInput placeholder="Challenge Answer" formControlName="challengeAnswer" autocomplete="off">
                <span *ngIf="isCheckingAnswer" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                <span *ngIf="isValidAnswer" matSuffix><i class="fa fa-check ts-l c-green0"></i></span>
                <mat-hint *ngIf="isValidAnswer && !isCheckingAnswer" class="c-green0">
                  {{challengeSuccessMessage}}</mat-hint>
                <mat-error>{{challengeErrorMessage}}</mat-error>
                <mat-error *ngIf="securityFrmGp.get('challengeAnswer')?.hasError('required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-btn-row pt-2 pb-2">
              <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
              <button class="btn btn-primary-short" (click)="retrieveUsername()" mat-button matStepperNext
                [disabled]="!isValidAnswer">Next</button>
            </div>
          </form>
        </mat-step>
        <!-- 3. Retrieve Username -->
        <mat-step class="stepper-icon">
          <ng-template matStepLabel>Retrieve Username</ng-template>
          <div class="pt-2 pb-2">
            <p>Your Username is: </p>
            <h2>{{displayUsername}}</h2>
          </div>
          <div class="flex-btn-row pt-2 pb-2">
            <button class="btn btn-submit" mat-raised-button (click)="goToLogin()">Go to Login</button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
  