<div *ngIf="!openConfirmation">
    <div class="footer-gap">
      <div class="container m--full-bleed">
        <div class="row m--full-bleed">
          <div class="col-12 form-panel">
            <form [formGroup]="loginFrmGp" (ngSubmit)="completeRegistration()">
              <mat-card class="card-container">
                <mat-card-header>
                  <mat-card-title>Complete Registration</mat-card-title>
                </mat-card-header>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Username" #loginId formControlName="loginId" required>
                    <mat-hint *ngIf="!isValidLogin && !isCheckingLogin">{{loginHintMessage}}</mat-hint>
                    <mat-hint *ngIf="isValidLogin && !isCheckingLogin" style="color: darkgreen">{{loginSuccessMessage}}
                    </mat-hint>
                    <span *ngIf="isCheckingLogin" matSuffix><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                    <span *ngIf="isValidLogin" matSuffix><i class="fa fa-check"
                        style="font-size: 25px;color:darkgreen"></i></span>
                    <!-- <mat-error>{{loginErrorMessage}}</mat-error> -->
                    <mat-error *ngIf="loginFrmGp.invalid && loginFrmGp.get('loginId')?.value === ''">Required</mat-error> 
                    <mat-error *ngIf="loginFrmGp.get('loginId')?.hasError('isNotReady')">Username is not available for registration.</mat-error>
                    <mat-error *ngIf="loginFrmGp.get('loginId')?.hasError('isExpired')">Username has expired.  Contact account manager.</mat-error>  
                    <mat-error *ngIf="loginFrmGp.get('loginId')?.hasError('isSameAsPassword')">Invalid Username.  Username cannot be same as password.</mat-error>                    
                  
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2" formGroupName="passwordGp">
                  <mat-form-field>
                    <input matInput placeholder="Password" [type]="passwordHide ? 'password' : 'text'"
                      formControlName="password1" autocomplete="off" required>
                    <mat-hint>{{passwordHintMessage}}</mat-hint>
                    <mat-icon matSuffix (click)="togglePasswordHide()">{{passwordHide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-error>{{passwordErrorMessage}}</mat-error>
                    <mat-error *ngIf="loginFrmGp.invalid && passwordErrorMessage?.length === 0">Required</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput [errorStateMatcher]="errorMatcher" placeholder="Confirm Password"
                      [type]="confirmpasswordHide ? 'password' : 'text'" formControlName="password2" autocomplete="off"
                      required>
                    <mat-icon matSuffix (click)="toggleConfirmPasswordHide()">{{confirmpasswordHide ? 'visibility_off' :
                      'visibility'}}</mat-icon>
                    <mat-error *ngIf="loginFrmGp.get('passwordGp')?.hasError('doNotMatch')">
                      Passwords do not match.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field class="mt-3" style="width: 60%;">
                    <mat-select matInput disableOptionCentering panelClass="matSelectClass"
                      placeholder="Challenge Question" formControlName="challengeQuestion" required>
                      <mat-option *ngFor="let question of questions" [value]="question.index">
                        {{question.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Required</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-input-col pt-2 pb-2">
                  <mat-form-field>
                    <input matInput placeholder="Challenge Answer" formControlName="challengeAnswer" autocomplete="off"
                      required>
                    <mat-error>Required</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-btn-row pt-2 pb-2">
                  <button class="btn btn-submit mt-3" mat-raised-button type="submit">Complete Registration</button>
                </div>
              </mat-card>
            </form>
          </div>
        </div>
      </div>
    </div>
</div>
  
  <epay-lib-common-confirmation *ngIf="openConfirmation" [confirmationTitle]="confirmationTitle"
    [confirmationMessage]="confirmationMessage" [confirmationEmailAddress]="confirmationEmailAddress"
    [confirmationNumber]="confirmationNumber" [goToPrimary]="goToPrimary" [goToPrimaryUrl]="goToPrimaryUrl">
  </epay-lib-common-confirmation>
  