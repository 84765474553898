import { Component, OnInit } from '@angular/core';
import { AuthService, ErrorHandlerService, CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  pagetitle = 'Login';
  creditCardMessage: string;
  loginType: string;
  tokenurl: string;
  baseurl: string;
  timeoutInMins: string;
  clientId: string;

  message1 = '';
  message2 = '';
  message3 = '';
  empWebsiteCode = '';

  constructor(
    private authservice: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.creditCardMessage = '';
    this.loginType = '';
    this.tokenurl = CommonSettings.TokenApi;
    this.baseurl = CommonSettings.BaseApi;
    this.timeoutInMins = CommonSettings.TimeOutInMins;
    this.clientId = CommonSettings.ClientID;
    this.empWebsiteCode = CommonSettings.EmpWebsiteCode;
  }

  ngOnInit() {
    this.authservice
      .getWebsiteMessage(this.baseurl, this.empWebsiteCode)
      .subscribe({
        next: (message: any) => {
          if (message.length) {
            if (message[0].isActive === true) {
              this.message1 = message[0].messageText1;
              this.message2 = message[0].messageText2;
              this.message3 = message[0].messageText3;
            }
          } else {
            this.message1 = '';
            this.message2 = '';
            this.message3 = '';
          }
        },
        error: (error: any) => {
          this.logError(error);
          this.handleError(error.status);
        },
      });
    if (history.state.creditCardMessage) {
      this.creditCardMessage = history.state.creditCardMessage;
    } else {
      this.creditCardMessage = '';
    }
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}
