<div class="container " id="page-content-main">
  <div class="mt-4">
    <div class="">
      <mat-card class="mb-3" style="border-radius: 10px">
        <mat-card-content>
          <a [routerLink]="['/home']" class="ts-l tw-b">
              <span class="fa fa-chevron-circle-left fa-sm-blue" matTooltip="GO BACK"
              matTooltipClass="tooltip-md">
            </span>
            Back
          </a>
          <br><br>
          <div class="container-fluid">
            <h2>Employer FAQ</h2>
            <p>
            <div class="accordion accordion-flush" id="accordionFlushExample">

            <!-------ACH CREDIT ------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                    style="font-weight: bold;">
                    ACH Credit (EFT/ EDI)
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How can I obtain information on the EFT/ EDI process?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Download an information packet at this web site or contact the Oklahoma Employer Services Center at 1-866-553-2368.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I recently arranged for a prenote transmission, when can I stop mailing in checks?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Five business days are required to validate bank account information that has not been
                          previously verified. You should continue to remit a check for your employees’
                          child support withholdings during this five business day period. You will be
                          notified if the prenote is unsuccessful, at which point you must continue to
                          remit a check until your bank account information has been successfully verified.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What should I do if the wrong amount is withheld from an employee’s paycheck?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Contact OKESC at 1-866-553-2368.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!---------------------------------------------------------------------------------------------->
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I transmit a debit transaction (reversal) if there is a mistake?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          No, debit transactions are not allowed.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>
            <!-------ACH DEBIT---------------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                    style="font-weight: bold;">
                    ACH Debit
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                     <!------REGISTRATION and PASSWORDS------------------------------------------------------------------------------------------>
                     <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Questions about Registering and Scheduling a Bank Draft
                        </mat-expansion-panel-header>
                         <!------------------------------------------------------------------------------------------------>
                         <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                What should I do to have child support payments automatically withdrawn from my bank account?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Click Employer at this web site, register an account, and schedule a payment.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                What information is required to register an account on the web site?
                              </div>
                            </mat-expansion-panel-header>
                            <ul class="list-bullet mx-4">
                              <li>Employer Name</li>
                              <li>Contact Name</li> 
                              <li>Telephone Number</li>
                              <li>Valid Email Address</li>
                              <li>Bank information: bank name, routing number, account number, type of account; checking or savings</li>
                            </ul>
                          </mat-expansion-panel>
                        </mat-accordion>
                         <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                Can I schedule more than one payment, for example, a payment on the 20th of the month, as well as a 
                                payment every two weeks on Friday?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Yes, however, you will need to schedule each payment separately.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>

                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------BANK DRAFT------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Questions about Scheduling a Bank Draft
                        </mat-expansion-panel-header>
                          <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  What should I do to have child support payments automatically withdrawn from my bank account?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                Click Employer at this web site, register an account, and schedule a payment.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!------------------------------------------------------------------------------------------------>
                          <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  Can I schedule more than one payment, for example, a payment on the 20th of the month,
                                  as well as a payment every two weeks on Friday?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                Yes, however, you will need to schedule each payment separately.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                           <!------------------------------------------------------------------------------------------------>
                           <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  Can I have more than one bank account authorized for a payment?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                No, you can only authorize one bank account for a payment.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!------------------------------------------------------------------------------------------------>
                          <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  What day will funds be withdrawn from my bank account?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                Funds will be withdrawn on the scheduled payment date. If that date falls on a
                                Saturday, Sunday, or bank holiday the funds will be withdrawn the next business day.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!------------------------------------------------------------------------------------------------>
                          <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  How long will it take for Oklahoma Child Support Services to receive a child support payment 
                                  once it is withdrawn from my bank account? 
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                The payment will be processed on the scheduled date unless there are technical difficulties. Your 
                                employees will receive credit for the payment in two to three business days.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!------------------------------------------------------------------------------------------------>
                          <!-- <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  How long will it take for Oklahoma Child Support Services to receive a child support
                                  payment once it is withdrawn from my bank account?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                The payment will be processed on the scheduled date unless there are technical difficulties.
                                Your employees will receive credit for the payment on the same date.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion> -->
                           <!------------------------------------------------------------------------------------------------>
                           <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  What happens if my payment is scheduled for a weekend or a holiday?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                If a withdrawal falls on a Saturday, Sunday, or bank holiday,
                                the funds will be withdrawn the next business day. Your
                                employees will receive credit for the payment in two to three business days.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                           <!------------------------------------------------------------------------------------------------>
                           <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  What is the earliest date that I can schedule a payment?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                Your payment start date must be at least five business days from the date you register your account.
                                This will allow sufficient time for your bank account information to be verified (prenote).
                                If your account has been previously verified then the payment must be scheduled at least two business
                                days prior to its due date.
                                <br><br>
                                <span style="font-weight: 600;">Example:</span> If you register your account on Monday the 1st, you must set your payment
                                start date no earlier than Tuesday the 9th.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!------------------------------------------------------------------------------------------------>
                          <mat-accordion multi>
                            <mat-expansion-panel>
                              <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                                <div class="m-3">
                                  I entered a start date and the web site calculated a different date for my first payment. What am I doing wrong?
                                </div>
                              </mat-expansion-panel-header>
                              <div class="mx-4">
                                If you entered a start date that is less than five business days from today's date, your date will be
                                automatically adjusted to include the five business day verification (prenote) period.
                                <br><br>
                                <span style="font-weight: 600;">Example:</span> Today is January 26 (Monday) and you entered January 30 (Friday) as your payment start date.
                                The web site calculates the start date as February 3, taking the five business day verification period
                                into consideration; January 27 - February 2.
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------PRENOTE------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Questions about Verification (Prenote) Period
                        </mat-expansion-panel-header>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                What happens after I register an account and schedule a payment?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              If your bank account information has not been previously verified, it will be
                              submitted to your bank for verification (prenote). If the prenote is successful,
                              your payments will be withdrawn as scheduled. If the prenote is unsuccessful,
                              you will be notified by e-mail and no payment will be processed.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                Why would a prenote be returned as unsuccessful?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Most prenotes are returned as unsuccessful when the bank account information was entered incorrectly.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                I received an e-mail that the prenote was unsuccessful. What should I do now?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Edit your bank account information through this web site and reschedule your payment.
                              The prenote will be resubmitted. You may want to contact your bank if you need assistance
                              identifying the routing and account numbers.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------EDIT OR CHANGE BANK ACCOUNTS------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Questions about Editing or Changing Bank Accounts
                        </mat-expansion-panel-header>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                I changed my bank account, what should I do?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Submit your new bank account information when you schedule your next payment. A prenote will be sent to 
                              verify the new bank account information. If your payment is due before the end of the five business day 
                              verification (prenote) period, you will need to mail your employees’ withholdings to the Oklahoma 
                              Centralized Support Registry in order to avoid delinquency on their child support accounts.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                         <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                I authorized a bank draft and now I want to cancel it. What should I to do?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Change or cancel your payment through this web site. You must allow two business
                              days prior to the payment's scheduled date to cancel your payment.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------UNAVAILABLE FUNDS------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Questions about Unavailable Funds
                        </mat-expansion-panel-header>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                What if my bank account does not have sufficient funds to cover a payment?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              You must cancel your payment at least two business days prior to its scheduled date.
                              You can cancel your payment at this web site.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                What if I fail to cancel the payment two business days prior to its
                                scheduled date and there are insufficient funds in my account?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              Your account will be placed on hold and your automatic withdrawal will be cancelled. You will be 
                              required to remit all future payments with a guaranteed payment instrument such as a money 
                              order, cashier's check, or certified check. Call CARE at 1-800-522-2922 as soon as possible, 
                              as it may be possible to stop or prevent the issuance of erroneous funds, thereby avoiding a bad debt.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <!------------------------------------------------------------------------------------------------>
                        <mat-accordion multi>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                              <div class="m-3">
                                Why is my bank account on hold?
                              </div>
                            </mat-expansion-panel-header>
                            <div class="mx-4">
                              We attempted to withdraw the funds from your bank account and they were not available.
                              You will be required to remit all future payments by a guaranteed instrument such as a
                              money order, cashier's check, or certified check, or credit card.
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <!----- PASSWORD ------------------------------------------------------------------------------------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"
                    style="font-weight: bold;">
                    Password FAQs
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        <div class="mt-3">
                          I am having trouble creating a password.
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mx-4">
                        Your password must consist of at least two numbers and six letters, and not exceed 12
                        characters in length. In addition, it cannot contain any special characters.
                        For example, question marks, number signs, periods.
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <!------------------------------------------------------------------------------------------------>
                  <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        <div class="mt-3">
                          Can my password be the same as my user ID?
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mx-4">
                        No, it must be different from your user ID.
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        <div class="mt-3">
                          Does my password ever expire?
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mx-4">
                        No, your password does not expire.
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        <div class="mt-3">
                          I forgot my password. What should I do?
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mx-4">
                        On the login page, click "Forgot your password?", enter the required information, and create a new password.
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <!------------------------------------------------------------------------------------------------>
                  <mat-accordion multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                        <div class="mt-3">
                          If I forget my password and change it but remember it later and want to change it back to the original password can I?
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mx-4">
                        Yes, you can.
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>
              
              <!-----CREDIT CARDS------------------------------------------------------------------------------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"
                    style="font-weight: bold;">
                    Credit Card FAQs
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What credit cards do you accept?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            You may use your MasterCard® or Visa® to make a child support payment.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                     <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                         What browsers do you support for e-wallet payments?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          Apple Pay payments must be made with an Apple device. Google Pay and PayPal payments
                          may be made with any browser. You must use Safari (iOS) or Chrome (Android) when
                          making a payment through Venmo.
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What information is required to make a credit card payment?
                        </mat-expansion-panel-header>
                        <div class="mx-2">
                          The following information is required to make a credit card payment: <br>

                          <ul class="list-bullet mx-3">
                            <br>
                            <li>Credit card holder's name.</li>
                            <li>Credit card holder's billing address.</li>
                            <li>Valid e-mail address.</li>
                            <li>MasterCard® or Visa® credit card number and expiration date and CVV2/ CVC2 3-digit security code.</li>
                          </ul>
                        </div>

                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What should I do if I don't have an e-mail address?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        A valid e-mail address is required in order for us to communicate with you electronically.
                        If you do not have an e-mail address we cannot send you information such as a confirmation
                        of your transactions. Free e-mail accounts are available on the Internet. To locate one,
                        use any search engine and search for "free e-mail".
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Can I make a credit card payment by phone?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, you can make a non-recurring payment for an employee by calling 1.844.324.3856.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Is there a minimum payment requirement?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Yes, there is a minimum payment requirement of $1.00.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the maximum payment that I can make?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The maximum payment that you can make in one transaction is $9,999.99; if your card has
                        that amount of credit available. Multiple transactions can be made in one day if each
                        transaction does not exceed $9,999.99, and the appropriate amount of credit is available.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the purpose of the service fee?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The credit card service fee is charged for the convenience of using this easy
                        payment method. Without the service fee, the cost of providing this service would be prohibitive.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How much is the service fee?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The service fee for using this service is 2.50% of the amount of the primary child support payment.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Will the service fee be applied to my child support obligation?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The service fee is separate from your child support obligation and is not applied to it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          When will my credit card be charged for my child support payment?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Your credit card is charged at the time you submit the transaction, and your credit card company authorizes it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          When will my payment be applied to my employees' child support accounts?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        They will receive credit in two to three business days.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          What is the CVV2/ CVC2 3 or 4-digit security code and where do I find it?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        The CVV2/ CVC2 3-digit security code is an added security measure. It is used to establish that you are 
                        in possession of the credit card. The code is printed just above the signature panel in reverse italics 
                        on the back of your card. Usually your card number precedes it.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          My credit card transaction was declined, what should I do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        You will need to contact your credit card company to determine why the payment was not authorized.
                        It could have been declined for one of the following reasons: <br>
                        
                        <br />
                        &nbsp; &bull; The credit card number and/ or CVV2/ CVC2 security code was incorrectly entered.<br>
                        
                        <br />
                        &nbsp; &bull; There is a discrepancy between the address you entered and the billing address that
                        is on file with the card-issuing bank. You must enter the billing address that appears on your credit card statement. <br>
                        
                        <br />
                        &nbsp; &bull; A foreign address (a country other than the US) was entered. The web site is unable
                        to process credit card payments with a foreign address.<br>
                        
                        <br />
                        &nbsp; &bull; There is insufficient credit available for the payment amount.
                        <br>
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          How do I obtain a refund on a credit card payment made through this website?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        You will need to contact Oklahoma Child Support Customer Service at 1-855-363-1754.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion> -->
                    <br>
                  </div>
                </div>
              </div>
              <!-----GENERAL ------------------------------------------------------------------------------------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"
                    style="font-weight: bold;">
                    General FAQs
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Are employers required to send child support payments electronically?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Employers are not required to remit payments electronically. However,
                            by submitting electronically, employers can reduce their cost for submitting child support payments.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <!-- <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I tried to register my employer on the web site, but my company name could not be located. What should I do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Contact the Oklahoma Centralized Support Registry at 1-855-363-1754 and report the problem. 
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br> -->
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Why am I unable to upload or download files with my employees’ payment information?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Microsoft Excel must be installed on your device to upload or download files.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>
               <!-----TECHNICAL ISSUES---------------------------------------------------------------------------->
               <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"
                    style="font-weight: bold;">
                    Technical Issues
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          It appears there is a problem with the web site. What should I do?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Contact the Oklahoma Centralized Support Registry at 1-855-363-1754 and report the problem. 
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>
              <!-----UNAUTHORIZED ACCESS TO ACCOUNT---------------------------------------------------------------------------->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"
                    style="font-weight: bold;">
                    Unauthorized Access of an Account
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          I believe someone has access to my web site user ID and password. What do I need to do?
                        </mat-expansion-panel-header>
                          <div class="mx-2">
                            Change your user ID and password through this web site.
                          </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                    <!------------------------------------------------------------------------------------------------>
                    <mat-accordion multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header style="font-weight: 600; color: #174a7c">
                          Money has been withdrawn from my account without my permission. What should I do?
                        </mat-expansion-panel-header>
                       <div class="mx-2">
                        Contact the Oklahoma Centralized Support Registry at 1-855-363-1754 immediately. You will be 
                        asked to submit your claim in writing.
                       </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <br>
                  </div>
                </div>
              </div>

              <!--------CONTACT INFORMATION------------------------------------------------------------------------------------>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"
                    style="font-weight: bold;">
                    Contact Information
                  </button>
                </h2>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body mx-3">
                    Assistance with web site and payment schedules: 1-855-363-1754, Oklahoma Centralized Support Registry
                    <br />

                    <br />
                    Assistance with employer-related questions: 1-866-553-2368, Oklahoma Employer Services Center
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
