import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { IProcessCreditCard} from '../models/creditcard-payment.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreditCardPaymentService {
  gateway = 'EPayGateway/EPay/api/v1/';

  httpOptions = {
    headers: new HttpHeaders(),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) { }

  resetHeader() {
    this.httpOptions.headers = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
  }

  insertProcessCreditCard(
    baseurl: string,
    ccPayment: IProcessCreditCard
  ): Observable<any> {
    const apiCmd = 'CreditCard:processCreditCard';
    this.resetHeader();

    return this.http
      .post<any>(baseurl + this.gateway + apiCmd, ccPayment)
      .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An Error Occurred: ${err.error.message}`;
    } else if (err.status === 404 || err.status === 409) {
      return throwError(() => err);
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      console.log('Error Occurred: ' + errorMessage);
    }
    return throwError(() => err);
  }
}

