import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epay-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit {
  pagetitle = 'FAQs';

  constructor() {}

  ngOnInit(): void {

  }

}
