import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployerRoutingModule } from './employer-routing.module';
import { EmployerModuleComponent } from './employer.module.component';
import { EmployerLibraryModule, MaterialLibraryModule } from 'epay-library';
import { HelperLibraryModule } from 'epay-library';
import { CommonLibraryModule } from 'epay-library';
import { BaseLayoutModule } from '../layout/base-layout.module';
import { EMPHomeComponent } from './components/home/home.component';

import { EMPRegisterComponent } from './components/register/employer.register.component';
import { EMPACHDLandingComponent } from './components/achd/landing/achd.landing.component';
import { EMPCreditCardLandingComponent } from './components/creditcard/landing/creditcard.landing.component';
import { EMPCreditCardPaymentComponent } from './components/creditcard/payment/creditcard.payment.component';
import { EMPEFTCreateFileComponent } from './components/eft/createfile/createfile.component';
import { EMPEFTInformationComponent } from './components/eft/information/eft-information.component';
import { EMPEFTLandingComponent } from './components/eft/landing/eft.landing.component';
import { EMPProfileComponent } from './components/profile/profile.component';
import { EMPACHDInformationComponent } from './components/achd/information/achd-information.component';
import { EMPPaymentComponent } from './components/achd/payment/payment.component';
import { EMPPaymentAccountListComponent } from './components/achd/paymentaccount/paymentaccountlist.component';
import { EMPSecondaryRegistrationComponent } from './components/register/secondary-registration/secondary-registration.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { MaskDataPipe } from 'epay-library';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ngxInputRegulateModule } from 'ngx-input-regulate';
import { InteractiveCreditCardEMPComponent } from './components/creditcard/payment/interactive-credit-card-emp/interactive-credit-card-emp.component';
import { EMPProfileSecurityComponent } from './components/profile/empprofile-security/empprofile-security.component';
import { EmployerProfileAccountComponent } from './components/profile/employer-profile-account/employer-profile-account.component';
import { EmployerSecondaryRegistrationComponent } from './components/register/secondary-registration/empsecondary-registration/empsecondary-registration.component';
import { EMPSecurityCodeHelpComponent } from './components/creditcard/payment/empsecurity-code-help/empsecurity-code-help.component';

@NgModule({
  declarations: [
    EmployerModuleComponent,
    EMPHomeComponent,
    EMPRegisterComponent,
    EMPACHDLandingComponent,
    EMPEFTLandingComponent,
    EMPProfileComponent,
    EMPPaymentComponent,
    EMPPaymentAccountListComponent,
    EMPSecondaryRegistrationComponent,
    EMPACHDInformationComponent,
    EMPEFTInformationComponent,
    EMPEFTCreateFileComponent,
    EMPCreditCardPaymentComponent,
    EMPCreditCardLandingComponent,
    InteractiveCreditCardEMPComponent,
    EMPProfileSecurityComponent,
    EmployerProfileAccountComponent,
    EmployerSecondaryRegistrationComponent,
    EMPSecurityCodeHelpComponent
  ],
  imports: [
    CommonModule,
    EmployerRoutingModule,
    EmployerLibraryModule,
    HelperLibraryModule,
    CommonLibraryModule,
    BaseLayoutModule,
    MatFormFieldModule,
    MaterialLibraryModule,
    ReactiveFormsModule,
    FormsModule,
    ngxInputRegulateModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [MaskDataPipe],
})
export class EmployerModule {}
