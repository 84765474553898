import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BusyService } from 'epay-library';

@Injectable()
export class BusyInterceptor implements HttpInterceptor {
  excludeAPIs: string[];

  constructor(private busyService: BusyService) {
    this.excludeAPIs = [];
    // add api commands that we don't block ui
    this.excludeAPIs.push('searchDuplicateLoginId');
    this.excludeAPIs.push('getChallengeQuestion');
    this.excludeAPIs.push('validateChallengeAnswer');
    this.excludeAPIs.push('searchEmployer');
    this.excludeAPIs.push('searchDuplicateEmail');
    this.excludeAPIs.push('searchDuplicateEin');
    this.excludeAPIs.push('searchPendingRegistration');
    this.excludeAPIs.push('ScheduledPayments:checkParticipants');
    this.excludeAPIs.push('ScheduledPayments:getNextScheduledDate');
    this.excludeAPIs.push('ScheduledPayments:getEarliestStartDateForNewPayment');
    this.excludeAPIs.push('ScheduledPayments:activeInactive');
    this.excludeAPIs.push('ScheduledPayments:paymentHistory');
    this.excludeAPIs.push('ScheduledPayments:activeInactive');
    this.excludeAPIs.push('ScheduledPaymentAccounts:withStatusAndCounts');
    this.excludeAPIs.push('Registration:states');
    this.excludeAPIs.push('ACHG:getGeneratedFile');
    this.excludeAPIs.push('CreditCard:createCreditCardPaymentEmployer');
    this.excludeAPIs.push('Registration:ssnAndCaseNumberValidation');
    this.excludeAPIs.push('CreditCard:listCases');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const msg = req.method === 'GET' ? 'Loading ...' : 'Saving ...';
    if (!this.blockUIExcept(req.url.toString())) {
      this.busyService.increment(msg);
      return next.handle(req).pipe(
        finalize(() => {
          this.busyService.decrement();
        })
      );
    } else {
      return next.handle(req).pipe(
        finalize(() => {
          this.busyService.staystill();
        })
      );
    }
  }

  blockUIExcept(url: string): boolean {
  let block = false;
  for (const api of this.excludeAPIs) {
    if (url.toLowerCase().includes(api.toLowerCase())) {
      block = true;
      break;
    } else {
      block = false;
    }
  }
  return block;
  }
}
