import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppLoadService } from '../service/appload.service';

// export function getSettings(apploadservice: AppLoadService) {
//   return () => apploadservice.getParameterSettings();
// }

@NgModule({
  declarations: [],
  imports: [HttpClientModule, CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AppLoadService],
      multi: true,
      useFactory: (appLoadService: AppLoadService) => {
        return () => {
          return appLoadService.getConfigSettings().then(() => {
            return appLoadService.getParameterSettings();
          });
        };
      },
    },
  ],
})
export class ApploadModule {}
