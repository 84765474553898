<epay-page-content [pagetitle]="pagetitle">
  <div class="box-panel shadow max-w max-gs">
    <div class="m--pa4">
      <h2 class="mb-5">Contact Us</h2>
      <h3>Address</h3>
      Oklahoma Centralized Support Registry <br>
      P.O. Box 268809 <br>
      Oklahoma City, OK 73126-8809 <br>
      <br>
      <h3>Telephone</h3>
      1-855-363-1754
      <div style="font-size: smaller;"><i>Note: If you are calling from a cell phone and have free long distance, 
        please call the local number instead of the toll free number.</i></div>
      <br>
      <h3>E-mail</h3>
      <address>
        <a href="mailto:okecsupport@smimail.net">
          <u>okecsupport@smimail.net</u>
        </a>
      </address>
      <div style="font-size: smaller;"><i>Note: All questions related to a child support case should be directed to CARE at 1-800-522-2922.</i>
      </div>
      <br>
      <!-- <h3>Live Chat</h3>
      <div>
        <span>
          <img (click)="openChat()" src="../../../../../assets/images/chaticonmid.png">
        </span>
      </div> -->
    </div>
  </div>
</epay-page-content>
