import { Component, OnInit } from '@angular/core';
import { EmployerService } from '../../../services/employer.service';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-achd-information',
  templateUrl: './achd-information.component.html'
})
export class EMPACHDInformationComponent implements OnInit {
  pagetitle = 'ACH Debit Infomation'
  achdInfo = '';
  baseurl = '';

  constructor(private employerService: EmployerService) { }

  ngOnInit() {
    this.baseurl = CommonSettings.BaseApi;
    this.employerService.getACHDInfo().subscribe(
      result => {
        this.achdInfo = result;
      }
    );
  }
}
