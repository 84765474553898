import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './components/changepassword/changepassword.component';
import { ContactComponent } from './components/contact/contact.component';
import { CreditCardComponent } from './components/creditcard/creditcard.component';
import { FaqComponent } from './components/faq/faq.component';
import { HowTosComponent } from './components/howtos/howtos.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ForgotUsernameComponent } from './components/forgotusername/forgotusername.component';
import { PrivacyPolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { RegisterComponent } from './components/register/register.component';
import { SecurityPolicyComponent } from './components/securitypolicy/securitypolicy.component';
import { TermsAndConditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { WebAccessibilityComponent } from './components/webaccessibility/webaccessibility.component';
import { CreditCardSuccessComponent } from './components/creditcard/creditcard-success/creditcard-success.component';
import { CreditCardCancelComponent } from './components/creditcard/creditcard-cancel/creditcard-cancel.component';
import { LogoutWarningComponent } from './components/logoutwarning/logoutwarning.component';
import { RouterModule } from '@angular/router';
import { BaseLayoutModule } from '../layout/base-layout.module';
import { CommonLibraryModule, HelperLibraryModule, MaterialLibraryModule } from 'epay-library';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { EmployerFAQComponent } from './components/faq/employer-faq/employer-faq.component';
import { NcpFAQComponent } from './components/faq/ncp-faq/ncp-faq.component';
import { PasswordChangeComponent } from './components/changepassword/password-change/password-change.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ngxInputRegulateModule } from 'ngx-input-regulate';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonForgotPasswordComponent } from './components/forgotpassword/forgot-password/forgot-password.component';
import { CommonForgotUsernameComponent } from './components/forgotusername/forgot-username/forgot-username.component';
import { CreditCardSuccessCommonComponent } from './components/creditcard/credit-card-success-common/credit-card-success-common.component';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    ContactComponent,
    CreditCardComponent,
    FaqComponent,
    HowTosComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    RegisterComponent,
    SecurityPolicyComponent,
    TermsAndConditionsComponent,
    WebAccessibilityComponent,
    CreditCardSuccessComponent,
    CreditCardCancelComponent,
    LogoutWarningComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    EmployerFAQComponent,
    NcpFAQComponent,
    PasswordChangeComponent,
    CommonForgotPasswordComponent,
    CommonForgotUsernameComponent,
    CreditCardSuccessCommonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BaseLayoutModule,
    CommonLibraryModule,
    MatExpansionModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    HelperLibraryModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MaterialLibraryModule,
    ngxInputRegulateModule
  ],
  exports: [
    ChangePasswordComponent,
    ContactComponent,
    CreditCardComponent,
    FaqComponent,
    HowTosComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    RegisterComponent,
    SecurityPolicyComponent,
    TermsAndConditionsComponent,
    WebAccessibilityComponent,
    CreditCardSuccessComponent,
    CreditCardCancelComponent,
    LogoutWarningComponent,
  ],
})
export class CommonEpayModule {}
