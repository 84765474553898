import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonEpayModule } from './features/common/common.module';
import { EmployerModule } from './features/employer/employer.module';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './helpers/interceptors/token.interceptor';
import { BusyInterceptor } from './helpers/interceptors/busy.interceptor';
import { UniversalDeviceDetectorService } from './helpers/services/universal.device.detector.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseLayoutModule } from './features/layout/base-layout.module';
import { ApploadModule } from './appload/module/appload.module';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomeComponent } from './features/common/components/home/home.component';
import { FooterComponent } from './features/layout/footer/footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ngxInputRegulateModule } from 'ngx-input-regulate';
import { RequestInterceptor } from './helpers/interceptors/request.interceptor';
import { MatSelectModule } from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ErrorDialogComponent } from './helpers/components/error-dialog/error-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ErrorDialogComponent
  ],
  imports: [
    ApploadModule,
    AppRoutingModule,
    BaseLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonEpayModule,
    EmployerModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgbModule,
    RouterModule,
    AngularMyDatePickerModule,
    ngxInputRegulateModule,
    
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
