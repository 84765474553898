import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { EmployerService } from '../../../services/employer.service';
import { CommonService, CommonSettings, CreditCardService, ErrorHandlerService, PaymentService, PhoneHelperService, ViewportService } from 'epay-library';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StepperOrientation } from '@angular/cdk/stepper';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { IScheduledDay } from 'epay-library/lib/features/common/models/payment/scheduledday';
import { IScheduledFrequency } from 'epay-library/lib/features/common/models/payment/scheduledfrequency';
import { IEMPPaymentList } from 'epay-library/lib/features/employer/models/payment/achd/employerPaymentList';
import { IEMPCCPayment } from 'epay-library/lib/features/employer/models/payment/creditcard/employerCCPayment';
import { IEMPPaymentCollection } from 'epay-library/lib/features/employer/models/payment/employerPaymentCollection';
import * as XLSX from 'xlsx';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IPaymentAccount } from 'epay-library/lib/features/common/models/payment/paymentaccount';
import { ICountry } from 'epay-library';
import { IState } from 'epay-library';
import { AuthService, HelperDialogModel, INCPCCPayment, INCPCases } from 'epay-library';
import { IProcessCreditCard } from 'src/app/helpers/models/creditcard-payment.model';
import PhoneNumber from 'awesome-phonenumber';
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { CreditCardPaymentService } from 'src/app/helpers/services/credit-card-payment.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EMPSecurityCodeHelpComponent } from './empsecurity-code-help/empsecurity-code-help.component';
import { ErrorDialogComponent, ErrorHandlerDialogModel } from 'src/app/helpers/components/error-dialog/error-dialog.component';

type AOA = any[][];

const PayorValidationTypes = {
  scheduldedPayment: 'scheduledPayment',
  creditCard: 'creditCard',
  eftEdiFile: 'eftEdiFile',
};

enum PreviousPayorsType {
  ALL_PAYORS,
  PREVIOUS_PAYORS,
  NONE,
}

interface ExpiryYear {
  value: string;
}

interface ExpiryMonth {
  value: string;
}

interface CreditCardType {
  value: string;
  viewValue: string;
}

function autocompleteExpMonthValidator(validOptions: Array<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    var expMonth = "";
    if (control.value?.length === 1) {
      if (control.value === "1" || control.value === "2" || control.value === "3" || control.value === "4"
      || control.value === "5" || control.value === "6" || control.value === "7" || control.value === "8" || control.value === "9") {
        expMonth = "0" + control.value;
      }
      else {
        return null;
      }
    }
    else {
      expMonth = control.value;
    }

    if (validOptions.indexOf(expMonth) !== -1) {
      return null
    }
    else {
      return { 'invalidExpMonth': true }
    }
    
  }
}

function autocompleteExpYearValidator(validOptions: Array<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (validOptions.indexOf(control.value) !== -1) {
      return null
    }
    else {
      return { 'invalidExpYear': true }
    }
    
  }
}


@Component({
  selector: 'epay-creditcard',
  templateUrl: './creditcard.payment.component.html'
})
export class EMPCreditCardPaymentComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isEditPayment: boolean = false;
  @Input() validationType = '';
  @Input() editPayorCollection: IEMPPaymentCollection[] = [];
  @Input() selectedPaymentAccount: IPaymentAccount = {} as IPaymentAccount;
  @Output() validPayorEmitter_OK = new EventEmitter<{
    payorCollection: IEMPPaymentCollection[];
  }>();
  @Output() validPayorCreditCardEmitter_OK = new EventEmitter<{
    creditCardPayment: IEMPCCPayment;
  }>();

  @ViewChild('LastName', { static: false }) nameField!: ElementRef;
  @ViewChild('inputFile', { static: false }) inputFile!: ElementRef;
  
  pagetitle = 'Employer: Create Credit Card Payment';
  baseurl= '';
  secondaryIdAlias = 'Family Group #'
  clientName= '';
  termsContent= '';
  creditCardUrl= '';
  creditCardOriginKey= '';
  validateCaseNumber = false;
  allowZeroAmount = false;
  isMobile = false;
  creditCardVendorId = 0;
  creditCardDisclaimer = '';
  fortPointStateCode = '';

  data: any;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  payorChkType = PreviousPayorsType.NONE;
  currentlyChecked = PreviousPayorsType.NONE;

  employerEIN = '';

  stepperOrientation: StepperOrientation = 'horizontal';

  //Payor Collection arrays
  previousPaymentPayors: IEMPPaymentCollection[] = [];
  previousAllPayors: IEMPPaymentCollection[] = [];
  payorCollection: IEMPPaymentCollection[] = [];

  termsFrmGp: FormGroup = new FormGroup({});
  reviewFrmGrp: FormGroup = new FormGroup({});
  paymentcollectionFrmGp: FormGroup = new FormGroup({});
  paymentCollection: IEMPPaymentCollection[];
  paymentheader: string[] = [
    'Last Name',
    'SSN',
    'FGN',
    'Amount',
  ];
  empCCPayment: IEMPCCPayment = {} as IEMPCCPayment;
  scheduledFrequencies: IScheduledFrequency[];
  scheduledDaysWeek: IScheduledDay[];
  scheduledDaysBiMonth: IScheduledDay[];
  scheduledDaysMonth: IScheduledDay[];
  scheduledDays: IScheduledDay[];
  scheduledpayments: IEMPPaymentList[] = [];

  today = new Date();
  maxDate = new Date();
  minDate = new Date();

  transactionId = '';
  encryptedTransactionId = '';

  billingSectionIncomplete = false;
  ccSectionIncomplete = false;

  attemptedCCAttempt = false;

  ssnHide = true;
  startDate = '';
  showPaymentCollectionForm: boolean;
  numOfPayments = 0;
  totalAmtOfPayments = 0;
  numInvalid = 0;
  numWarning = 0;
  iseditccPayment = false;
  iseditccPaymentCollection = false;
  editccPaymentCollectionIndex: number;
  errorMessage = '';
  validationErrorMessage = '';
  showScheduleDay: boolean;
  selectedFrequency = '';
  selectedFrequencyCode = '';
  selectedDay = '';
  selectedDayCode = '';
  nextScheduledDate = '';
  noPreviousPayors = '';
  isLoadingPreviousPayors = false;

  scheduleddayCtrl: any;

  confirmationSender = '';
  confirmationTitle = '';
  confirmationMessage = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';
  openConfirmation = false;
  isLoadingEdit = false;
  isTermsSubmitted = false;
  allChecked = false;

  animateInput = false;
  atLeastOnePayorSelected = false;
  validateAttemptMade = false;
  insertUpdateError = false;
  nameChangeOccured = false;
  insertUpdateErrorMessage = '';
  isBankAccountSelected = false;

  fileImportHint = `File must contain 4 columns with headers titled "Last Name", "SSN", "Case Number", "Amount"`;
  fileErrorMessage = '';
  payorDetailError = '';
  calendarHint = '';

  toggleEditPayor = false;
  toggleImportPayor = false;

  currencyPattern = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
  ssnPattern = /^(?!000|666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;

  //Form Group for payor collection
  payorFrmGp: FormGroup = new FormGroup({});

  //Edit payor collection
  isEditPayor = false;
  editPayorIndex = 0;

  fileImported = false;
  isFileValid = false;

  showPayorCollectionForm = false;
  payorsValid = false;
  isValdiatingPayment = false;

  previousPayorsChecked = false;
  allPayorsChecked = false;

  isCreditCardValidation: boolean = false;
  isEftFileValidation: boolean = false;
  isScheduledPaymentValidation: boolean = false;

  ccSecurityMessage = '';
  civitekOriginKey = '';
  createPaymentButtonText = '';
  forePointAccessToken = '';
  forePointStateCode = '';
  validateFGNumber = false;
  canMakePaymentsToClosedCases = false;
  isLoggedIn = false;

  ccInfo = {
    cardNumber: null,
    cvc: null,
    expMonth: null,
    expYear: null,
    holder: null
  }

  requestId = '';

  cardExpired = false;
  flipCard = false;
  cardSide = 0;
  defaultCC = 'mastercard';

  totalCCPayment = 0;

  states: IState[] = [];
  countries: ICountry[] = [];
  selectedCountry = '';

  ccFrmGp: FormGroup = new FormGroup({});
  casesearchFrmGp: FormGroup = new FormGroup({});
  paymentDetailFrmGp: FormGroup = new FormGroup({});

  paymentaccountheader: string[] = [
    'Select',
    'Name',
    'Type',
    'Account',
    'Status',
  ];
  cases: INCPCases[] = [];
  creditCardPayment: IEMPCCPayment = {} as IEMPCCPayment;
  creditCardObj: IProcessCreditCard = {} as IProcessCreditCard;

  panelOpenState = false;

  months: ExpiryMonth[] = [];
  years: ExpiryYear[] = [];

  filteredExpMonths$!: Observable<string[]>;
  expMonths: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  expMonthCtrl = new FormControl();

  filteredExpYears$!: Observable<string[]>;
  expYears: string[] = [];
  expYearCtrl = new FormControl();

  ccExpired = false;


  creditcardType: CreditCardType[] = [];
  currentYear!: any;

  caseIsValid = false;
  isValdiatingParent = false;
  hasAttemptedSearch = false;
  selectedCase = '';
  amount = 0;
  hasHold: any;
  //hasHold = true;
  cvvHide = true;
  ccHide = true;
  showValidationMessage = false;

  ncpAccountData = [];

  goToCCSuccess = false;

  phoneMask = '';
  phoneExample = '';

  dialogData: any;
  dialogRef: any;
  deleteResult = '';

  ccMask = '0000 0000 0000 0000'; //mastercard, visa
  ccRegex = '^5[1-5][0-9]{14}$'; //mastercard
  ccImgURL = '../../../../../assets/images/Icons/icons8-mastercard.svg'; //mastercard
  expiryRegex = '^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$'

  firstNameCtrl: any;
  lastNameCtrl: any;
  ssnCtrl: any;
  caseNumberCtrl: any;

  emailPattern = /\S+@\S+\.\S+/;
  numberPattern = /^[0-9]\d*$/;
  creditcardPattern = `^(3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})\d{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}))$`
  zipPattern = /^[0-9]{5}$/;

  // Datepicker settings
  dateSettingsOptions: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su',
    closeSelectorOnDateSelect: true,
    sunHighlight: false,
    disableWeekends: true,
    disableUntil: {
      year: 0,
      month: 0,
      day: 0,
    },
  };

  constructor(
    private paymentService: PaymentService,
    private employerService: EmployerService,
    private errorHandlerService: ErrorHandlerService,
    private ccPaymentService: CreditCardPaymentService,
    private creditCardService: CreditCardService,
    private formbuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private viewpointService: ViewportService,
    private commonService: CommonService,
    private phoneHelperService: PhoneHelperService,
    public tooltip: MatTooltipModule,
    private authService: AuthService
    ) {
    this.baseurl = CommonSettings.BaseApi;
    this.clientName = CommonSettings.ClientName;
    this.validateCaseNumber = true;
    this.allowZeroAmount = false;
    this.creditCardVendorId = CommonSettings.CreditCardVendorID;

    this.iseditccPaymentCollection = false;
    this.showPaymentCollectionForm = false;
    this.showScheduleDay = false;
    this.paymentCollection = [];
    this.scheduledDaysWeek = [];
    this.scheduledDaysBiMonth = [];
    this.scheduledDaysMonth = [];
    this.scheduledDays = [];
    this.scheduledFrequencies = [];
    this.editccPaymentCollectionIndex = -1;
    this.maxDate.setDate(
      this.today.getDate() + +CommonSettings.NumOfDaysOutForCalendar
    );

    this.validateFGNumber = true;
    this.allowZeroAmount = false;

    this.selectedCountry = 'US';

    this.createPaymentButtonText = 'Pay Now';
  }

  ngOnInit() {
    let currentDate = new Date();
    this.currentYear = currentDate.getFullYear();

    this.expYears = [
      this.currentYear.toString(),
      (this.currentYear + 1).toString(),
      (this.currentYear + 2).toString(),
      (this.currentYear + 3).toString(),
      (this.currentYear + 4).toString(),
      (this.currentYear + 5).toString(),
      (this.currentYear + 6).toString(),
      (this.currentYear + 7).toString(),
      (this.currentYear + 8).toString(),
      (this.currentYear + 10).toString(),
    ];

    this.expMonthCtrl = new FormControl('', { validators: [ Validators.required]});
    this.expYearCtrl = new FormControl('', { validators: [Validators.required]});

    this.filteredExpMonths$ = this.expMonthCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterMonthValue(value))
    );

    this.filteredExpYears$ = this.expYearCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterYearValue(value))
    );

    this.expMonthCtrl.valueChanges.pipe(debounceTime(1500)).subscribe((month: any) => {
      if (month && month.length === 1) {
        var expMonth = "";
        if (month === "1" || month === "2" || month === "3" || month === "4"
        || month === "5" || month === "6" || month === "7" || month === "8" || month === "9") {
          expMonth = "0" + month;
          this.expMonthCtrl.setValue(expMonth);
        }
      }

      if (month && this.expYearCtrl.value) {
        this.ccExpired = this.isCCExpired(month, this.expYearCtrl.value);
        console.log("CCExpired1: ", this.ccExpired)

        if (this.ccExpired) {
          this.ccFrmGp.setErrors({expired: true})
        }
        else {
          this.ccFrmGp.setErrors({expired: null})
          this.ccFrmGp.get('ccExpiryMonth')!.setValue(month);
          this.ccFrmGp.get('ccExpiryYear')!.setValue(this.expYearCtrl.value);
        }
      } 
    });

    this.expYearCtrl.valueChanges.pipe(debounceTime(1500)).subscribe((year: any) => {
      if (year && this.expMonthCtrl.value) {
        this.ccExpired = this.isCCExpired(this.expMonthCtrl.value, year);
        console.log("CCExpired2: ", this.ccExpired)

        if (this.ccExpired) {
          this.ccFrmGp.setErrors({expired: true})
        }
        else {
          this.ccFrmGp.setErrors({expired: null})
          this.ccFrmGp.get('ccExpiryYear')!.setValue(year);
          this.ccFrmGp.get('ccExpiryMonth')!.setValue(this.expMonthCtrl.value);
        }
      }
    });
    

    this.creditcardType = [
      {value: 'mastercard', viewValue: 'MasterCard'},
      {value: 'visa', viewValue: 'Visa'},
    ]

    this.employerService.getEmployerTerms().subscribe(
      result => {
        this.termsContent = result;
      }
    );

    this.validationType = PayorValidationTypes.creditCard;
    this.loadFormGroups();
    this.getCountries();
    this.getStates();
    this.setValidationType();

    if (this.secondaryIdAlias === '') {
      this.secondaryIdAlias = 'Case Number';
    }

    this.payorFrmGp = this.formbuilder.group({
      filename: ' Choose File',
      collectionFrmGp: this.formbuilder.group({
        lastname: ['', Validators.minLength(1)],
        ssn: [''],
        casenumber: ['', Validators.minLength(1)],
        amount: ['', Validators.pattern(this.currencyPattern)],
      }),
      isValid: ['false', Validators.requiredTrue],
    });

    // if (this.secondaryIdAlias !== '') {
    //   this.paymentheader = [
    //     'Last Name',
    //     'SSN',
    //     'FGN',
    //     'Amount',
    //   ];
    // } else {
    //   this.secondaryIdAlias = 'Case Number';
    // }

    this.viewpointService.StepperOrient.subscribe(
      (orient) => (this.stepperOrientation = orient)
    );

    // Credit Card Payment controls
    const phoneCtrl = this.ccFrmGp.get('phoneGp.phoneNumber');
    const countryCtrl = this.ccFrmGp.get('country');

    phoneCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value) {
        const pn = new PhoneNumber(
          value,
          this.ccFrmGp.get('phoneGp.regionCode')!.value
        );
        this.ccFrmGp.get('phoneGp')!.patchValue({
          isValid: pn.isPossible(),
        });
      }
    });

    countryCtrl!.valueChanges.subscribe((value) => {
      if (value) {
        this.setMaskAndExample(value);
        this.ccFrmGp.patchValue({
          state: '',
          zip5: '',
        });
        this.ccFrmGp.get('phoneGp')!.patchValue({
          regionCode: value,
          countryCode:
            this.phoneHelperService.getCountryCodeForRegionCode(value),
          phoneNumber: '',
          isValid: false,
        });
        if (value === 'US') {
          this.ccFrmGp
            .get('zip5')!
            .setValidators([
              Validators.required,
              Validators.pattern(this.zipPattern),
            ]);
          this.ccFrmGp.get('zip5')!.updateValueAndValidity();
        } else {
          this.ccFrmGp
            .get('zip5')!
            .setValidators([Validators.required, Validators.maxLength(6)]);
          this.ccFrmGp.get('zip5')!.updateValueAndValidity();
        }
        if (value === 'US' || value === 'MX' || value === 'CA') {
          this.selectedCountry = value;
          this.ccFrmGp.get('state')!.enable();
          this.ccFrmGp.get('state')!.setErrors({ required: true });
          this.ccFrmGp.updateValueAndValidity();
          this.getStates();
        } else {
          this.ccFrmGp.get('state')!.disable();
          this.ccFrmGp.get('state')!.setErrors({ required: false });
          this.ccFrmGp.updateValueAndValidity();
        }
      }
    });

    this.ccFrmGp.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.attemptedCCAttempt) {
        if (!this.ccFrmGp.valid) {
          if (
            (
              !this.ccFrmGp.get('ccFirstName')?.value ||
            !this.ccFrmGp.get('ccLastName')?.value ||
            !this.ccFrmGp.get('address1')?.value ||
            !this.ccFrmGp.get('city')?.value ||
            !this.ccFrmGp.get('state')?.value ||
            !this.ccFrmGp.get('zip5')?.value ||
            !this.ccFrmGp.get('email')?.value ||
            !this.ccFrmGp.get('phoneGp.phoneNumber')?.value
            ) || (
            !this.ccFrmGp.get('ccFirstName')?.valid ||
            !this.ccFrmGp.get('ccLastName')?.valid ||
            !this.ccFrmGp.get('address1')?.valid ||
            !this.ccFrmGp.get('city')?.valid ||
            !this.ccFrmGp.get('state')?.valid ||
            !this.ccFrmGp.get('zip5')?.valid ||
            !this.ccFrmGp.get('email')?.valid ||
            !this.ccFrmGp.get('phoneGp.phoneNumber')?.valid
            ))
           {
            this.billingSectionIncomplete = true;
            console.log("Billing missing")
          } else {
            this.billingSectionIncomplete = false;
          }
  
          if (
            (
            !this.ccFrmGp.get('ccNumber')?.value || 
            !this.ccFrmGp.get('ccExpiryMonth')?.value ||
            !this.ccFrmGp.get('ccExpiryYear')?.value ||
            !this.ccFrmGp.get('cvv')?.value
            ) || 
            (
              !this.ccFrmGp.get('ccNumber')?.valid || 
              !this.ccFrmGp.get('ccExpiryMonth')?.valid ||
              !this.ccFrmGp.get('ccExpiryYear')?.valid ||
              !this.ccFrmGp.get('cvv')?.valid
            )) {
              this.ccSectionIncomplete = true;
              console.log("cc missing")
          } else {
            this.ccSectionIncomplete = false;
          }
        }
        else {
          this.billingSectionIncomplete = false;
          this.ccSectionIncomplete = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    // this.expMonTrigger.panelClosingActions.subscribe((e) => {
    //   console.log("E ", e)
    //   if (!(e && e.source)) {
    //     // clear value if is not from the filtered list
    //     this.expMonthCtrl.setValue('');
    //     this.expMonTrigger.closePanel();
    //   }
    // });

    // this.expYearTrigger.panelClosingActions.subscribe((e) => {
    //   console.log("E ", e)
    //   if (!(e && e.source)) {
    //     // clear value if is not from the filtered list
    //     this.expYearCtrl.setValue('');
    //     this.expYearTrigger.closePanel();
    //   }
    // });
  }

  ngOnChanges(): void {
    if (this.isEditPayment) {
      this.payorCollection = this.editPayorCollection;
    }
  }

  isCCExpired(month: string, year: string): boolean {
    var dateVal = month + "/01/" + year;
    var ccDate = new Date(dateVal);
    var currentDate = new Date();

    //console.log("Date val: ", dateVal)
    //console.log("CCDate ", ccDate)
    //console.log("CurrentDate ", currentDate)

    if (ccDate < currentDate) {
      var expDate = new Date(Number(month)+1 + "/01/" + year);
      //console.log("EXP DATE: ", expDate)
      return expDate < currentDate
    }
    else {
      return false;
    }
  }

  filterMonthValue(value: string): string[] {
    if (value === '') {
      return this.expMonths.slice();
    }

    const filterValue = value.toLowerCase();
    return this.expMonths.filter(
      (option: any) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  filterYearValue(value: string): string[] {
    if (value === '') {
      return this.expYears.slice();
    }

    const filterValue = value.toLowerCase();
    return this.expYears.filter(
      (option: any) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  createCCPaymentAttempt() {
  this.attemptedCCAttempt = true;

  console.log("****** ", this.ccFrmGp)
  console.log("NOT VALID: ", !this.ccFrmGp.valid)

  if (!this.ccFrmGp.valid) {
    if (
      !this.ccFrmGp.get('ccFirstName')?.value
       ||
      !this.ccFrmGp.get('ccLastName')?.value ||
      !this.ccFrmGp.get('address1')?.value ||
      !this.ccFrmGp.get('city')?.value ||
      !this.ccFrmGp.get('state')?.value ||
      !this.ccFrmGp.get('zip5')?.value ||
      !this.ccFrmGp.get('email')?.value ||
      !this.ccFrmGp.get('phoneGp.phoneNumber')?.value
    ) {
      this.billingSectionIncomplete = true;
      console.log("Billing missing")
    }

    if (
      !this.ccFrmGp.get('ccNumber')?.value || 
      !this.ccFrmGp.get('ccExpiryMonth')?.value ||
      !this.ccFrmGp.get('ccExpiryYear')?.value ||
      !this.ccFrmGp.get('cvv')?.value) {
        this.ccSectionIncomplete = true;
        console.log("cc missing")
    }

    if (this.ccFrmGp?.hasError('expired') || this.expMonthCtrl.hasError('invalidExpMonth') || this.expYearCtrl.hasError('invalidExpYear')) {
      this.ccSectionIncomplete = true;
    }
  }

  else {
    this.creditCardPayment.confirm = true;

    this.creditCardPayment.amount = this.totalAmtOfPayments;
    this.totalCCPayment = this.creditCardPayment.totalAmount;
    this.creditCardPayment.firstName = this.ccFrmGp.get('ccFirstName')?.value;
    this.creditCardPayment.lastName = this.ccFrmGp.get('ccLastName')?.value;
    this.creditCardPayment.addressLine1 = this.ccFrmGp.get('address1')?.value;
    this.creditCardPayment.addressLine2 = this.ccFrmGp.get('address2')?.value;
    this.creditCardPayment.addressCity = this.ccFrmGp.get('city')?.value;
    this.creditCardPayment.addressState = this.ccFrmGp.get('state')?.value;
    this.creditCardPayment.addressCountry = this.ccFrmGp.get('country')?.value;
    this.creditCardPayment.addressZIP5 = this.ccFrmGp.get('zip5')?.value;
    this.creditCardPayment.email = this.ccFrmGp.get('email')?.value;
    this.creditCardPayment.phone = this.ccFrmGp.get('phoneGp.phoneNumber')?.value;

    //console.log("CC PAYMENT ATTEMPT PAYLOAD: ", this.creditCardPayment)

    this.creditCardService
      .insertEmployerCreditCardAttempt(this.baseurl, this.creditCardPayment)
      .subscribe({
        next: (success: any) => {

          this.transactionId = success.value.transactionId;

          if (this.transactionId === '') {
            console.log(
              'Did not receieve transactionId for Credit Card payment...'
            );
          } else {
            //this.authService.logout(this.baseurl, true);
          }
        },
        error: (error: any) => {
          console.log("Failing here #2")
          this.logError(error);
          this.handleError(error.status);
        },
      });
    }
  }

  processCreditCard() {
    this.createPaymentButtonText = 'Processing....';
    
    let firstName = this.ccFrmGp.get('ccFirstName')?.value;
    let lastName = this.ccFrmGp.get('ccLastName')?.value;
    let cvv = this.ccFrmGp.get('cvv')?.value;
    let ccNumber = this.ccFrmGp.get('ccNumber')?.value;
    let ccExpiryMonth = this.ccFrmGp.get('ccExpiryMonth')?.value;
    let ccExpiryYear = this.ccFrmGp.get('ccExpiryYear')?.value;
    let address1 = this.ccFrmGp.get('address1')?.value;
    let address2 = this.ccFrmGp.get('address2')?.value;
    let city = this.ccFrmGp.get('city')?.value;
    let state = this.ccFrmGp.get('state')?.value;
    let zip = this.ccFrmGp.get('zip5')?.value;

    this.creditCardObj = {
      transactionId: this.transactionId,
      firstName: firstName,
      lastName: lastName,
      accountNumber: ccNumber,
      expirationMonth: ccExpiryMonth,
      expirationYear: ccExpiryYear,
      securityCode: cvv,
      isEmployer: true,
      addressLine1: address1,
      addressLine2: address2,
      addressCity: city,
      addressState: state,
      addressZIP5: zip
    };

    console.log("Process CC Obj: ", this.creditCardObj);

    this.ccPaymentService.insertProcessCreditCard(
      this.baseurl, this.creditCardObj
    ).subscribe({
      next: (success: any) => {

        console.log("Process CC Response: ", success)
        console.log("Transaction ID: ", this.transactionId)

        this.encryptedTransactionId = success.encodedTransactionId!

        if (success) {
          this.authService.logout(CommonSettings.BaseApi);
          this.router.navigate(['/creditcardsuccess'], {queryParams: {tid: this.encryptedTransactionId}});
        }
      },
      error: (error: any) => {
        //this.logError(error);
        //this.handleError(error.status);
        console.log("ERR: ", error)

        this.createPaymentButtonText = 'Pay Now';

          if (error.error) {
            var errTitle = '';
            var errMsg = '';

            if (error.error.length !== 0) {
              error.error.forEach((res: any) => {
                if (res.errorCode + ', ' !== errTitle) {
                  errTitle += res.errorCode + ', ';
                }
                errMsg += res.errorMessage + ', ';
              });
            }
  
            this.dialogData = new ErrorHandlerDialogModel(
              "Error Occurred: Internal Error Occurred",
              "Error Code: " + errTitle,
              errMsg,
              '',
              '',
              '',
              true
            );
    
            this.dialogRef = this.dialog.open(ErrorDialogComponent, {
              maxWidth: '800px',
              data: this.dialogData,
            });
  
            this.dialogRef.afterClosed().subscribe((dialogResult: string) => {
              this.deleteResult = dialogResult;
              if (this.deleteResult) {
                  //this.location.back();
              }
            });
          }
          else {
            this.logError(error);
            this.handleError(error.status);
          }

      },
    });
  }

  securityCodeHelp() {
    this.dialog.open(EMPSecurityCodeHelpComponent);
  }

  rotateCard(control: string) {
    if (control === 'cvv') {
      if (this.cardSide === 0) {
        this.flipCard = !this.flipCard;
        this.cardSide = 1;
      }
    }

    if (control === 'other') {
      if (this.cardSide === 1) {
        this.flipCard = !this.flipCard;
        this.cardSide = 0;
      }
    }

  }

  setMaskAndExample(regionCode: string) {
    this.phoneExample = this.phoneHelperService.getExample(regionCode);
    this.phoneMask = this.phoneHelperService.getMask(regionCode);
  }

  toggleCVVHide() {
    this.cvvHide = !this.cvvHide;
  }

  toggleCCHide() {
    this.ccHide = !this.ccHide;
  }

  emitValidPayorPerValidation() {
    if (this.isScheduledPaymentValidation) {
      this.validPayorEmit();
    } else if (this.isCreditCardValidation) {
      this.validCreditCardEmit();
    }
  }

  validPayorEmit() {
    if (this.payorsValid) {
      this.validPayorEmitter_OK.emit({
        payorCollection: this.payorCollection,
      });
    }
  }

  validCreditCardEmit() {
    this.creditCardPayment = {
      batchType: 'WEB',
      payerType: 'EMP',
      confirm: false,
      fee: 0,
      feePercentage: 0,
      amount: this.totalAmtOfPayments,
      totalAmount: this.totalAmtOfPayments,
      participantCollection: this.payorCollection,
      firstName: this.ccFrmGp.get('ccFirstName')?.value ? this.ccFrmGp.get('ccFirstName')?.value : '',
      lastName: this.ccFrmGp.get('ccLastName')?.value ? this.ccFrmGp.get('ccLastName')?.value : '',
      ssn: '',
      addressLine1: this.ccFrmGp.get('address1')?.value ? this.ccFrmGp.get('address1')?.value : '',
      addressLine2: this.ccFrmGp.get('address2')?.value ? this.ccFrmGp.get('address2')?.value : '',
      addressCity: this.ccFrmGp.get('city')?.value ? this.ccFrmGp.get('city')?.value : '',
      addressState: this.ccFrmGp.get('state')?.value ? this.ccFrmGp.get('state')?.value : '',
      addressCountry: this.ccFrmGp.get('country')?.value ? this.ccFrmGp.get('country')?.value : '',
      addressZIP5: this.ccFrmGp.get('zip5')?.value ? this.ccFrmGp.get('zip5')?.value : '',
      email: this.ccFrmGp.get('email')?.value ? this.ccFrmGp.get('email')?.value : '',
      phone: this.ccFrmGp.get('phoneGp.phoneNumber')?.value ? this.ccFrmGp.get('phoneGp.phoneNumber')?.value : ''
    };

    this.creditCardService
      .insertEmployerCreditCardAttempt(this.baseurl, this.creditCardPayment)
      .subscribe({
        next: (response: any) => {
          this.creditCardPayment.fee = response.value.convenienceAmount;
          this.creditCardPayment.feePercentage =
            response.value.convenienceFeePercentage;
          this.creditCardPayment.totalAmount = response.value.totalPaymentAmount;
          this.creditCardPayment.participantCollection = this.payorCollection;
        },
        error: (error: any) => {
          this.logError(error);
          this.handleError(error.status);
        },
        complete: () => {
          this.validPayorCreditCardEmitter_OK.emit({
            creditCardPayment: this.creditCardPayment,
          });
        },
      });
  }

  validatePayors() {
    this.numInvalid = 0;
    this.numWarning = 0;
    this.noPreviousPayors = '';
    this.validationErrorMessage = '';
    this.isValdiatingPayment = true;
    this.validateAttemptMade = true;
    this.nameChangeOccured = false;

    const allowZeroAmount = false;

    let foundError = false;

    if (this.atLeastOnePayorSelected) {
      this.payorCollection = this.payorCollection.filter((x) => x.isSelected);
      this.payorCollection.forEach((payor) => {
        payor.hasError = false;
        payor.isValidated = false;
        payor.errorMessage = '';
        // Check for invalid payors
        if (payor.amount === null || payor.amount === undefined) {
          foundError = true;
          payor.hasError = true;
          payor.isValidated = true;
          payor.errorMessage = 'Missing Amount';
        } else if (
          Number.isNaN(payor.amount) ||
          payor.amount === 0 ||
          payor.amount < 1.0 ||
          payor.amount > 9999.99
        ) {
          foundError = true;
          payor.hasError = true;
          payor.isValidated = true;
          payor.errorMessage = 'Invalid Amount';
        } else if (
          this.validateCaseNumber &&
          (payor.caseNumber === null || payor.caseNumber === '')
        ) {
          foundError = true;
          payor.hasError = true;
          payor.isValidated = true;
          payor.errorMessage = 'Missing ' + this.secondaryIdAlias;
        } else {
          payor.hasError = false;
          payor.isValidated = false;
          payor.errorMessage = '';
        }
        payor.ssn = payor.ssn.toString().replace(/-/g, '');
        payor.ssn = payor.ssn.toString().replace(/\s/g, '');
        payor.hadEdit = false;
      });

      if (foundError) {
        let foundInvalidCount = 0;
        this.payorsValid = false;
        this.isValdiatingPayment = false;
        this.payorFrmGp.patchValue({
          isValid: this.payorsValid,
        });
        this.payorCollection.forEach((payor) => {
          if (payor.hasError) {
            foundInvalidCount++;
          }
        });
        this.numInvalid = foundInvalidCount;
      }

      if (
        this.payorCollection.length > 0 &&
        this.atLeastOnePayorSelected &&
        !foundError
      ) {
        this.payorCollection.forEach((payor) => {
          //Update ssn to all 0s if blank
          if (payor.ssn.length === 0) {
            payor.ssn = '000000000';
          }
          payor.caseNumber = payor.caseNumber?.toString().padStart(9, '0');
        });

        this.paymentService
          .isValidPayment(
            this.baseurl,
            this.payorCollection,
            allowZeroAmount.toString(),
            this.validateCaseNumber.toString()
          )
          .subscribe({
            next: (valid: any) => {
              this.payorCollection.forEach((element) => {
                element.hasError = false;
                element.hasWarning = false;
                element.isValidated = true;
              });
              this.payorsValid = true;
              this.nameChangeOccured = false;
              this.payorFrmGp.patchValue({
                isValid: this.payorsValid,
              });
              this.isValdiatingPayment = false;
            },
            error: (error: any) => {
              this.payorsValid = false;
              this.isValdiatingPayment = false;
              this.payorFrmGp.patchValue({
                isValid: this.payorsValid,
              });
              if (error.status === 400) {
                // First, reset all payments hasError to false
                this.payorCollection.forEach((element) => {
                  element.isValidated = true;
                  element.hasError = false;
                  element.hasWarning = false;
                  element.errorMessage = '';
                });
                // Second, add hasError to those indexes returned
                error.error.invalidParticipantPositions.forEach(
                  (invalidPayor: any) => {
                    if (
                      invalidPayor.correctedName !== undefined &&
                      invalidPayor.correctedName !== null &&
                      invalidPayor.correctedName !== ''
                    ) {
                      this.nameChangeOccured = true;
                      this.payorCollection[invalidPayor.index].hasWarning =
                        true;
                      this.payorCollection[invalidPayor.index].lastName =
                        invalidPayor.correctedName;
                      this.payorCollection[invalidPayor.index].errorMessage =
                        invalidPayor.error;
                      this.numWarning++;
                    } else {
                      this.payorCollection[invalidPayor.index].hasError = true;
                      this.payorCollection[invalidPayor.index].errorMessage =
                        invalidPayor.error;
                      this.numInvalid++;
                    }
                  }
                );
                this.validationErrorMessage = error.error.failureMessage;
                this.validationErrorMessage =
                  this.validationErrorMessage.replace(/^"|"$/g, '');
              } else {
                this.logError(error);
                this.handleError(error.status);
              }
            },
            complete: () => {
              if (this.payorsValid) {
                this.emitValidPayorPerValidation();
              }
            },
          });
      }
    } else {
      this.isValdiatingPayment = false;
    }
  }

  getPreviousPaymentPayors(event: any) {
    this.toggleEditPayor = false;
    this.payorsValid = false;
    this.numInvalid = 0;
    this.numWarning = 0;
    this.isFileValid = false;
    this.fileImported = false;
    this.noPreviousPayors = '';
    this.nameChangeOccured = false;
    this.isLoadingPreviousPayors = false;
    this.previousPaymentPayors = [];
    this.previousAllPayors = [];
    this.payorCollection = [];

    let lastPaymentIndex = 0;
    this.payorFrmGp.patchValue({
      filename: '',
      isValid: this.payorsValid,
    });
    this.payorFrmGp.updateValueAndValidity();

    if (!event.target.checked) {
      this.previousPayorsChecked = false;
    } else {
      this.previousPayorsChecked = true;
      this.allPayorsChecked = false;
      this.isLoadingPreviousPayors = true;

      this.paymentService
        .getPayments(
          this.baseurl,
          this.selectedPaymentAccount.scheduledPaymentAccountKey,
          true
        )
        .subscribe({
          next: (payments: any) => {
            this.scheduledpayments = payments;
            //Filter cancelled payments that don't have payor listed anymore
            this.scheduledpayments.filter(
              (p) => p.participantCollection.length > 0
            );

            //If we have scheduled payment left after filter, sort them and add to payment Collection for view
            if (this.scheduledpayments.length > 0) {
              this.scheduledpayments.sort(
                (a, b) =>
                  <any>new Date(a.scheduleStartDate) -
                  <any>new Date(b.scheduleStartDate)
              );

              lastPaymentIndex = this.scheduledpayments.length - 1;

              this.previousPaymentPayors =
                this.scheduledpayments[lastPaymentIndex].participantCollection;
              this.previousPaymentPayors.forEach((payor) => {
                payor.isSelected = false;
                payor.isValidated = false;
                payor.hasError = false;
                payor.caseNumber = payor.secondaryId;
              });
              this.payorCollection = this.payorCollection.concat(
                this.previousPaymentPayors
              );
              this.isLoadingPreviousPayors = false;
              this.allChecked = false;
            } else {
              this.noPreviousPayors =
                'No previous payors found for the selected bank account.';
              this.isLoadingPreviousPayors = false;
            }
          },
          error: (error: any) => {
            this.isLoadingPreviousPayors = false;
            if (error.status !== 404) {
              this.logError(error);
              this.handleError(error.status);
            } else {
              this.noPreviousPayors =
                'No previous payors found for the selected bank account.';
              this.isLoadingPreviousPayors = false;
            }
          },
        });
    }
  }

  getAllPreviousPayors(event: any) {
    this.toggleEditPayor = false;
    this.payorsValid = false;
    this.numInvalid = 0;
    this.numWarning = 0;
    this.isFileValid = false;
    this.fileImported = false;
    this.noPreviousPayors = '';
    this.nameChangeOccured = false;
    this.isLoadingPreviousPayors = false;
    this.previousPaymentPayors = [];
    this.previousAllPayors = [];
    this.payorCollection = [];

    this.payorFrmGp.patchValue({
      filename: '',
      isValid: this.payorsValid,
    });
    this.payorFrmGp.updateValueAndValidity();

    if (!event.target.checked) {
      this.allPayorsChecked = false;
    } else {
      this.allPayorsChecked = true;
      this.previousPayorsChecked = false;

      this.isLoadingPreviousPayors = true;

      this.paymentService
        .getPayments(
          this.baseurl,
          this.selectedPaymentAccount.scheduledPaymentAccountKey,
          true
        )
        .subscribe({
          next: (payments: any) => {
            this.scheduledpayments = payments;
            //Filter cancelled payments that don't have payor listed anymore
            this.scheduledpayments.filter(
              (p) => p.participantCollection.length > 0
            );

            //If we have scheduled payment left after filter, sort them and add to payment Collection for view
            if (this.scheduledpayments.length > 0) {
              this.scheduledpayments.sort(
                (a, b) =>
                  <any>new Date(a.scheduleStartDate) -
                  <any>new Date(b.scheduleStartDate)
              );

              //Get all previous participants
              this.scheduledpayments.forEach((payment) => {
                this.previousAllPayors = this.previousAllPayors.concat(
                  payment.participantCollection
                );
              });
              //Set defaults for all previous participants
              this.previousAllPayors.forEach((prevPayor) => {
                prevPayor.isSelected = false;
                prevPayor.hasError = false;
                prevPayor.isValidated = false;
                prevPayor.caseNumber = prevPayor.secondaryId;
              });
              //Remove duplicates with same name, ssn, and case number
              this.previousAllPayors = this.previousAllPayors.filter(
                (v, i, a) =>
                  a.findIndex(
                    (t) =>
                      t.lastName.toUpperCase() === v.lastName.toUpperCase() &&
                      t.ssn === v.ssn &&
                      t.caseNumber === v.caseNumber
                  ) === i
              );

              //Set previous to collection for UI display
              this.payorCollection = this.previousAllPayors;

              //Set loading values for display
              this.isLoadingPreviousPayors = false;
              this.allChecked = false;
            }
          },
          error: (error: any) => {
            this.isLoadingPreviousPayors = false;
            if (error.status !== 404) {
              this.logError(error);
              this.handleError(error.status);
            } else {
              this.noPreviousPayors =
                'No previous payors found for the selected bank account.';
              this.isLoadingPreviousPayors = false;
            }
          },
        });
    }
  }

  toggleSSNHide() {
    this.ssnHide = !this.ssnHide;
  }

  onFileChange(evt: any) {
    this.previousPayorsChecked = false;
    this.allPayorsChecked = false;
    this.payorsValid = false;
    this.numInvalid = 0;
    this.numWarning = 0;
    this.isFileValid = false;
    this.noPreviousPayors = '';
    this.nameChangeOccured = false;

    let headerRowIndex = -1;
    let lastNameHeaderIndex = -1;
    let ssnHeaderIndex = -1;
    let caseHeaderIndex = -1;
    let amountHeaderIndex = -1;
    this.payorCollection = [];
    this.payorFrmGp.patchValue({
      filename: '',
    });

    /* wire up file reader */
    let target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length > 1) {
      this.isFileValid = false;
      this.fileErrorMessage = 'Cannot import multiple files at once.';
      target = new DataTransfer();
      throw new Error('Cannot use multiple files');
    }
    if (
      !target.files[0].name.endsWith('xlsx') &&
      !target.files[0].name.endsWith('xls')
    ) {
      target = new DataTransfer();
      this.isFileValid = false;
      this.fileErrorMessage = 'File type must be Excel format (.xlsx or .xls)';
      throw new Error('File type not accepted');
    }
    // this.payorCollection = []; Addd import to existing payors
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const worksheetName: string = wb.SheetNames[0];
      const worksheet: XLSX.WorkSheet = wb.Sheets[worksheetName];

      /* find the headers */
      this.data = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as AOA;
      for (let x = 0; x < this.data.length; x++) {
        if (this.data[x][0] !== undefined) {
          if (
            this.data[x][0].toString().trim().toLowerCase() ===
              'employee last name' ||
            this.data[x][0].toString().trim().toLowerCase() === 'lastname' ||
            this.data[x][0].toString().trim().toLowerCase() === 'last name' ||
            this.data[x][0].toString().trim().toLowerCase() ===
              'social security number' ||
            this.data[x][0].toString().trim().toLowerCase() === 'ssn' ||
            this.data[x][0].toString().trim().toLowerCase() === 'amt' ||
            this.data[x][0].toString().trim().toLowerCase() === 'amount' ||
            this.data[x][0].toString().trim().toLowerCase() === 'payment amount'
          ) {
            headerRowIndex = x;
            break;
          }
        }
      }

      if (headerRowIndex === -1) {
        this.payorFrmGp.patchValue({
          filename: '',
        });
        this.isFileValid = false;
        target = new DataTransfer();
        if (this.validateCaseNumber) {
          this.fileErrorMessage =
            `Invalid File Format. File must be Excel document and contain  at least 4 columns with headers titled
          "Employee Last Name", "SSN", ` +
            this.secondaryIdAlias +
            ` and "Amount".  Please refer to Sample File Example.`;
        } else {
          this.fileErrorMessage = `Invalid File Format. File must be Excel document and contain  at least 3 columns with headers titled
                                "Employee Last Name", "SSN", and "Amount".  Please refer to Sample File Example.`;
        }
        return;
      }

      /* Find the index for data */
      const headercheck = this.data[headerRowIndex];
      for (let x = 0; x < headercheck.length; x++) {
        if (
          headercheck[x].toString().toLowerCase() === 'employee last name' ||
          headercheck[x].toString().toLowerCase() === 'lastname' ||
          headercheck[x].toString().toLowerCase() === 'last name'
        ) {
          lastNameHeaderIndex = x;
        }
        if (
          headercheck[x].toString().toLowerCase() === 'ssn' ||
          headercheck[x].toString().toLowerCase() === 'social security number'
        ) {
          ssnHeaderIndex = x;
        }

        if (
          headercheck[x].toString().toLowerCase() === 'case number' ||
          headercheck[x].toString().toLowerCase() === 'case' ||
          headercheck[x].toString().toLowerCase() === 'case id' ||
          headercheck[x].toString().toLowerCase() === 'fgn' ||
          headercheck[x].toString().toLowerCase() === 'family group #' ||
          headercheck[x].toString().toLowerCase() === 'family group number'
        ) {
          caseHeaderIndex = x;
        }

        if (
          headercheck[x].toString().toLowerCase() === 'payment amount' ||
          headercheck[x].toString().toLowerCase() === 'amount' ||
          headercheck[x].toString().toLowerCase() === 'amt'
        ) {
          amountHeaderIndex = x;
        }
      }

      if (this.validateCaseNumber) {
        if (
          lastNameHeaderIndex === -1 ||
          ssnHeaderIndex === -1 ||
          amountHeaderIndex === -1 ||
          caseHeaderIndex === -1
        ) {
          this.payorFrmGp.patchValue({
            filename: '',
          });
          this.isFileValid = false;
          target = new DataTransfer();
          this.fileErrorMessage =
            `Invalid File Format. File must be Excel document and contain at least 3 columns with headers titled
                                  "Employee Last Name", "SSN", ` +
            this.secondaryIdAlias +
            ` and "Amount".  Please refer to Sample File.`;
          return;
        }
      } else {
        if (
          lastNameHeaderIndex === -1 ||
          ssnHeaderIndex === -1 ||
          amountHeaderIndex === -1
        ) {
          this.payorFrmGp.patchValue({
            filename: '',
          });
          this.isFileValid = false;
          target = new DataTransfer();
          this.fileErrorMessage = `Invalid File Format. File must be Excel document and contain at least 3 columns with headers titled
                                  "Employee Last Name", "SSN", and "Amount".  Please refer to Sample File.`;
          return;
        }
      }

      /* save data */
      this.data = this.data.splice(headerRowIndex + 1);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.data.length; i++) {
        const d = this.data[i];
        if (d[0] === undefined) {
          break;
        }
        const p = {
          name: '',
          firstName: '',
          middleName: '',
          lastName: d[lastNameHeaderIndex],
          ssn:
            d[ssnHeaderIndex] === undefined ? '' : d[ssnHeaderIndex].toString(),
          caseNumber: this.validateCaseNumber ? d[caseHeaderIndex] : '',
          amount:
            d[amountHeaderIndex] === undefined ? 0 : +d[amountHeaderIndex],
          isSelected: true,
          isValidated: false,
          hasError: false,
          hasWarning: false,
          hadEdit: false,
          errorMessage: '',
        };
        this.payorCollection.push(p);
      }
    };
    reader.readAsBinaryString(target.files[0]);

    this.payorFrmGp.patchValue({
      filename: target.files[0].name,
    });
    this.fileImported = true;
    this.isFileValid = true;
    this.payorCollection.reverse();
    reader.onloadend = (e: any) => {
      this.isAllChecked();
      this.payorCollection.forEach((element) => {
        if (element.isSelected) {
          this.atLeastOnePayorSelected = true;
        }
      });
      if (this.payorCollection.length === 0 && this.isFileValid) {
        this.isFileValid = false;
        this.fileErrorMessage =
          'Error importing file.  Please review file to make sure all fields are completed.';
      }
    };
    target = new DataTransfer();
  }

  resetFile() {
    this.inputFile.nativeElement.value = null;
  }

  addSavePayorCollection() {
    this.payorsValid = false;
    this.numInvalid = 0;
    this.numWarning = 0;
    this.noPreviousPayors = '';
    const payor = this.payorFrmGp.value;
    if (
      payor.collectionFrmGp.lastName === '' ||
      payor.collectionFrmGp.lastName === null ||
      //Don't care if ssn is missing for TN
      // payor.collectionFrmGp.ssn === '' ||
      // payor.collectionFrmGp.ssn === null ||
      payor.collectionFrmGp.amount === '' ||
      payor.collectionFrmGp.amount === null ||
      ((payor.collectionFrmGp.casenumber === '' ||
        payor.collectionFrmGp.casenumber === null) &&
        this.validateCaseNumber)
    ) {
      if (!this.validateCaseNumber) {
        this.payorDetailError =
          'Invalid entry, make sure Last Name, SSN, and Amount have been entered.';
      } else {
        this.payorDetailError =
          'Invalid entry, make sure Last Name, SSN, ' +
          this.secondaryIdAlias +
          ' and Amount have been entered.';
      }
      return;
    } else {
      this.payorDetailError = '';
    }
    let founderror = false;
    let formatSSN = '';
    const ssnRegex = new RegExp(this.ssnPattern);

    if (payor.collectionFrmGp.ssn.length > 0) {
      formatSSN = payor.collectionFrmGp.ssn.toString();
      formatSSN = formatSSN.replace(/-/g, '');
      formatSSN = formatSSN.replace(/\s/g, '');

      if (!ssnRegex.test(payor.collectionFrmGp.ssn)) {
        founderror = true;
      }
    }

    if (!this.isEditPayor) {
      const p = {
        lastName: payor.collectionFrmGp.lastname,
        ssn: formatSSN,
        amount: +payor.collectionFrmGp.amount,
        caseNumber: payor.collectionFrmGp.casenumber,
        firstName: '',
        middleName: '',
        name: '',
        isSelected: true,
        isValidated: false,
        hasError: founderror,
        hasWarning: false,
        hadEdit: false,
        errorMessage: founderror ? 'Invalid SSN' : '',
      };
      this.payorCollection.push(p);
      this.payorCollection.reverse();
    } else {
      this.payorCollection[this.editPayorIndex].lastName =
        payor.collectionFrmGp.lastname;
      this.payorCollection[this.editPayorIndex].ssn = formatSSN;
      this.payorCollection[this.editPayorIndex].caseNumber =
        payor.collectionFrmGp.casenumber;
      this.payorCollection[this.editPayorIndex].amount =
        payor.collectionFrmGp.amount;
      this.payorCollection[this.editPayorIndex].hasError = false;
      this.payorCollection[this.editPayorIndex].errorMessage = '';
      this.payorCollection[this.editPayorIndex].isValidated = false;

      //If we have a SSN, then make sure it's valid
      if (payor.collectionFrmGp.ssn.length > 0) {
        if (!ssnRegex.test(payor.collectionFrmGp.ssn)) {
          this.payorCollection[this.editPayorIndex].hasError = true;
        } else {
          this.payorCollection[this.editPayorIndex].hasError = false;
        }
      }
      this.payorCollection[this.editPayorIndex].hadEdit = true;

      this.isEditPayor = false;
      this.editPayorIndex = -1;
    }

    this.payorsValid = false;
    this.getNumberOfPayments();
    this.payorFrmGp.reset();
    this.payorFrmGp.updateValueAndValidity();
  }

  resetToggle() {
    this.toggleEditPayor = false;
  }

  editPayor(payors: IEMPPaymentCollection, index: number) {
    this.toggleEditPayor = true;
    this.nameField.nativeElement.focus();
    this.isEditPayor = true;
    this.editPayorIndex = index;
    this.payorFrmGp.reset();

    this.payorFrmGp.get('collectionFrmGp')!.patchValue({
      lastname: payors.lastName,
      ssn: payors.ssn,
      amount: payors.amount,
      casenumber: payors.caseNumber,
    });
    this.payorFrmGp.updateValueAndValidity();
    this.numInvalid = 0;
    this.numWarning = 0;
    this.payorsValid = false;
    this.payorCollection.forEach((payor) => {
      payor.isValidated = false;
      payor.hadEdit = false;
    });
  }

  deletePayor(i: number) {
    this.payorCollection.splice(i, 1);
    this.numInvalid = 0;
    this.numWarning = 0;
    this.payorsValid = false;
    this.payorCollection.forEach((payor) => {
      payor.isValidated = false;
    });
    this.isAllChecked();
  }

  selectAllPayments(event: any) {
    if (event.target.checked) {
      this.payorCollection.forEach((p) => {
        p.isSelected = true;
      });
    } else {
      this.payorCollection.forEach((p) => {
        p.isSelected = false;
      });
    }
    this.getNumberOfPayments();
  }

  clearpayorCollection() {
    this.fileImported = false;
    this.isFileValid = false;
    this.data = null;
    //this.payment = {} as IEMPPayment;
    this.payorCollection = [];
    this.numOfPayments = 0;
    this.payorFrmGp.reset();
    this.payorFrmGp.patchValue({ filename: ' Choose File' });
  }

  isAllChecked() {
    let checkit = true;
    this.payorCollection.forEach((element) => {
      if (!element.isSelected) {
        checkit = false;
      }
    });
    this.allChecked = checkit;
  }

  addRemovePayor(event: any, i: number) {
    if (event.target.checked) {
      this.payorCollection[i].isSelected = true;
    } else {
      this.payorCollection[i].isSelected = false;
    }
    this.getNumberOfPayments();
    this.isAllChecked();
  }

  getNumberOfPayments(): number {
    this.numOfPayments = 0;
    this.totalAmtOfPayments = 0;
    this.atLeastOnePayorSelected = false;
    this.payorCollection.forEach((element) => {
      if (element.isSelected) {
        this.atLeastOnePayorSelected = true;
        this.numOfPayments++;
        this.totalAmtOfPayments += +element.amount;
      }
    });
    this.totalAmtOfPayments = +this.totalAmtOfPayments.toFixed(2);
    return this.numOfPayments;
  }

  setValidationType() {
    if (this.validationType === PayorValidationTypes.creditCard) {
      this.isCreditCardValidation = true;
      this.isEftFileValidation = false;
      this.isScheduledPaymentValidation = false;
    }
    if (this.validationType === PayorValidationTypes.scheduldedPayment) {
      this.isCreditCardValidation = false;
      this.isEftFileValidation = false;
      this.isScheduledPaymentValidation = true;
    }
    if (this.validationType === PayorValidationTypes.eftEdiFile) {
      this.isCreditCardValidation = false;
      this.isEftFileValidation = true;
      this.isScheduledPaymentValidation = false;
    }
  }

  validCCPaymentEmitted(ccPayment: any) {
    this.creditCardPayment = ccPayment.creditCardPayment;
    this.paymentCollection = this.creditCardPayment.participantCollection;
    this.getNumberOfPayments();
  }

  toggleEditPayorTab() {
    this.toggleEditPayor = true;
  }

  loadFormGroups() {
    this.termsFrmGp = this.formbuilder.group({
      accepted: ['', Validators.requiredTrue],
    });

    this.reviewFrmGrp = this.formbuilder.group({});

    this.ccFrmGp = this.formbuilder.group({
      ccFirstName: ['', Validators.required],
      ccLastName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip5: ['', [Validators.required, Validators.pattern(this.zipPattern)]],
      country: ['US', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phoneGp: this.formbuilder.group({
        regionCode: 'US',
        phoneNumber: ['', [Validators.required]],
        countryCode: '1',
        isValid: [false, Validators.requiredTrue],
      }),
      phoneExt: '',

      // Credit Card Info
      ccType: ['mastercard', Validators.required],
      ccNumber: ['', [Validators.required, Validators.pattern(this.ccRegex)]],
      cvv: ['', [Validators.required, Validators.minLength(3)]],
      ccExpiryMonth: ['', [Validators.required, autocompleteExpMonthValidator(this.expMonths)]],
      ccExpiryYear: ['', [Validators.required, autocompleteExpYearValidator(this.expYears),]]
    });

    // set default country to US
    this.ccFrmGp.patchValue({
      country: 'US',
    });

    this.setMaskAndExample('US');

    this.reviewFrmGrp = this.formbuilder.group({});
  }

  onCountrySelectionChange(event: any) {
    var country = event.value;

    this.ccFrmGp.get('phoneGp.phoneNumber')?.setValue('');
    this.ccFrmGp.get('zip5')?.setValue('');
    this.ccFrmGp.get('phoneGp.isValid')?.setValue(true);

    this.setMaskAndExample(country);

    // if (country !== 'US' || country !== 'CA' || country !== 'MX') {
    //   this.ccFrmGp.get('state')!.disable();
    //   this.ccFrmGp.get('state')!.clearValidators();
    //   this.ccFrmGp.get('state')!.setValue('');
    // }
    // else {
    //   this.ccFrmGp.get('state')!.enable();
    //   this.ccFrmGp.get('state')?.setValidators(Validators.required);
    // }
  }

  getCountries() {
    this.commonService.getCountries(this.baseurl).subscribe({
      next: (countries: any) => {
        this.countries = countries;
        this.countries = this.countries.sort((a, b) =>
          a.countryName < b.countryName ? 1 : -1
        );
        this.countries.push({
          country: 'MX',
          countryName: 'Mexico',
          alpha3IsoCode: 'MEX',
          numeric3IsoCode: '484',
        });
        this.countries.push({
          country: 'CA',
          countryName: 'Canada',
          alpha3IsoCode: 'CAN',
          numeric3IsoCode: '124',
        });
        this.countries.push({
          country: 'US',
          countryName: 'United States',
          alpha3IsoCode: 'USA',
          numeric3IsoCode: '840',
        });
        this.countries.reverse();
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  getStates() {
    this.selectedCountry = this.ccFrmGp.get('country')!.value;
    this.commonService.getStates(this.baseurl, this.selectedCountry).subscribe({
      next: (states: any) => {
        this.states = states;
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  creditCardTypeOnChange(type: any) {
    this.rotateCard('other');

    console.log("TEST HERE: ", type)
    
    if (type) {
      this.ccFrmGp.get('ccNumber')!.setValue("");
      this.expMonthCtrl!.setValue("");
      this.expYearCtrl!.setValue("");
      this.ccFrmGp.get('cvv')!.setValue("");
  
      this.ccFrmGp.get('ccNumber')!.clearValidators();
      this.expMonthCtrl!.clearValidators();
      this.expYearCtrl!.clearValidators();
      this.ccFrmGp.get('cvv')!.clearValidators();
      this.ccFrmGp.updateValueAndValidity();
  
      this.ccInfo = {
        cardNumber: null,
        cvc: null,
        expMonth: null,
        expYear: null,
        holder: null
      };
  
      switch(type.value) { 
        // case "amex": { 
        //    this.ccMask = "0000 000000 00000";
        //    this.ccRegex = "^3[47][0-9]{13}$";
        //    this.ccImgURL = '../../../../../assets/images/Icons/amex.svg';
        //    break; 
        // } 
        // case "discover": { 
        //   this.ccMask = "0000 0000 0000 0000";
        //   this.ccRegex = "^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$";
        //   this.ccImgURL = '../../../../../assets/images/Icons/discover.svg';
        //   break; 
        // } 
        case "mastercard": { 
          this.ccMask = "0000 0000 0000 0000";
          this.ccRegex = "^5[1-5][0-9]{14}$";
          this.ccImgURL = '../../../../../assets/images/Icons/icons8-mastercard.svg';
          break; 
       } 
      case "visa": { 
        this.ccMask = "0000 0000 0000 0000";
        this.ccRegex = "^4[0-9]{12}(?:[0-9]{3})?$"; 
        this.ccImgURL = '../../../../../assets/images/Icons/icons8-visa.svg';
        break; 
      } 
      default: { 
        this.ccMask = "0000 0000 0000 0000";
        this.ccRegex = "\b\d{13,16}\b"; 
        this.ccImgURL = '../../../../../assets/images/Icons/icons8-mastercard.svg';
          break; 
      } 
     } 
  
     this.ccFrmGp.get('ccNumber')!.setValidators([Validators.required, Validators.pattern(this.ccRegex)]);
     this.expMonthCtrl!.setValidators([Validators.required]);
     this.expYearCtrl!.setValidators([Validators.required]);
     this.ccFrmGp.get('cvv')!.setValidators([Validators.required, Validators.minLength(3)]);
     this.ccFrmGp.updateValueAndValidity();
    }
  }

  resetFormValues() {
    this.termsFrmGp.setValue({
      accepted: false,
    });
  }

  checkReading() {}

  editccPaymentcollection(ccPayment: IEMPPaymentCollection, index: number) {
    // this.nameField.nativeElement.focus();
    // this.iseditccPaymentCollection = true;
    // this.editccPaymentCollectionIndex = index;
    // this.paymentFrmGp.reset();
    // this.paymentFrmGp.get('collectionFrmGp')!.patchValue({
    //   lastname: ccPayment.lastName,
    //   ssn: ccPayment.ssn,
    //   casenumber: ccPayment.caseNumber,
    //   amount: ccPayment.amount,
    // });
    // this.paymentFrmGp.updateValueAndValidity();
    // this.numInvalid = 0;
    // this.numWarning = 0;
    // this.paymentsValid = false;
    // this.paymentCollection.forEach((payor) => {
    //   payor.isValidated = false;
    //   payor.hadEdit = false;
    // });
  }

  onSaveComplete(confirmationNum: string) {
    this.termsFrmGp.reset();
    this.reviewFrmGrp.reset();

    this.confirmationSender = 'Create A New Payment';
    this.confirmationTitle = 'Success';
    this.confirmationMessage = this.iseditccPayment
      ? 'Your scheduled Credit Card Payment has been updated!'
      : 'New scheduled Credit Card Payment has been created!';
    this.confirmationNumber = confirmationNum;
    this.goToPrimaryUrl = 'employer/achd/landing';
    this.goToPrimary = 'ACH Debit Home';
    this.openConfirmation = true;
  }

  hideConfirmationPage(result: any) {
    this.termsFrmGp.reset();
    this.reviewFrmGrp.reset();

    this.resetFormValues();

    this.isTermsSubmitted = false;
    this.iseditccPaymentCollection = false;
    this.showPaymentCollectionForm = false;
    this.showScheduleDay = false;
    this.paymentCollection = [];

    this.loadFormGroups();
    this.openConfirmation = !result.hideConfirmation;
  }

  goToHome() {
    this.router.navigateByUrl('employer/home');
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }


  submitTerms() {
    this.isTermsSubmitted = true;
  }
  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}