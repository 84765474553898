import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ErrorHandlerService } from 'epay-library';
import { CommonSettings } from 'epay-library';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'epay-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  message1 = '';
  message2 = '';
  message3 = '';
  baseurl: string;
  empWebsiteCode: string;
  isIE = false;
  ieMessage = '';
  mobileMessage = '';
  deviceInfo: any;
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  pagetitle = '';

  constructor(
    private router: Router,
    private authservice: AuthService,
    private errorHandlerService: ErrorHandlerService,
    private deviceService: DeviceDetectorService
  ) {
    this.baseurl = CommonSettings.BaseApi;
    this.empWebsiteCode = CommonSettings.EmpWebsiteCode;
    this.pagetitle = 'SMART Oklahoma Child Support Home';
  }

  ngOnInit() {
    this.authservice
      .getWebsiteMessage(this.baseurl, this.empWebsiteCode)
      .subscribe({
        next: (message: any) => {
          if (message.length) {
            if (message[0].isActive === true) {
              this.message1 = message[0].messageText1;
              this.message2 = message[0].messageText2;
              this.message3 = message[0].messageText3;
            }
          } else {
            this.message1 = '';
            this.message2 = '';
            this.message3 = '';
          }
        },
        error: (error: any) => {
          this.logError(error);
          this.handleError(error.status);
        },
      });
    this.checkBrowser();
    this.checkDevice();
  }

  checkBrowser() {
    this.isIE = false;
    this.ieMessage = '';

    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    if (match !== -1) {
      this.isIE = true;
    }
    this.ieMessage = `You are using Internet Explorer.
                      Some functions and tasks are not supported in this browser.
                      Please switch to Chrome, Edge, or another modern browser.`;
  }

  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    if (this.isMobile) {
      this.mobileMessage = `You are using a mobile device. Some functions and tasks are not supported
                           using a mobile device.  Please switch to a desktop for use of all functions.`;
    }
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}
