<div class="text-center">
    <h2 mat-dialog-title>
      <span class="fa fa-exclamation-triangle fa-lg-red"></span>
      {{title}}
    </h2>
  
    <div mat-dialog-content>
      <p>{{message}}</p>
    </div>
    <div *ngIf="startTime" mat-dialog-content>
      <p class="text-center">
        {{startTime}} and {{endTime}}
      </p>
    </div>
    <div mat-dialog-content>
      <p class="text-center">
        {{message2}}
      </p>
    </div>
    <div mat-dialog-content>
      <h5>
        {{message3}}
      </h5>
    </div>
  
    <div *ngIf="showDialogButton" mat-dialog-actions>
      <button class="btn btn-dialog-primary" mat-raised-button (click)="onDismiss()">OK</button>
    </div>
  </div>