import { Component, OnInit } from '@angular/core';
import { CommonSettings } from 'epay-library';

@Component({
  selector: 'epay-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
  pagetitle = 'Contact Information';
  chaturl = '';

  constructor() {}

  ngOnInit(): void {
    this.chaturl = CommonSettings.ChatUrl;
  }

  openChat() {
    window.open(this.chaturl, '', 'width=510, height=900');
  }
}
