import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IChallengeQuestion, ErrorHandlerService, ViewportService, RegisterLibraryService } from 'epay-library';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'common-forgot-username',
  templateUrl: './forgot-username.component.html'
})
export class CommonForgotUsernameComponent implements OnInit {
  @Input() baseurl = '';

  isMobile = false;
  stepperOrientation: StepperOrientation = 'horizontal';

  errorMessage = '';
  userQuestion: any;
  loginErrorMessage = '';
  emailErrorMessage = '';
  accountSuccessMessage = '';
  accountErrorMessage = '';
  challengeSuccessMessage = '';
  challengeErrorMessage = '';
  displayUsername = '';
  isCheckingLogin = false;
  isCheckingAnswer = false;
  isValidAccount = false;
  isValidAnswer = false;
  passwordHide = true;

  questions: IChallengeQuestion[] = [];
  passwordFrmGp: FormGroup = new FormGroup({});
  securityFrmGp: FormGroup = new FormGroup({});
  submitFrmGp: FormGroup = new FormGroup({});

  passwordHintMessage = 'Must contain at least 6 letters, 2 numbers, and at least one special character with a maximum of 12 characters.';

  loginPattern =
    /(?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*/;

  //passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  validationPasswordMessages: { [key: string]: string } = {
    required: 'Password is required.',
    minlength: 'Invalid password. Passwords must be between 8 to 12 characters in length.',
    maxlength: 'Invalid password. Passwords must be between 8 to 12 characters in length.',
    pattern:
      'Invalid Password.  Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.',
  };

  validationEmailMessages: { [key: string]: string } = {
    required: 'Email is required.',
    email: 'Email format is invalid.',
    notFound: 'Account is not found.',
  };

  validationChallengeMessages: { [key: string]: string } = {
    required: 'Answer is required.',
    notVerified: 'Challenge answer is incorrect.',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private viewpointService: ViewportService,
    private registerService: RegisterLibraryService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadFormGroups();
    const passwordCtrl = this.passwordFrmGp.get('password');
    const emailCtrl = this.passwordFrmGp.get('email');
    const answerCtrl = this.securityFrmGp.get('challengeAnswer');

    this.viewpointService.IsMobile.subscribe(
      (ismobile: boolean) => (this.isMobile = ismobile)
    );

    this.viewpointService.StepperOrient.subscribe(
      (orient) => (this.stepperOrientation = orient)
    );

    passwordCtrl!.valueChanges
      .pipe(debounceTime(200))
      .subscribe((currentPassword) => {
        this.resetAccountCheck();
        emailCtrl!.setErrors(null);
        emailCtrl!.updateValueAndValidity();
        if (
          (passwordCtrl!.touched || passwordCtrl!.dirty) &&
          passwordCtrl!.errors
        ) {
          // this.loginErrorMessage = Object.keys(passwordCtrl!.errors)
          //   .map((key) => this.validationPasswordMessages[key])
          //   .join(' ');
        } else if (
          (emailCtrl!.touched || emailCtrl!.dirty) &&
          emailCtrl!.errors
        ) {
          this.emailErrorMessage = Object.keys(emailCtrl!.errors)
            .map((key) => this.validationEmailMessages[key])
            .join(' ');
        } else if (
          (emailCtrl!.touched || emailCtrl!.dirty) &&
          !emailCtrl!.errors
        ) {
          this.isCheckingLogin = true;
          const user = {
            password: currentPassword,
            email: this.passwordFrmGp.get('email')!.value,
          };
          this.registerService
            .getUserChallengeQuestion(this.baseurl, user)
            .subscribe({
              next: (result: any) => {
                this.setAccountMessage(passwordCtrl!, emailCtrl!, result);
                this.securityFrmGp.patchValue({
                  challengeQuestion: result.challengeQuestion,
                });
                this.isCheckingLogin = false;
              },
              error: (error: any) => {
                this.resetAccountCheck();
                this.errorMessage = error as any;
                this.logError(error);
                this.handleError(error.status);
              },
            });
        }
      });

    emailCtrl!.valueChanges
      .pipe(debounceTime(500))
      .subscribe((emailAddress) => {
        this.resetAccountCheck();
        if ((emailCtrl!.touched || emailCtrl!.dirty) && emailCtrl!.errors) {
          this.emailErrorMessage = Object.keys(emailCtrl!.errors)
            .map((key) => this.validationEmailMessages[key])
            .join(' ');
          return;
        } else if (
          (passwordCtrl!.touched || passwordCtrl!.dirty) &&
          passwordCtrl!.errors
        ) {
          // this.emailErrorMessage = Object.keys(passwordCtrl!.errors)
          //   .map((key) => this.validationPasswordMessages[key])
          //   .join(' ');
          return;
        } else if (
          (passwordCtrl!.touched || passwordCtrl!.dirty) &&
          !passwordCtrl!.errors
        ) {
          this.isCheckingLogin = true;
          const user = {
            password: this.passwordFrmGp.get('password')!.value,
            email: emailAddress,
          };
          this.registerService
            .getUserChallengeQuestion(this.baseurl, user)
            .subscribe({
              next: (result: any) => {
                this.setAccountMessage(passwordCtrl!, emailCtrl!, result);
                this.securityFrmGp.patchValue({
                  challengeQuestion: result.challengeQuestion,
                });
                this.isCheckingLogin = false;
              },
              error: (error: any) => {
                this.resetAccountCheck();
                this.errorMessage = error as any;
                this.logError(error);
                this.handleError(error.status);
              },
            });
        }
      });

    answerCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((answer) => {
      if ((answerCtrl!.touched || answerCtrl!.dirty) && answerCtrl!.errors) {
        this.emailErrorMessage = Object.keys(answerCtrl!.errors)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      } else {
        this.isCheckingAnswer = true;
        const answerObj = {
          password: this.passwordFrmGp.get('password')!.value,
          email: this.passwordFrmGp.get('email')!.value,
          answer: this.securityFrmGp.get('challengeAnswer')!.value,
        };
        this.registerService
          .validateChallengeAnswer(this.baseurl, answerObj)
          .subscribe({
            next: (result: any) => {
              this.setChallengeMessage(answerCtrl!, result);
              this.isCheckingAnswer = false;
            },
            error: (error: any) => {
              this.resetChallengeCheck();
              this.errorMessage = error as any;
              this.logError(error);
              this.handleError(error.status);
            },
          });
      }
    });
  }

  togglePasswordHide() {
    this.passwordHide = !this.passwordHide;
  }

  resetAccountCheck() {
    this.isValidAccount = false;
    this.isCheckingLogin = false;
    this.accountSuccessMessage = '';
    this.accountErrorMessage = '';
    this.emailErrorMessage = '';
    this.loginErrorMessage = '';
    this.userQuestion = '';
  }

  resetChallengeCheck() {
    this.isCheckingAnswer = false;
    this.securityFrmGp.patchValue({
      challengeAnswer: '',
    });
  }

  retrieveUsername() {
    if (this.securityFrmGp.valid) {
      const username = {
        validated: true,
        emailAddress: this.passwordFrmGp.get('email')!.value,
        password: this.passwordFrmGp.get('password')!.value,
        challengeAnswer: this.securityFrmGp.get('challengeAnswer')!.value,
      };
      this.registerService.forgotUsername(this.baseurl, username).subscribe({
        next: (result: any) => {
          this.displayUsername = result.loginID;
        },
        error: (error: any) => {
          this.errorMessage = error as any;
          this.logError(error);
          this.handleError(error.status);
        },
      });
    }
  }

  setAccountMessage(
    passwordControl: AbstractControl,
    emailControl: AbstractControl,
    result: any
  ) {
    this.accountErrorMessage = '';
    this.accountSuccessMessage = '';

    if (passwordControl.dirty && emailControl.dirty) {
      this.isValidAccount = result.challengeQuestionIndex === -1 ? false : true;
      if (this.isValidAccount) {
        this.accountSuccessMessage = 'Account found.';
      } else {
        emailControl.setErrors({ notFound: true });
        this.accountErrorMessage = Object.keys(emailControl.errors!)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      }
    }
  }

  setChallengeMessage(answerControl: AbstractControl, result: any) {
    if (answerControl.dirty || answerControl.touched) {
      this.isValidAnswer = result.verified;
      if (this.isValidAnswer) {
        this.challengeSuccessMessage = 'Correct!';
      } else {
        answerControl.setErrors({ notVerified: true });
        this.challengeErrorMessage = Object.keys(answerControl.errors!)
          .map((key) => this.validationChallengeMessages[key])
          .join(' ');
      }
    }
  }

  loadFormGroups() {
    this.passwordFrmGp = this.formbuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: [
        '',
        [Validators.required, Validators.minLength(8), Validators.maxLength(12)],
      ],
    });

    this.securityFrmGp = this.formbuilder.group({
      challengeQuestion: ['', Validators.required],
      challengeAnswer: ['', Validators.required],
    });
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  onSaveComplete() {
    this.passwordFrmGp.reset();
    this.securityFrmGp.reset();
    this.passwordFrmGp.reset();
    this.router.navigate(['login']);
  }

  goToLogin() {
    this.passwordFrmGp.reset();
    this.securityFrmGp.reset();
    this.passwordFrmGp.reset();
    this.router.navigateByUrl('login');
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}
