<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main" id="page-content-main">
    <div class="row justify-content-center">
      <mat-card class="col-5 mt-3">
        <mat-card-content>
          <div class="row justify-content-center">
            <p>Your payment attempt has been cancelled. The information was not saved.</p>
            <div class="next-links mb-3">
              <a class="btn-link mt-3" [routerLink]="['/login']">Login</a>
              <a class="btn-link mt-3" [routerLink]="['/home']">Home</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</epay-page-content>
