<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main box-panel shadow max-w max-gs" id="page-content-main">
    <!-- <epay-lib-common-creditcard-success [encryptedTransactionId]="encryptedTransactionId" [baseurl]="baseurl"
      [clientId]="clientId">
    </epay-lib-common-creditcard-success> -->

    <credit-card-success-common [encryptedTransactionId]="encryptedTransactionId" [baseurl]="baseurl"
    [clientId]="clientId"></credit-card-success-common>
  </div>
</epay-page-content>
