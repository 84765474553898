<epay-page-content [pagetitle]="pagetitle">
  <div class="page-content-main box-panel shadow max-w max-gs" id="page-content-main">
    <div class="m--pa4">
      <h2 class="mb-5">Accessibility Statement</h2>
      <p>
        This site has been evaluated for compliance with existing accessibility guidelines,
        including Section 508 of the US Rehabilitation Act and the W3C&rsquo;s Web Content
        Accessibility Guidelines (WCAG).
      </p>
    </div>
  </div>
</epay-page-content>
