import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IChallengeQuestion, ErrorHandlerService, ViewportService, CommonService, RegisterLibraryService } from 'epay-library';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password1Ctrl = c.get('newpassword1');
  const password2Ctrl = c.get('newpassword2');
  if (password1Ctrl === null || password2Ctrl === null) {
    return null;
  }
  if (password1Ctrl.pristine || password2Ctrl.pristine) {
    return null;
  }
  if (password1Ctrl.value === password2Ctrl.value) {
    return null;
  }
  return { doNotMatch: true };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (control) {
      return (control.dirty || control.touched) && control.parent!.invalid;
    }
    return false;
  }
}

@Component({
  selector: 'common-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class CommonForgotPasswordComponent implements OnInit {
  @Input() baseurl = '';

  isMobile = false;
  stepperOrientation: StepperOrientation = 'horizontal';
  errorMatcher = new CrossFieldErrorMatcher();
  errorMessage = '';
  userQuestion: any;
  loginErrorMessage = '';
  emailErrorMessage = '';
  accountSuccessMessage = '';
  accountErrorMessage = '';
  challengeSuccessMessage = '';
  challengeErrorMessage = '';
  isCheckingLogin = false;
  isCheckingAnswer = false;
  isValidAccount = false;
  isValidAnswer = false;
  isValidLogin = false;
  passwordHide = true;
  passwordConfirmHide = true;

  openConfirmation = false;
  confirmationTitle = '';
  confirmationMessage = '';
  confirmationEmailAddress = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';

  questions: IChallengeQuestion[] = [];
  usernameFrmGp: FormGroup = new FormGroup({});
  securityFrmGp: FormGroup = new FormGroup({});
  passwordFrmGp: FormGroup = new FormGroup({});

  // loginPattern =
  //   /((?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*)/;

  loginPattern = /^[a-zA-Z0-9.@]*$/;

  // usernamePattern =
  //   /((?=^[A-Za-z\d]{8,12}$)(?=(.*[A-Za-z]){6,8})(?=(.*\d){2,4})^.*) || \S+@\S+\.\S+/;

  passwordPattern = /(?=^[A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\d]{8,12}$)(?=(.*[A-Za-z]){6})(?=(.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){1})(?=(.*\d){2})^.*/;

  passwordErrorMessage = '';
  passwordHintMessage = 'Must contain at least 6 letters, 2 numbers, and at least one special character with a maximum of 12 characters.';

  validationLoginIdMessages: { [key: string]: string } = {
    required: 'Username is required.',
    pattern: 'Invalid username',
  };

  validationEmailMessages: { [key: string]: string } = {
    required: 'Email is required.',
    email: 'Email format is invalid.',
    notFound: 'Account is not found.',
  };

  validationPasswordMessages: { [key: string]: string } = {
    required: 'Password is required.',
    pattern:
      'Invalid Password.  Must contain at least 6 letters, 2 numbers, and one special character with a maximum of 12 characters.',
    isSameAsLogin: 'Invalid Password.  Password cannot be same as username.',
  };

  validationChallengeMessages: { [key: string]: string } = {
    required: 'Answer is required.',
    notverified: 'Challenge answer is incorrect.',
  };

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private viewpointService: ViewportService,
    private commonService: CommonService,
    private registerService: RegisterLibraryService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadFormGroups();
    const emailCtrl = this.usernameFrmGp.get('email');
    const loginIdCtrl = this.usernameFrmGp.get('loginId');
    const answerCtrl = this.securityFrmGp.get('challengeAnswer');
    const password1Ctrl = this.passwordFrmGp.get('newpassword1');

    this.viewpointService.IsMobile.subscribe(
      (ismobile: boolean) => (this.isMobile = ismobile)
    );

    this.viewpointService.StepperOrient.subscribe(
      (orient) => (this.stepperOrientation = orient)
    );

    password1Ctrl!.valueChanges
      .pipe(debounceTime(500))
      .subscribe((passwrd) => this.setPasswordMessage(password1Ctrl!, passwrd));

    loginIdCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((username) => {
      this.resetAccountCheck();
      emailCtrl!.setErrors(null);
      emailCtrl!.updateValueAndValidity();

      if ((loginIdCtrl!.touched || loginIdCtrl!.dirty) && loginIdCtrl!.errors) {
        // this.loginErrorMessage = Object.keys(loginIdCtrl!.errors)
        //   .map((key) => this.validationLoginIdMessages[key])
        //   .join(' ');
      } else if (
        (emailCtrl!.touched || emailCtrl!.dirty) &&
        emailCtrl!.errors
      ) {
        this.emailErrorMessage = Object.keys(emailCtrl!.errors)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      } else if (
        (emailCtrl!.touched || emailCtrl!.dirty) &&
        !emailCtrl!.errors
      ) {
        this.isCheckingLogin = true;
        const user = {
          loginId: username,
          email: this.usernameFrmGp.get('email')!.value,
        };
        this.registerService
          .getUserChallengeQuestion(this.baseurl, user)
          .subscribe({
            next: (result: any) => {
              this.setAccountMessage(loginIdCtrl!, emailCtrl!, result);
              this.securityFrmGp.patchValue({
                challengeQuestion: result.challengeQuestion,
              });
            },
            error: (error: any) => {
              this.resetAccountCheck();
              this.errorMessage = error as any;
              this.handleError(error.status);
            },
            complete: () => (this.isCheckingLogin = false),
          });
      }
    });

    emailCtrl!.valueChanges
      .pipe(debounceTime(500))
      .subscribe((emailAddress) => {
        this.resetAccountCheck();
        if ((emailCtrl!.touched || emailCtrl!.dirty) && emailCtrl!.errors) {
          this.emailErrorMessage = Object.keys(emailCtrl!.errors)
            .map((key) => this.validationEmailMessages[key])
            .join(' ');
        } else if (
          (loginIdCtrl!.touched || loginIdCtrl!.dirty) &&
          loginIdCtrl!.errors
        ) {
          this.emailErrorMessage = Object.keys(loginIdCtrl!.errors)
            .map((key) => this.validationLoginIdMessages[key])
            .join(' ');
        } else if (
          (loginIdCtrl!.touched || loginIdCtrl!.dirty) &&
          !loginIdCtrl!.errors
        ) {
          this.isCheckingLogin = true;
          const user = {
            loginId: this.usernameFrmGp.get('loginId')!.value,
            email: emailAddress,
          };
          this.registerService
            .getUserChallengeQuestion(this.baseurl, user)
            .subscribe({
              next: (result: any) => {
                this.setAccountMessage(loginIdCtrl!, emailCtrl!, result);
                this.securityFrmGp.patchValue({
                  challengeQuestion: result.challengeQuestion,
                });
              },
              error: (error: any) => {
                this.resetAccountCheck();
                this.errorMessage = error as any;
                this.handleError(error.status);
              },
              complete: () => (this.isCheckingLogin = false),
            });
        }
      });

    answerCtrl!.valueChanges.pipe(debounceTime(500)).subscribe((answer) => {
      if ((answerCtrl!.touched || answerCtrl!.dirty) && answerCtrl!.errors) {
        this.emailErrorMessage = Object.keys(answerCtrl!.errors)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      } else {
        this.isCheckingAnswer = true;
        const answerObj = {
          loginId: this.usernameFrmGp.get('loginId')!.value,
          email: this.usernameFrmGp.get('email')!.value,
          answer: this.securityFrmGp.get('challengeAnswer')!.value,
        };
        this.registerService
          .validateChallengeAnswer(this.baseurl, answerObj)
          .subscribe({
            next: (result: any) => {
              this.setChallengeMessage(answerCtrl!, result);
            },
            error: (error: any) => {
              this.resetChallengeCheck();
              this.errorMessage = error as any;
              this.handleError(error.status);
            },
            complete: () => (this.isCheckingAnswer = false),
          });
      }
    });
  }

  resetAccountCheck() {
    this.isValidAccount = false;
    this.isCheckingLogin = false;
    this.accountSuccessMessage = '';
    this.accountErrorMessage = '';
    this.emailErrorMessage = '';
    this.loginErrorMessage = '';
    this.userQuestion = '';
  }

  resetChallengeCheck() {
    this.isCheckingAnswer = false;
    this.securityFrmGp.patchValue({
      challengeAnswer: '',
    });
  }

  saveNewPassword() {
    const newpassword = {
      validated: true,
      emailAddress: this.usernameFrmGp.get('email')!.value,
      loginID: this.usernameFrmGp.get('loginId')!.value,
      challengeAnswer: this.securityFrmGp.get('challengeAnswer')!.value,
      newPassword1: this.passwordFrmGp.get('newpassword1')!.value,
      newPassword2: this.passwordFrmGp.get('newpassword2')!.value,
    };
    this.registerService.forgotPassword(this.baseurl, newpassword).subscribe({
      next: (result: any) => {
        if (result.validated) {
          this.onSaveComplete(result.confirmation.confirmationNumber);
        } else {
          this.handleError(500);
        }
      },
      error: (error: any) => {
        this.logError(error);
        this.handleError(error.status);
      },
    });
  }

  setPasswordMessage(passwordControl: AbstractControl, passwrd: any) {
    this.passwordErrorMessage = '';
    const username = this.usernameFrmGp.get('loginId')!.value;

    if (
      (passwordControl.touched || passwordControl.dirty) &&
      passwordControl.errors
    ) {
      this.passwordErrorMessage = Object.keys(passwordControl.errors)
        .map((key) => this.validationPasswordMessages[key])
        .join(' ');
    } else if (
      (passwordControl.touched || passwordControl.dirty) &&
      !passwordControl.errors
    ) {
      if (username === passwrd) {
        passwordControl.setErrors({ isSameAsLogin: true });
        this.passwordErrorMessage = Object.keys(passwordControl.errors!)
          .map((key) => this.validationPasswordMessages[key])
          .join(' ');
      } else {
        passwordControl.setErrors(null);
        passwordControl.updateValueAndValidity();
      }
    }
  }

  setAccountMessage(
    loginControl: AbstractControl,
    emailControl: AbstractControl,
    result: any
  ) {
    this.accountErrorMessage = '';
    this.accountSuccessMessage = '';

    if (
      (loginControl.dirty || loginControl.touched) &&
      (emailControl.dirty || emailControl.touched)
    ) {
      this.isValidAccount = result.challengeQuestionIndex === -1 ? false : true;
      if (this.isValidAccount) {
        this.accountSuccessMessage = 'Account found.';
      } else {
        emailControl.setErrors({ notFound: true });
        this.accountErrorMessage = Object.keys(emailControl.errors!)
          .map((key) => this.validationEmailMessages[key])
          .join(' ');
      }
    }
  }

  setChallengeMessage(answerControl: AbstractControl, result: any) {
    if (answerControl.dirty || answerControl.touched) {
      this.isValidAnswer = result.verified;
      if (this.isValidAnswer) {
        this.challengeSuccessMessage = 'Correct!';
      } else {
        answerControl.setErrors({ notverified: true });
        this.challengeErrorMessage = Object.keys(answerControl.errors!)
          .map((key) => this.validationChallengeMessages[key])
          .join(' ');
      }
    }
  }

  togglePasswordHide() {
    this.passwordHide = !this.passwordHide;
  }

  toggleConfirmPasswordHide() {
    this.passwordConfirmHide = !this.passwordConfirmHide;
  }

  loadFormGroups() {
    this.usernameFrmGp = this.formbuilder.group({
      loginId: [
        '',
        [Validators.required, Validators.pattern(this.loginPattern)],
      ],
      email: ['', [Validators.email, Validators.required]],
    });

    this.securityFrmGp = this.formbuilder.group({
      challengeQuestion: ['', Validators.required],
      challengeAnswer: ['', Validators.required],
    });

    this.passwordFrmGp = this.formbuilder.group(
      {
        newpassword1: [
          '',
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        newpassword2: ['', Validators.required],
      },
      { validator: passwordMatcher }
    );
  }

  openSnackBar(message: string, time: number, style: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.duration = time;
    config.panelClass = style;
    this.snackbar.open(message, '', config);
  }

  onSaveComplete(confirmationNum: string) {
    (this.confirmationEmailAddress = this.usernameFrmGp.get('email')!.value),
      this.usernameFrmGp.reset();
    this.securityFrmGp.reset();
    this.passwordFrmGp.reset();
    this.confirmationTitle = 'Success';
    this.confirmationMessage = `Your password has been changed. `;
    this.confirmationNumber = confirmationNum;
    this.goToPrimaryUrl = 'login';
    this.goToPrimary = 'Login';
    this.openConfirmation = true;
  }

  goToLogin() {
    this.router.navigateByUrl('login');
  }

  logError(error: Error) {
    this.errorHandlerService.logError(error);
  }

  handleError(error: number) {
    console.log(this.errorMessage);
    console.log('error', error);
    this.errorHandlerService.handleErrorMessage(error, this.baseurl);
  }
}

