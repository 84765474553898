import { Injectable } from '@angular/core';
import {
  HttpHeaderResponse,
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { IEmpSearchResults, CommonSettings } from 'epay-library';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  private serviceurl = CommonSettings.BaseApi;
  private environment = CommonSettings.Environment;
  private ecomGateway = 'EcommerceGateway/Ecommmerce';

  HttpOptions = {
    headers: new HttpHeaders(),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getEmployerTerms() {
    const file = 'assets/terms/employer/disclaimer.txt';
    return this.http
      .get(file, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  getEFTInfo() {
    const file = 'assets/information/eft.html';
    return this.http
      .get(file, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  getACHDInfo() {
    const file = 'assets/information/achd.html';
    return this.http
      .get(file, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  getLoginHelp() {
    const file = 'assets/help/loginHelpEmployer.html';
    return this.http
      .get(file, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  getEmployers(
    baseurl: string,
    employerName: string,
    employerEIN: string,
    includeAllEmployers: boolean,
    includeHasHold: boolean
  ): Observable<IEmpSearchResults[]> {
    const apiCmd = 'searchEmployer';
    const gateway = 'EPayGateway/EPay/api/v1/Registration:';
    this.HttpOptions.params = new HttpParams()
      .set('thirdPartyName', employerName)
      .set('ein', employerEIN === null ? '' : employerEIN)
      .set('includeAllEmployers', includeAllEmployers) // false = don't show inactive accounts
      .set('includeHasHold', includeHasHold); // false = bypass has hold check

    return this.http
      .get<IEmpSearchResults[]>(baseurl + gateway + apiCmd, this.HttpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(() => err);
  }
}
