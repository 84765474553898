import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CommonSettings } from 'epay-library';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppLoadService {
  constructor(private httpClient: HttpClient) {}

  async getParameterSettings(): Promise<any> {
    let apiCmd = 'Parameters';
    let gateway = 'EPayGateway/EPay/api/v1/';

    const obsParameterValue = this.httpClient
      .get<any>(CommonSettings.BaseApi + gateway + apiCmd, {
        headers: new HttpHeaders({
          Client: CommonSettings.ClientID,
        }),
      })
      .pipe(
        map((parameterValues: any) => {
          console.log('Loading settings....');
          CommonSettings.NumOfDaysForPaymentHistory =
            parameterValues.EPay_NumOfDaysForPaymentHistory;
          CommonSettings.NumOfDaysOutForCalendar =
            parameterValues.EPay_NumOfDaysOutForCalendar;
          CommonSettings.DaysToShowEFTFileHistory =
            parameterValues.EPay_DaysToShowEFTFileHistory;
          CommonSettings.Timezone = parameterValues.EPay_ClientTimeZone;
          CommonSettings.EmpWebsiteCode = parameterValues.EPay_EmpWebsiteCode;
          CommonSettings.AppInsightsConnectionString =
            parameterValues.EPay_AppInsightConnectionString;
          CommonSettings.AppInsightsEnableAutoRouteTracking =
            parameterValues.EPay_AppInsightEnableAutoRouteTracking;
          CommonSettings.CreditCardVendorID =
            parameterValues.EPay_CreditCardVendorID;
          CommonSettings.FortPointAccessToken =
            parameterValues.EPay_FortPointAccessToken;
          CommonSettings.FortPointCreditCardUrl =
            parameterValues.EPay_FortPointCreditCardUrl;
          CommonSettings.FortPointStateCode =
            parameterValues.EPay_FortPointStateCode;
          CommonSettings.ChatUrl = parameterValues.EPay_Chat_url;
          return parameterValues;
        }),
        catchError(this.handleError)
      );

    return await lastValueFrom(obsParameterValue);
  }

  async getConfigSettings(): Promise<any> {
    //Get settings from config file
    const configSettings = this.httpClient
      .get<any>('/assets/config/config.json')
      .pipe(
        map((configs: any) => {
          CommonSettings.Title = configs.title;
          CommonSettings.BaseApi = configs.baseApi;
          CommonSettings.TokenApi = configs.tokenApi;
          CommonSettings.ClientID = configs.clientID.toString();
          CommonSettings.ClientName = configs.clientName;
          CommonSettings.StateName = configs.stateName;
          CommonSettings.TimeOutInMins = configs.timeoutInMins.toString();
          CommonSettings.Environment = configs.environment;

          CommonSettings.CanCreateNewEmployer = this.toBoolean(configs.canCreateNewEmployer);
          CommonSettings.UseEmailForLogin = this.toBoolean(configs.useEmailForLogin);
          CommonSettings.CanRegisterWithClosedCase =
          this.toBoolean(configs.canRegisterWithClosedCase);
          CommonSettings.CanMakePaymentsToClosedCases =
          this.toBoolean(configs.canMakePaymentsToClosedCases);
          CommonSettings.EmployerUseCaseNumberForPayments =
          this.toBoolean(configs.employerUseCaseNumberForPayments);
          CommonSettings.NCPUseCaseNumberForPayments =
          this.toBoolean(configs.ncpUseCaseNumberForPayment);
          CommonSettings.SaveEmployeesForPayments =
          this.toBoolean(configs.saveEmployeesForPayments);
          CommonSettings.AllowEINSearch = this.toBoolean(configs.allowEINSearch);
          CommonSettings.CanInactivateNCPAccount =
          this.toBoolean(configs.canInactivateNCPAccount);

          return configs;
        }),
        catchError(this.handleError)
      );

    return await lastValueFrom(configSettings);
  }

  toBoolean(value: string): boolean {
    if (value === 'true')
      return true;
    else
      return false;
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An Error Occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      console.log('Error Occurred: ' + errorMessage);
    }
    return throwError(() => err);
  }
}
