import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent implements OnInit {
  title: string;
  message: string;
  message2: string;
  message3: string;
  startTime: string;
  endTime: string;
  showDialogButton: boolean;
  acctdetail: string;

  constructor(    
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorHandlerDialogModel) { 
        // Update view with given values
        this.title = data.title;
        this.message = data.message;
        this.message2 = data.message2;
        this.message3 = data.message3;
        this.startTime = data.startTime;
        this.endTime = data.endTime;
        this.acctdetail = '';
        this.showDialogButton = data.showDialogButton;
        dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close(true);
  }

}
/**
 * Class to represent error handler dialog model.
 *
 * It has been kept here to keep it as part of helper components.
 */
export class ErrorHandlerDialogModel {
  constructor(
    public title: string,
    public message: string,
    public message2: string,
    public message3: string,
    public startTime: string,
    public endTime: string,
    public showDialogButton: boolean
  ) {}
}
