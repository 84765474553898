import { Component, OnInit } from '@angular/core';
import { AuthService, PhoneHelperService, ViewportService } from 'epay-library';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'epay-library';
import { DatePipe } from '@angular/common';
import { CommonSettings } from 'epay-library';

import { EMPLOYER_PROFILE_SETTINGS_URL } from 'epay-library';

@Component({
  selector: 'epay-eft-landing',
  templateUrl: './eft.landing.component.html',
})
export class EMPEFTLandingComponent implements OnInit {
  pagetitle = 'ACH Credit EFT/EDI';
  paymentaccounts: any[] = [];
  username = '';
  usertype = '';
  isManager = '';
  numOfUsers = 0;
  accountKeys: string[] = [];
  isLoggedIn = false;
  isLoading = false;
  accountAddress = '';
  accountCSZ = '';
  accountEmail = '';
  accountPhone = '';
  employerName = '';
  baseurl = '';
  daysToShowEFTFileHistory: number;
  setupresult = '';
  isPaymentAccountSetup = false;
  isMobile = false;
  phoneMask = '';
  countryId = '';

  confirmationTitle = '';
  confirmationMessage = '';
  confirmationNumber = '';
  goToPrimary = '';
  goToPrimaryUrl = '';
  openConfirmation = false;

  profileSettingsUrl = EMPLOYER_PROFILE_SETTINGS_URL;

  constructor(
    private authservice: AuthService,
    private paymentservice: PaymentService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private router: Router,
    private viewpointService: ViewportService,
    private phoneHelperService: PhoneHelperService
  ) {
    this.baseurl = CommonSettings.BaseApi;
    this.daysToShowEFTFileHistory = +CommonSettings.DaysToShowEFTFileHistory;
    this.accountKeys = [];
  }

  ngOnInit() {
    this.authservice.isLoggedIn.subscribe(
      (status: any) => (this.isLoggedIn = JSON.parse(status))
    );
    if (this.isLoggedIn) {
      this.authservice.currentUserName.subscribe(
        (name: string) => (this.username = name.toUpperCase())
      );
      this.authservice.currentUserType.subscribe(
        (type: string) => (this.usertype = type)
      );
      this.authservice.currentAccountAddress.subscribe((address: string) => {
        this.accountAddress = address;
        this.countryId = address.slice(-2);
        this.setPhoneMask(this.countryId)!;
        console.log(this.countryId + ' ' + this.phoneMask);
      });
      this.authservice.currentAccountPhone.subscribe(
        (phone: string) => (this.accountPhone = phone)
      );
      this.authservice.currentAccountEmail.subscribe(
        (email: string) => (this.accountEmail = email)
      );
      this.authservice.currentEmployerName.subscribe(
        (empName: string) => (this.employerName = empName)
      );
      this.authservice.currentUserAccountManager.subscribe(
        (manager: string) => (this.isManager = manager)
      );
      this.authservice.currentNumberofAccountUsers.subscribe(
        (acctCount: string) => (this.numOfUsers = +acctCount)
      );
    }
    this.viewpointService.IsMobile.subscribe(
      (mobile: any) => (this.isMobile = mobile)
    );
  }

  goToProfile() {
    this.router.navigate(['/employer/profile']);
  }

  setPhoneMask(regionCode: string) {
    this.phoneMask = this.phoneHelperService.getMask(regionCode);
  }
}
